/**
 * This file defines the MealTimeField component.
 * It is used to input a time for a meal.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import TimePicker from 'react-time-picker'; //Must be v5.2.0 or else it breaks


export class MealTimeField extends Component
{
    /**
     * Represents the MealTimeField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.mealTime - The default meal time input value.
     * @param {string} props.mealType - The type of meal (lunch or dinner).
     * @param {string} props.errorCode - The error code for the meal time input.
     * @param {Function} props.setParentMealTime - The function to set the parent component's meal time.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {string} props.idMod - The string to modify the id of the meal time input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            mealTimeInput: props.mealTime,
            mealType: props.mealType,
            errorCode: props.errorCode,
            idMod: props.idMod || "",
        }

        this.setParentMealTime = props.setParentMealTime;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Sets the mealTime and also updates the parent component.
     * @param {string} mealTime - The meal time input value.
     */
    setMealTime(mealTime)
    {
        this.setState({mealTimeInput: mealTime});
        this.setParentMealTime(mealTime);
    }

    /**
     * Renders the MealTimeField component.
     * @returns {JSX.Element} The rendered MealTimeField component.
     */
    render()
    {
        let minTime, maxTime;
        let mealType = this.state.mealType;
        if (mealType === "lunch")
        {
            //Reasonable lunch times
            minTime = "10:00:00";
            maxTime = "14:01:00"; //Must be 01 to allow 2:00 PM
        }
        else if (mealType === "dinner")
        {
            //Reasonable dinner times
            minTime = "15:00:00";
            maxTime = "23:01:00";
        }
        else
            mealType = "meal"; //Default for when there are no dates

        return (
            <Form.Group className="mb-3">
                <Form.Label className="mb-1">What time will your {this.state.mealType} be at?</Form.Label>
                <br/>
                <Form.Text className="mb-1">
                    If you would like to meet your guests at shul or inform them yourself, leave this blank.
                </Form.Text>
                <br/>
                <TimePicker
                    className={"form-control" + (this.state.mealTimeInput == null || this.isErrorMessage(this.state.errorCode) ? " is-invalid" : "")} //Always show error colour when null
                    data-testid={"meal-time-input" + this.state.idMod}
                    //placeholder="12:30 PM"
                    minTime={minTime}
                    maxTime={maxTime}
                    hourPlaceholder="HH"
                    minutePlaceholder="MM"
                    format="HH:mm" //Use 24 hour clock since AM/PM selector causes issues
                    disableClock={true}
                    clearIcon={null}
                    value={this.state.mealTimeInput}
                    onChange={this.setMealTime.bind(this)}
                />
            </Form.Group>
        );
    }
}

export default MealTimeField;
