/**
 * This file defines the component for editing a user's details.
 */

import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap';
import Swal from 'sweetalert2'; //Pop-Up

import NewHost from "./NewHost";
import NewStudent from "./NewStudent";
import UnknownPage from "./UnknownPage";
import {PasswordField} from './subcomponents/PasswordField';

import {Capitalize, ValidateEmail, SendQuickDataToServer,
        ErrorPopUp, ComponentWithRouter,
        GLOBAL_ERROR_MESSAGES} from "./Util";
import {EmailField} from './subcomponents/EmailField';

const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_EMAIL: "Invalid email!",
    INVALID_PASSWORD: "Incorrect password!",
    NO_ACCOUNT_FOUND: "No {TYPE} account with that email exists!",
};


export class EditDetails extends Component
{
    /**
     * Represents the EditDetails component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {Object} props.locationDetails - The location details of the current community.
     * @param {Object} props.router - The router object containing the URL parameters.
     */
    constructor(props)
    {
        super(props);
        let routerParams = this.props.router.params;

        this.state =
        {
            type: routerParams.type, //Access the URL parameter
            emailInput: "",
            passwordInput: "",
            invalidEmail: "",
            errorMsg: "",
            showedErrorPopUp: false,
            locationDetails: props.locationDetails,
        }
    }

    /**
     * Checks if the current state type is for a student.
     * @returns {boolean} Whether the edit details page is for a student.
     */
    isStudent()
    {
        return this.state.type === this.state.locationDetails.guestType;
    }

    /**
     * Checks if the current state type is for a host.
     * @returns {boolean} Whether the edit details page is for a host.
     */
    isHost()
    {
        return this.state.type === "host";
    }

    /**
     * Checks if the input email is a valid email.
     * @returns {boolean} Whether the email input is valid.
     */
    validEmail()
    {
        return ValidateEmail(this.state.emailInput);
    }

    /**
     * Checks if the password input is valid.
     * @returns {boolean} Whether the password input is valid.
     */
    validPassword()
    {
        return this.state.passwordInput.length >= 0;
    }

    /**
     * Checks if the current email input is for an account that doesn't exist.
     * @returns {boolean} true if the the account for the email input doesn't exist, false otherwise.
     */
    emailNotAccount()
    {
        return this.state.invalidEmail !== ""
            && this.state.invalidEmail === this.state.emailInput;
    }

    /**
     * Gets the error message (if present) at the time of form submission.
     * @returns {string} The error message symbol.
     */
    getErrorMessage()
    {
        let errorMsg = "";

        if (!this.validEmail())
            errorMsg = "INVALID_EMAIL";
        else if (!this.validPassword())
            errorMsg = "INVALID_PASSWORD";

        return errorMsg;
    }

    /**
     * Displays an error pop-up.
     * @param {string} errorSymbol - The error symbol for the message to be shown on the pop-up.
     */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        text = text.replaceAll("{TYPE}", this.state.type);
        ErrorPopUp(text);
    }

    /**
     * Submits the user's email and password in order to load their details for editing.
     * @param {Object} e - The form submission event. 
     */
    async LoadDetailsForEditing(e)
    {
        e.preventDefault(); //Prevent page reload
        const route = `/load${this.isStudent() ? "student" : "host"}details`;
        const data =
        {
            email: this.state.emailInput,
            password: this.state.passwordInput,
        };

        Swal.fire
        ({
            title: 'Connecting, please wait...',
            timer: 10000, //10 seconds
            timerProgressBar: true,
            allowOutsideClick: false,
            showConfirmButton: false,
            showCancelButton: false,
            didOpen: async () =>
            {
                let ret = await SendQuickDataToServer(data, this, route, this.state.locationDetails);
                if (ret != null)
                {
                    let data = ret.data;
                    data.password = this.state.passwordInput; //Add password to the data
                    this.setState({personData: data});
                    Swal.close();
                }
            },
        }).then((e) =>
        {
            if (e.dismiss === Swal.DismissReason.timer)
            {
                this.errorPopUp("NO_SERVER_CONNECTION");
                return null;
            }
        });
    
    }

    /**
     * Renders the EditDetails component.
     * @returns {JSX.Element} The rendered component.
     */
    render()
    {
        let formType = Capitalize(this.state.type);

        if (!this.isStudent() && !this.isHost())
            return <UnknownPage />;

        if (this.state.personData != null)
        {
            if (this.isStudent())
                return <NewStudent locationDetails={this.state.locationDetails} editData={this.state.personData} />;
            else if (this.isHost())
                return <NewHost locationDetails={this.state.locationDetails} editData={this.state.personData} />;
        }

        return (
            <div className="form-page">
                <h1 className="form-title reset-password-form-title">Edit {formType} Details</h1>
                <div className="mb-3"><Form.Text>Enter your email and password first to edit your details.</Form.Text></div>

                <Form onSubmit={(e) => this.LoadDetailsForEditing(e)}>
                    {/* Email Input */}
                    <EmailField
                        email={this.state.emailInput}
                        setParentEmail={(email) => this.setState({emailInput: email})}
                        isInvalid={() => !this.validEmail() || this.emailNotAccount()} />

                    {/* Password Input */}
                    <PasswordField
                        password={this.state.passwordInput}
                        showForgotPasswordLink={true}
                        setParentPassword={(password) => this.setState({passwordInput: password})}
                        personType={this.state.type}
                        basePath={this.state.locationDetails.basePath} />

                    {/* Submit Button */}
                    <div className="submit-form-button-container mt-2">
                        <Button className="submit-form-button" type="submit">
                            Continue
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

export default ComponentWithRouter(EditDetails);
