/**
 * This file defines the DatingField component.
 * It is used to show the dating information of a person.
 */

import React, {Component} from 'react';
import {Button, Form} from "react-bootstrap";
import Swal from 'sweetalert2'; //Pop-Up


export class DatingField extends Component
{
    /**
     * Represents the DatingField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.datingName - The default dating input value.
     * @param {string} props.datingEmail - The default dating input value.
     * @param {Function} props.setParentDating - The function to set the parent component's dating input.
     * @param {string} props.idMod - The string to modify the id of the email input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            datingName: props.datingName || "",
            datingEmail: props.datingEmail || "",
            idMod: props.idMod || "",
        }

        this.setParentDating = props.setParentDating;
    }

    /**
     * Sets the dating input value and also updates the parent component.
     * @param {string} name - The dating name.
     * @param {string} email - The dating email.
     */
    setDating(name, email)
    {
        this.setState({datingName: name, datingEmail: email});

        if (name !== "" || email !== "") //Not clearing the dating information
            this.setParentDating({"name": name, "email": email});
        else
            this.setParentDating({});
    }

    /**
     * Clears the dating information and prompts the user for confirmation before removing the relationship.
     */
    clearDating()
    {
        Swal.fire(
        {
            title: "Are you sure you want to remove the relationship?",
            text: "This action cannot be undone.",
            icon: "warning",
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: "Remove"
        }).then((result) =>
        {
            if (result.isDenied)
                this.setDating("", "");
        });
    }

    /**
     * Renders the DatingField component.
     * @returns {JSX.Element} The rendered DatingField component.
     */
    render()
    {
        if (this.state.datingEmail === "" || this.state.datingName === "")
            return ""; //Only show the field if it's populated

        return (
            <Form.Group controlId={"formDating" + this.state.idMod} className="mb-3">
                {/* Title */}
                <Form.Label>Married To</Form.Label>

                {/* Input Field */}
                <div className="d-flex">
                    <Form.Control
                        type="text"
                        value={this.state.datingName}
                        data-testid={"dating-name-input" + this.state.idMod}
                        disabled />

                    <Button variant="danger" onClick={this.clearDating.bind(this)}>
                        Remove
                    </Button>
                </div>
            </Form.Group>
        );
    }
}

export default DatingField;
