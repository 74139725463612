import React from 'react';

import {PATH_HOME} from "./App";
import {NavButton} from "./Welcome";

import "./styles/Welcome.css";
import "./styles/FormPage.css";

class UnknownPage extends React.Component {
    render() {
        return (
            <div className="form-page">
                <div className="form-loading">
                    <h1>404! Page not found!</h1>
                    <NavButton target={PATH_HOME} className={"welcome-button"} text="Back to Start" />
                </div>
            </div>
        )
    }
}

export default UnknownPage;
