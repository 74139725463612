/*
    A class for the form students use to cancel weekly.
*/

import React, {Component} from 'react';

import CancellationForm from './CancellationForm';
import {GetUpcomingDateNameList, PageLoading, NoDatesAvailablePopUp} from "./Util";


export class StudentCancellation extends Component
{
    /*
        Sets up the weekly student cancellation page.
    */
    constructor(props)
    {
        super(props);

        this.state =
        {
            loaded: false,
            dateList: [],
            locationDetails: props.locationDetails,
        }
    }

    /*
        Loads data from the server when the page loads.
    */
    async componentDidMount()
    {
        let dateList = await GetUpcomingDateNameList(this.state.locationDetails);

        if (dateList.length > 1)
        {
            //Only offer the student days within the next six days
            let sixDaysAway = new Date();
            sixDaysAway.setDate(sixDaysAway.getDate() + 6);
            dateList = dateList.filter(date => new Date(date.date) <= sixDaysAway);
        }

        if (this.state.loaded)
            return; //Prevent the pop-up for displaying multiple times

        this.setState
        ({
            loaded: true,
            dateList: dateList,
        });

        if (dateList.length === 0) //No dates available
            NoDatesAvailablePopUp(this.state.locationDetails);
    }

    /*
        Prints the weekly student cancellation page.
    */
    render()
    {
        if (!this.state.loaded)
            return PageLoading();

        return (
            <CancellationForm dateList={this.state.dateList} type={this.state.locationDetails.guestType} locationDetails={this.state.locationDetails}
                cancellationMsg={"Cancellation complete.\nSorry you couldn't make it!"}
                deadlineMsg="This form closes Wednesday night."/>
        )
    }
}

export default StudentCancellation;
