/**
 * This file defines the MoveStudentButton component.
 * It is used for the maintainer to manually allocate a student for a meal.
 */

import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import Swal from 'sweetalert2'; //Pop-Up
import withReactContent from 'sweetalert2-react-content';

const ReactPopUp = withReactContent(Swal);

//Custom SVGs for the icons
const EDIT_ICON =
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="3.45em" height="2em" viewBox="0 0 640 512">
        <path d="M639.098 300.5811c5.872-5.872 5.872-15.6586 0-21.2295l-35.232-35.232c-5.5708-5.872-15.3575-5.872-21.2294 0l-27.7038 27.5533 56.4614 56.4614M372.4495 454.3068v56.4614h56.4614l166.5236-166.6741-56.4615-56.4615ZM38.9719 15.999v.0954c-3.8302-.383-7.2774 2.1062-8.1392 5.8407l-29.013 123.239C.575 150.3449 0 155.7069 0 161.069c0 38.2066 30.8335 69.1359 68.9442 69.4231V452.646c0 12.7355 10.246 22.9814 22.9815 22.9814s22.9814-10.2459 22.9814-22.9814v-222.249c38.1108-.2873 68.9442-31.2166 68.9442-69.4231 0-5.3623-.5749-10.7243-1.8197-15.8951l-29.013-123.239c-.8617-3.6387-4.309-6.128-8.1392-5.8407-3.8302.2873-6.7994 3.3515-6.991 7.1817l-7.277 123.0463H114.045L99.4908 22.7973c-.383-3.926-3.735-6.7983-7.5651-6.7983-3.8303 0-7.0863 2.968-7.5651 6.7983L69.8064 146.227H53.1445L45.9628 23.1807c-.1915-3.8302-3.1607-6.8944-6.9909-7.1817zm553.9479 0c-2.0109 0-3.9256.2873-5.8408.9576-16.1827 5.9368-96.8088 40.4091-96.8088 136.9309v99.9097l122.663-122.663V36.0125c0-11.1077-9.0015-20.0134-20.0134-20.0134zm-271.18 15.321c-46.8245 0-90.2017 15.0344-125.5356 40.5054l15.6071 66.3578v-.0954l.1926.7668c27.865-28.5353 66.7416-46.2509 109.736-46.2509 60.6133 0 113.0877 35.2375 137.8884 86.371v-25.0872c0-23.939 4.1183-44.718 10.8212-62.7201-38.5896-37.0575-90.969-59.8475-148.7097-59.8475Zm-12.811 92.597a122.5675 122.5675 0 0 0-109.7565 121.8962 122.5675 122.5675 0 0 0 245.135 0A122.5675 122.5675 0 0 0 308.9288 123.917ZM168.5303 245.239c-7.086 4.5005-14.8422 8.2354-22.9814 10.9165V368.19c38.2461 54.8668 101.472 91.0267 173.1966 92.0155v-34.8836l28.4724-28.4724c-8.2895 1.3954-16.7888 2.1732-25.4782 2.1732-84.648 0-153.2094-68.5614-153.2094-153.2094z"/>
    </svg>;
const ALLOCATE_ICON =
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="3.45em" height="2em" viewBox="0 0 640 512">
        <path d="M40.6992 16v.0996c-4-.4-7.6 2.1996-8.5 6.0996L1.9004 150.9004C.6004 156.3004 0 161.9 0 167.5c0 39.9 32.2 72.2 72 72.5v232c0 13.3 10.7 24 24 24s24-10.7 24-24V239.9004c39.8-.3 72-32.6 72-72.5 0-5.6-.6004-11.1996-1.9004-16.5996L159.8008 22.0996c-.9-3.8-4.5-6.3996-8.5-6.0996-4 .3-7.1008 3.5-7.3008 7.5L136.4004 152h-17.3008L103.9004 23.0996C103.5004 18.9996 100 16 96 16s-7.4004 3.0996-7.9004 7.0996L72.9004 152H55.5L48 23.5c-.2-4-3.3008-7.2-7.3008-7.5Zm578.5 0c-2.1 0-4.0996.3-6.0996 1C596.1996 23.2 512 59.2001 512 160v128c0 17.7 14.3 32 32 32h48.0996v152c0 13.3 10.7 24 24 24s24-10.7 24-24V36.9004c0-11.6-9.4004-20.9004-20.9004-20.9004ZM336 32c-48.9 0-94.1997 15.7008-131.0996 42.3008l16.2988 69.2988V143.5l.2012.8008C250.5004 114.5008 291.1 96 336 96c63.3 0 118.1 36.7993 144 90.1992V160c0-25 4.3008-46.7 11.3008-65.5C451.0008 55.8 396.2999 32 336 32Zm.455 96a128 128 0 0 0-10.0214.3574A128 128 0 0 0 208 256a128 128 0 0 0 256 0 128 128 0 0 0-127.545-128Zm-.4296 26.543c8.018 0 14.4717 6.4528 14.4727 14.4707v139.0175l47.75-47.8046c5.6623-5.6624 14.863-5.6624 20.5253 0 5.6624 5.6623 5.6624 14.865 0 20.5273l-72.457 72.457c-5.6624 5.6624-14.863 5.6624-20.5254 0l-.0547.0547-72.5098-72.457c-5.6623-5.6624-5.6623-14.865 0-20.5273 5.6624-5.6624 14.863-5.6624 20.5254 0l47.75 47.6972V169.0137c0-8.018 6.5055-14.4688 14.5235-14.4688zM176 255.4004c-7.4 4.7-15.5 8.6004-24 11.4004v117C192.5 441.9008 259.8 480 336 480c89.4 0 166.6-52.3993 202.5-128.1992-23.9-2-43.9992-17.2012-53.1992-38.2012C462.2008 373.4996 404.0999 416 336 416c-88.4 0-160-71.6-160-160Z"/>
    </svg>;


export class MoveStudentButton extends Component
{
    /**
     * Represents the MoveStudentButton component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {Object} props.student - The student to sign up.
     * @param {Object} props.registeredHosts - The map of registered hosts for the week.
     * @param {string} props.date - The date of the meal.
     * @param {string} props.meal - The meal type.
     * @param {Object} props.allocatedHost - The host the student is already allocated to.
     * @param {string} props.customPopUpButtonClassesSuccess - The custom class for the pop-up buttons.
     * @param {Object} props.locationDetails - The location details for the current community.
     * @param {Function} props.allocateToHost - The function to add the student to a host's list.
     * @param {Function} props.removeFromHost - The function to remove the student from a host's list.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            hostInput: "",
            student: props.student,
            registeredHosts: props.registeredHosts,
            date: props.date,
            meal: props.meal,
            allocatedHost: props.allocatedHost,
            customPopUpButtonClassesSuccess: props.customPopUpButtonClassesSuccess,
            locationDetails: props.locationDetails,
        };

        this.allocateToHost = props.allocateToHost;
        this.removeFromHost = props.removeFromHost;
    }

    /**
     * Returns the full name of the student to be signed-up.
     * @returns {string} The first and last name of the student.
     */
    getStudentName()
    {
        return `${this.state.student.name}`;
    }

    /**
     * Handles the choosing of a host to allocate the student to.
     */
    async chooseHost()
    {
        let inputOptions = 
        {
            //Get an object where the keys are host emails and the values are host names
            ...Object.fromEntries(Object.values(this.state.registeredHosts)
                    .filter(host => !this.state.allocatedHost || host.email !== this.state.allocatedHost.email) //Remove the host the student is already allocated to
                    .map(host => [host.email, host.name])),
        };

        if (this.state.allocatedHost)  //Student is already allocated to a host
        {
            //Allow the option to remove the student from the host
            inputOptions =
            {
                "": `Remove From Host (${this.state.allocatedHost.name})`,
                ...inputOptions,
            }
        }

        let result = await ReactPopUp.fire
        ({
            title: `Send ${this.getStudentName()} to which host?`,
            input: "select",
            inputOptions: inputOptions,
            showCancelButton: true,
            confirmButtonText: `Confirm`,
            buttonsStyling: false,
            customClass: this.state.customPopUpButtonClassesSuccess,
        });

        if (result.isConfirmed)
            await this.sendStudentToHost(result.value);
    }

    /**
     * Sends the student to the chosen host.
     * @param {string} hostEmail - The email of the host to send the student to.
     */
    async sendStudentToHost(hostEmail)
    {
        await this.removeFromHost(this.state.student, this.state.date, this.state.meal);
        if (hostEmail !== "")
            await this.allocateToHost(this.state.student, hostEmail, this.state.date, this.state.meal);
    }

    /**
     * Renders the MoveStudentButton component.
     * @returns {JSX.Element} The rendered MoveStudentButton component.
     */
    render()
    {
        let buttonVariant = this.state.allocatedHost ? "info" : "success";

        if (Object.keys(this.state.registeredHosts).length === 0)
            return "";

        return (
            <Button variant={buttonVariant} className="maintainer-move-student-button px-0"
                    onClick={this.chooseHost.bind(this)}>
            {
                this.state.allocatedHost ? //Change button icon based on whether student is allocated
                    EDIT_ICON
                :
                    ALLOCATE_ICON
            }
            </Button>
        );
    }
}

export default MoveStudentButton;
