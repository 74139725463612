/**
 * This file defines the NotesField component.
 * It is used to input extra free-form notes for a person.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";

import {ProcessTextInput} from '../Util';


export class NotesField extends Component
{
    /**
     * Represents the NotesField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.notes - The default notes input value.
     * @param {string} props.fieldLabel - The label for the input.
     * @param {string} props.fieldDesc - The description for the input.
     * @param {Function} props.setNotes - The function to set the parent component's notes.
     * @param {string} props.idMod - The string to modify the id of the notes input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            notesInput: props.notes,
            fieldLabel: props.fieldLabel || "Notes",
            fieldDesc: props.fieldDesc || "",
            idMod: props.idMod || "",
        }

        this.setParentNotes = props.setNotes;
    }

    /**
     * Sets the notes and also updates the parent component.
     * @param {Object} e - The event object to get the input from.
     */
    setNotes(e)
    {
        let notes = ProcessTextInput(e, "NOTES", false);
        this.setState({notesInput: notes});
        this.setParentNotes(notes);
    }

    /**
     * Renders the NotesField component.
     * @returns {JSX.Element} The rendered NotesField component.
     */
    render()
    {
        return (
            <Form.Group className="mb-3" controlId={"formNotes" + this.state.idMod}>
                {/* Title */}
                <Form.Label className={this.state.fieldDesc ? "mb-0" : "mb-1"}>
                    {this.state.fieldLabel}
                </Form.Label>

                {/* Note */}
                {
                    this.state.fieldDesc &&
                        <div className="mb-1">
                            <Form.Text className="text-muted">
                                {this.state.fieldDesc}
                            </Form.Text>
                        </div>
                }

                {/* Input */}
                <Form.Control
                    name="notes"
                    data-testid={"notes-input" + this.state.idMod}
                    //placeholder=""
                    value={this.state.notesInput}
                    onChange={(e) => this.setNotes(e)}
                />
            </Form.Group>
        );
    }
}

export default NotesField;
