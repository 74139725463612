/*
    A class for the form hosts use to sign-up weekly.
*/

import React, {Component} from 'react';
import {Button, Form} from "react-bootstrap";
import {random} from 'lodash';
import Swal from 'sweetalert2'; //Pop-Up
import withReactContent from 'sweetalert2-react-content';

import {PATH_HOME} from './App';
import {Capitalize, CreateTotalFoodRestrictionNameList, GetUpcomingDateNameList, GetWeeklyStudentList,
        ExtractCountryCityCommunity, ProcessTextInput, ValidateEmail,
        PageLoading, RequiredTooltip, ErrorPopUp, NoDatesAvailablePopUp, SendFormToServer,
        HostsNeedApproval, HostsHaveGenderRequest, HostsHaveAgeRequest, HostsCanRequestGuests, GetDefaultMinAge, GetDefaultMaxAge,
        GLOBAL_ERROR_MESSAGES, CUSTOM_POPUP_BUTTON_CLASSES_CONTINUE,} from "./Util";
import {DateDropdown} from "./subcomponents/DateDropdown";
import {EmailField} from './subcomponents/EmailField';
import {FoodDropdown} from './subcomponents/FoodDropdown';
import {MealTimeField} from './subcomponents/MealTimeField';
import {PasswordField} from './subcomponents/PasswordField';
import {StudentDropdown} from './subcomponents/StudentDropdown';

import "./styles/FormPage.css";
import "./styles/WeeklyForm.css";

//TODO: Cookies of food preferences should be saved and loaded weekly.

const ReactPopUp = withReactContent(Swal);

const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_EMAIL: "Invalid email!",
    INVALID_PASSWORD: "Incorrect password!",
    INVALID_DATE: "Invalid date!",
    INVALID_CAPACITY: "Invalid capacity!",
    INVALID_GENDER: "Invalid gender!",
    INVALID_BADFORALLERGY: "Invalid food restriction!",
    INVALID_STUDENTPREF: "Invalid requested {GUEST_TYPE}!",
    INVALID_MINAGE: "Invalid minimum age!",
    INVALID_MAXAGE: "Invalid maximum age!",
    INVALID_AGE_RANGE: "Minimum age must be less than or equal to maximum age!",
    INVALID_MEALTIME: "Invalid meal time!",
    NO_ACCOUNT_FOUND: "No host account with that email exists!",
    ALREADY_SIGNED_UP: "You've already signed up for this week!",
    ALREADY_SIGNED_UP_TO_JOIN: "You've already signed up to join that week. You can't sign up to host as well!",
    DEADLINE_PASSED: "The deadline for that date has already passed!",
};


export class WeeklyHost extends Component
{
    /*
        Sets up the weekly host sign-up page.
    */
    constructor(props)
    {
        super(props);

        this.state =
        {
            loaded: false,
            emailInput: "",
            passwordInput: "",
            dateInput: "",
            holyDayInput: "",
            mealInput: "",
            mealTimeInput: "",
            capacityInput: "4",
            genderPrefInput: "none",
            minAgeInput: GetDefaultMinAge(props.locationDetails),
            maxAgeInput: GetDefaultMaxAge(props.locationDetails),
            foodRestrictionInput: [],
            studentPrefInput: [],
            weeklyStudentInput: [],
            dateList: [],
            weeklyStudentLists: {}, //Key: date-meal, Value: list of students
            foodRestrictionNameList: [],
            studentPrefKey: 0, //Used to force re-render of student pref dropdown
            showedErrorPopUp: false,
            errorMsg: "",
            invalidEmail: "",
            locationDetails: props.locationDetails,
        }
    }

    /*
        Loads data from the server when the page loads.
    */
    async componentDidMount()
    {
        let weeklyStudentLists = [];
        let foodRestrictionNameList = await CreateTotalFoodRestrictionNameList(this.state.locationDetails);
        let dateList = await GetUpcomingDateNameList(this.state.locationDetails);

        //Load all weekly student lists in parallel
        if (HostsCanRequestGuests(this.state.locationDetails) && !HostsNeedApproval(this.state.locationDetails)) //Only load if necessary
        {
            let promises = dateList.map((date) => GetWeeklyStudentList(date.date, date.meal, this.state.locationDetails));
            try
            {
                weeklyStudentLists = await Promise.all(promises);
                weeklyStudentLists = weeklyStudentLists.map((students) => students ? students.sort((a, b) => a.name.localeCompare(b.name)) : []); //Sort by name
            }
            catch (error)
            {
                console.error("Error loading weekly student lists:", error);
                weeklyStudentLists = dateList.map((date) => []); //Set to empty lists on error
            }
        }

        if (this.state.loaded)
            return; //Prevent the pop-up for displaying multiple times

        this.setState
        ({
            loaded: true,
            foodRestrictionNameList: foodRestrictionNameList,
            dateList: dateList,
            dateInput: (dateList.length > 0) ? dateList[0].date : this.state.dateInput, //Default to the first entry if there is one
            holyDayInput: (dateList.length > 0) ? dateList[0].type : this.state.holyDayInput, //Default to the first entry if there is one
            mealInput: (dateList.length > 0) ? dateList[0].meal : this.state.mealInput, //Default to the first entry if there is one
            weeklyStudentLists: weeklyStudentLists.reduce((acc, res, i) => {acc[dateList[i].date + "-" + dateList[i].meal] = res; return acc;}, {}),
        });

        if (dateList.length === 0) //No dates available
            NoDatesAvailablePopUp(this.state.locationDetails);
    }

    /*
        Ensures all necessary fields in the form are filled.
        returns: true if all required fields are filled, false otherwise.
    */
    allRequiredFieldsFilled()
    {
        return this.state.emailInput !== ""
            && this.state.passwordInput !== ""
            && this.state.dateInput !== ""
            && this.state.holyDayInput !== ""
            && this.state.mealInput !== ""
            && this.state.capacityInput !== ""
            && this.state.genderPrefInput !== "";
    }

    /*
        Checks if the input email is a valid email.
        returns: true if the email is valid, false otherwise.
    */
    validEmail()
    {
        return ValidateEmail(this.state.emailInput);
    }

    /*
        Checks if the input meal time is valid.
        returns: true if the meal time is valid, false otherwise.
    */
    validMealTime()
    {
        return this.state.mealTimeInput != null; //Just check if it's not null (meaning partially set)
    }

    /*
        Checks if the input age range is valid.
        returns: true if the age range is valid, false otherwise.
    */
    validAgeRange()
    {
        let minAge = Number(this.state.minAgeInput);
        let maxAge = Number(this.state.maxAgeInput);

        if (isNaN(minAge) || isNaN(maxAge))
            return false;

        if (minAge === 0 || maxAge === 0)
            return true; //Either can be 0 and then it's always valid

        return minAge <= maxAge;
    }

    /*
        Gets the error message (if present) at the time of form submission.
        returns: The error message symbol.
    */
    getErrorMessage()
    {
        let errorMsg = "";

        if (!this.allRequiredFieldsFilled())
            errorMsg = "MISSING_REQUIRED_FIELD";
        else if (!this.validEmail())
            errorMsg = "INVALID_EMAIL";
        //else if (!this.validMealTime()) //Can't call because no way for user to wipe the field and unnull it
        //    errorMsg = "INVALID_MEALTIME";
        else if (!this.validAgeRange())
            errorMsg = "INVALID_AGE_RANGE";

        return errorMsg;
    }

    /*
        Checks if an error message symbol is the last shown error message.
        param errorMsg: The error message symbol to check.
        returns: true if the the error message symbol was last shown, false otherwise.
    */
    isErrorMessage(errorMsg)
    {
        return this.state.errorMsg === errorMsg;
    }

    /*
        Checks if the current email input is for an account that doesn't exist.
        returns: true if the the account for the email input doesn't exist, false otherwise.
    */
    emailNotAccount()
    {
        return this.state.invalidEmail !== ""
            && this.state.invalidEmail === this.state.emailInput;
    }

    /*
        Sets the chosen gender preference.
        param: The gender to set.
    */
    setGenderPref(gender)
    {
        this.setState({genderPrefInput: gender});
    }

    /*
        Sets the list of students the host would like to host.
        param studentPref: The student preference to set.
    */
    setStudentPref(studentPref)
    {
        this.setState({studentPrefInput: studentPref});
    }

    /*
        Submits the sign up.
        param e: The default event for submitting a form.
    */
    async submitSignUp(e)
    {
        e.preventDefault(); //Prevent page reload
        let errorMsg = this.getErrorMessage();

        if (errorMsg === "") //No error
        {
            let data =
            {
                email: this.state.emailInput.toLowerCase(),
                password: this.state.passwordInput,
                date: this.state.dateInput,
                type: this.state.holyDayInput,
                meal: this.state.mealInput,
                mealTime: this.state.mealTimeInput ? this.state.mealTimeInput : "",
                capacity: Number(this.state.capacityInput),
                genderPref: this.state.genderPrefInput,
                minAge: this.state.minAgeInput === "" ? 0 : Number(this.state.minAgeInput),
                maxAge: this.state.maxAgeInput === "" ? 0 : Number(this.state.maxAgeInput),
                badForAllergy: this.state.foodRestrictionInput,
                studentPref: this.state.studentPrefInput,
            };

            await SendFormToServer(data, this, "/weeklyhostsignup", 'Sign-up complete!\nThank you for hosting!',
                                   this.state.locationDetails, PATH_HOME);
        }
        else
        {
            this.setState({errorMsg: errorMsg});
            this.errorPopUp(errorMsg);
        }
    }

    /*
        Displays an error pop-up.
        param errorSymbol: The error symbol for the message to be shown on the pop-up.
    */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        text.replaceAll("{GUEST_TYPE}", this.state.locationDetails.guestType);
        ErrorPopUp(text);
    }

    /*
        Sets the date inputs for the form.
        param date: The date to set.
        param holyDay: The holy day to set.
        param meal: The meal to set.
    */
    setDateInputs(date, holyDay, meal)
    {
        let mealTime = this.state.mealTimeInput;
        if (meal !== this.state.mealInput) //If the meal changed, reset the meal time
            mealTime = "";

        this.setState({dateInput: date, holyDayInput: holyDay, mealInput: meal, mealTimeInput: mealTime});
    }

    /*
        Gets the content displaying which students are signed up for the selected week.
        param selectedStudents: The students selected to host.
        returns: The content displaying which students are signed up for the selected week.
    */
    getWeeklyStudentListPopUpContent(selectedStudents)
    {
        const takenClass = "text-danger";
        let date = this.state.dateInput;
        let meal = this.state.mealInput;
        let weeklyList = this.state.weeklyStudentLists[date + "-" + meal];

        if (!weeklyList || weeklyList.length === 0)
            return <p>No {this.state.locationDetails.guestType}s signed up yet.</p>;

        return (
            <div className="text-start">
                <p className="text-justify"><small><span className="fw-bold">Note</span>: <span className={takenClass}>Red</span> indicates someone else requested to host this {this.state.locationDetails.guestType}.</small></p>
                <div>
                {
                    weeklyList.map((student, i) =>
                    {
                        let email = student.email;
                        let name = (student.alreadyRequested) ? <span className={takenClass}>{student.name}</span> : student.name;
                        let maleGuests = (student.maleGuests > 0) ? `+ ${student.maleGuests} ♂` : "";
                        let femaleGuests = (student.femaleGuests > 0) ? `+ ${student.femaleGuests} ♀` : "";
                        let guestAgeRange = (student.guestMinAge > 0 && student.guestMaxAge > 0) ?
                                        (
                                                (student.guestMinAge === student.guestMaxAge)
                                                ? `(Aged ${student.guestMinAge})`
                                                : `(Aged ${student.guestMinAge} - ${student.guestMaxAge})`
                                        )
                                        : (student.guestMinAge > 0) ? `(Aged ${student.guestMinAge}+)`
                                        : (student.guestMaxAge > 0) ? `(Aged up to ${student.guestMaxAge})`
                                        : "";
                        let selected = selectedStudents.includes(email);
                        let onClick = (!selectedStudents.includes(email))
                            ? () => {
                                selectedStudents.push(email);
                                this.setState({weeklyStudentInput: selectedStudents});
                                ReactPopUp.update({html: this.getWeeklyStudentListPopUpContent(selectedStudents)});
                            }
                            : () => {
                                selectedStudents.splice(selectedStudents.indexOf(email), 1);
                                this.setState({weeklyStudentInput: selectedStudents});
                                ReactPopUp.update({html: this.getWeeklyStudentListPopUpContent(selectedStudents)});
                            };

                        return (
                            <div key={i} onClick={onClick} className="clickable mb-2">
                                <Form.Check inline defaultChecked={selected}/> {name} {maleGuests} {femaleGuests} {guestAgeRange}
                            </div>
                        )
                    })
                }
                </div>
            </div>
        );
    }

    /*
        Displays a pop-up showing which students are signed up for the selected week.
    */
    async displayWeeklyStudentList()
    {
        let date = this.state.dateInput;
        let meal = this.state.mealInput;

        this.setState({weeklyStudentInput: [...this.state.studentPrefInput]}, () =>
        {
            ReactPopUp.fire
            ({
                title: `${date} ${Capitalize(meal)} ${Capitalize(this.state.locationDetails.guestType)}s`,
                html: this.getWeeklyStudentListPopUpContent(this.state.weeklyStudentInput),
                showCancelButton: true,
                buttonsStyling: false,
                customClass: CUSTOM_POPUP_BUTTON_CLASSES_CONTINUE,
            }).then((result) => {
                if (result.isConfirmed)
                    this.setState({studentPrefInput: this.state.weeklyStudentInput, weeklyStudentInput: [], studentPrefKey: random(0, 100000)});
            });
        });
    }

    /*
        Prints the weekly host sign-up page.
    */
    render()
    {
        let required = RequiredTooltip();
        let {country, city, community} = ExtractCountryCityCommunity(this.state.locationDetails);
        let guestType = this.state.locationDetails.guestType;

        if (!this.state.loaded)
            return PageLoading();

        return (
            <div className="form-page">
                <h1 className="form-title weekly-student-form-title">Weekly Host Sign-Up</h1>
                <h2 className="form-title weekly-student-form-title mb-3">{community}, {city}, {country}</h2>
                <Form onSubmit={(e) => this.submitSignUp(e)}>
                    {/*Email Input*/}
                    <EmailField
                        email={this.state.emailInput}
                        setParentEmail={(email) => this.setState({emailInput: email})}
                        isInvalid={() => !this.validEmail() || this.emailNotAccount()} />

                    {/*Password Input*/}
                    <PasswordField
                        password={this.state.passwordInput}
                        showForgotPasswordLink={true}
                        setParentPassword={(password) => this.setState({passwordInput: password})}
                        personType="host"
                        basePath={this.state.locationDetails.basePath} />

                    {/*Date Input*/}
                    <DateDropdown dateList={this.state.dateList} dateInput={this.state.dateInput}
                                  holyDayInput={this.state.holyDayInput} mealInput={this.state.mealInput}
                                  setParentDateInputs={this.setDateInputs.bind(this)}
                                  text="Which date are you signing up for?" />

                    {/*Meal Time Input*/}
                    <MealTimeField
                        key={this.state.mealInput} //Force re-render when meal changes
                        mealTime={this.state.mealTimeInput}
                        mealType={this.state.mealInput}
                        errorCode="INVALID_MEALTIME"
                        setParentMealTime={(mealTime) => this.setState({mealTimeInput: mealTime})}
                        isErrorMessage={this.isErrorMessage.bind(this)} />

                    {/*Capacity Input*/}
                    <Form.Group className="mb-3">
                        <Form.Label>What is the maximum number of {guestType}s you'd like to host?{required}</Form.Label>
                        <Form.Control
                            required
                            name="quantity"
                            type="number"
                            min="1"
                            placeholder=""
                            value={this.state.capacityInput}
                            onChange={(e) => this.setState({capacityInput: ProcessTextInput(e, "CAPACITY", true)})}
                        />
                    </Form.Group>

                    {/*Gender Input*/}
                    {/*TODO: Replace with GenderField*/}
                    {
                        HostsHaveGenderRequest(this.state.locationDetails) &&
                            <Form.Group className="mb-3">
                                <Form.Label>Do you have any preferences in regard to {guestType} genders?{required}</Form.Label>
                                <div key="gender-radio">
                                    <Form.Check
                                        required
                                        inline
                                        label="None"
                                        name="gender"
                                        type="radio"
                                        id="radio-none"
                                        checked={this.state.genderPrefInput === "none"}
                                        onChange={() => this.setGenderPref("none")}
                                    />
                                    <Form.Check
                                        required
                                        inline
                                        label="Male"
                                        name="gender"
                                        type="radio"
                                        id="radio-male"
                                        checked={this.state.genderPrefInput === "male"}
                                        onChange={() => this.setGenderPref("male")}
                                    />
                                    <Form.Check
                                        required
                                        inline
                                        label="Female"
                                        name="gender"
                                        type="radio"
                                        id="radio-female"
                                        checked={this.state.genderPrefInput === "female"}
                                        onChange={() => this.setGenderPref("female")}
                                    />
                                </div>
                            </Form.Group>
                    }

                    {/* Min & Max Age Input */}
                    {
                        HostsHaveAgeRequest(this.state.locationDetails) &&
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1">What is the age range of {guestType}s you'd like to host?</Form.Label>
                                <br/>
                                <Form.Text className="mb-1">
                                    Setting either to 0 will allow {guestType}s of all ages for that range.
                                </Form.Text>
                                <br/>
                                <div className="weekly-age-range-container">
                                    <Form.Control
                                        className="weekly-age-range-input"
                                        name="minAge"
                                        type="number"
                                        min="0"
                                        max="120"
                                        placeholder="Min Age"
                                        value={this.state.minAgeInput}
                                        onChange={(e) => this.setState({minAgeInput: ProcessTextInput(e, "AGE", false)})}
                                    />
                                    <div className="weekly-age-range-separator">
                                        to
                                    </div>
                                    <Form.Control
                                        className="weekly-age-range-input"
                                        name="maxAge"
                                        type="number"
                                        min="0"
                                        max="120"
                                        placeholder="Max Age"
                                        value={this.state.maxAgeInput}
                                        onChange={(e) => this.setState({maxAgeInput: ProcessTextInput(e, "AGE", false)})}
                                    />
                                </div>
                            </Form.Group>
                    }

                    {/*Bad for Allergy Input*/}
                    <FoodDropdown
                        foodSelection={this.state.foodRestrictionInput}
                        foodNameList={this.state.foodRestrictionNameList}
                        fieldLabel="Are there any food restrictions you would rather NOT cater to?"
                        fieldDesc={
                        <>
                            You will only be informed of the food restrictions a day or two before.
                            If that is too short notice for some of these, please select them.
                            <br/>
                            Additionally, assume allergies are serious and {guestType}s can't be at the same table.
                        </>
                        }
                        allowAdditions={false}
                        isAllergyList={false}
                        errorCode="INVALID_BADFORALLERGY"
                        setParentFoodSelection={(foodPref) => this.setState({foodRestrictionInput: foodPref})}
                        setParentFoodOptions={(foodPref) => this.setState({foodRestrictionNameList: foodPref})}
                        isErrorMessage={this.isErrorMessage.bind(this)} />

                    {/*Requested Students Input*/}
                    {
                        HostsCanRequestGuests(this.state.locationDetails) &&
                            <StudentDropdown
                                key={this.state.studentPrefKey} //Force re-render when student pref changes in this file
                                students={this.state.studentPrefInput}
                                fieldLabel={`Are there any ${guestType}s you'd like to host if they're available?`}
                                fieldDesc={
                                    //TODO: In communities where hosts need approval, give special UI for showing signed up students
                                    !HostsNeedApproval(this.state.locationDetails) ?
                                        <span className="link-text"
                                            onClick={this.displayWeeklyStudentList.bind(this)}>
                                            Who's signed up this week?
                                        </span>
                                    : `There is no guarantee any of these ${guestType}s will be signed-up.`
                                }
                                errorCode="INVALID_STUDENTPREF"
                                setParentStudents={this.setStudentPref.bind(this)}
                                isErrorMessage={this.isErrorMessage.bind(this)}
                                locationDetails={this.state.locationDetails} />
                    }

                    {/* Submit Button */}
                    <div className="submit-form-button-container mt-2">
                        <Button variant="success" className="submit-form-button" type="submit">
                            Sign-Up
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default WeeklyHost;
