/**
 * This file defines the NameFields component.
 * It is used to input a first name and last name.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";

import {ProcessTextInput, RequiredTooltip} from '../Util';


export class NameFields extends Component
{
    /**
     * Represents the NameFields component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.firstName - The default first name input value.
     * @param {string} props.lastName - The default last name input value.
     * @param {string} props.firstNameErrorCode - The error code for the first name input.
     * @param {string} props.lastNameErrorCode - The error code for the last name input.
     * @param {Function} props.setParentFirstName - The function to set the parent component's first name.
     * @param {Function} props.setParentLastName - The function to set the parent component's last name.
     * @param {Function} props.validFirstName - The function to check if the first name is valid.
     * @param {Function} props.validLastName - The function to check if the last name is valid.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {string} props.idMod - The string to modify the id of the first name input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            firstNameInput: props.firstName,
            lastNameInput: props.lastName,
            firstNameErrorCode: props.firstNameErrorCode,
            lastNameErrorCode: props.lastNameErrorCode,
            idMod: props.idMod || "",
        }

        this.setParentFirstName = props.setParentFirstName;
        this.setParentLastName = props.setParentLastName;
        this.validFirstName = props.validFirstName;
        this.validLastName = props.validLastName;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Sets the first name and also updates the parent component.
     * @param {Object} e - The event object to get the input from.
     */
    setFirstName(e)
    {
        let name = ProcessTextInput(e, "NAME", false);
        this.setState({firstNameInput: name});
        this.setParentFirstName(name);
    }

    /**
     * Sets the last name and also updates the parent component.
     * @param {Object} e - The event object to get the input from.
     */
    setLastName(e)
    {
        let name = ProcessTextInput(e, "NAME", false);
        this.setState({lastNameInput: name});
        this.setParentLastName(name);
    }

    /**
     * Renders the NameFields component.
     * @returns {JSX.Element} The rendered NameFields component.
     */
    render()
    {
        const required = RequiredTooltip();

        return (
        <>
            {/*First Name Input*/}
            <Form.Group className="mb-3" controlId={"formBasicFirstName" + this.state.idMod}>
                <Form.Label>First Name{required}</Form.Label>
                <Form.Control
                    required
                    name="firstName"
                    data-testid={"first-name-input" + this.state.idMod}
                    //placeholder="John"
                    value={this.state.firstNameInput}
                    className={`form-control ${this.state.firstNameInput !== ""
                                            && !this.validFirstName()
                                            && this.isErrorMessage(this.state.firstNameErrorCode) ? 'is-invalid' : ''}`}
                    onChange={(e) => this.setFirstName(e)}
                />
            </Form.Group>

            {/*Last Name Input*/}
            <Form.Group className="mb-3" controlId={"formBasicLastName" + this.state.idMod}>
                <Form.Label>Last Name{required}</Form.Label>
                <Form.Control
                    required
                    name="lastName"
                    data-testid={"last-name-input" + this.state.idMod}
                    //placeholder="Smith"
                    value={this.state.lastNameInput}
                    className={`form-control ${this.state.lastNameInput !== ""
                                            && !this.validLastName()
                                            && this.isErrorMessage(this.state.lastNameErrorCode) ? 'is-invalid' : ''}`}
                    onChange={(e) => this.setLastName(e)}
                />
            </Form.Group>
        </>
        );
    }
}

export default NameFields;
