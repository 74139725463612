/**
 * This file defines the StudentGuestSelection component.
 * It is used to input details of guests a student is bringing.
 */

import React, {Component} from 'react';
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FaMale, FaFemale} from "react-icons/fa";

import {FoodDropdown} from './FoodDropdown';
import {RadioOptions} from './RadioOptions';
import {HostsHaveAgeRequest, ProcessTextInput, RequiredTooltip} from "../Util";

const MAX_GUESTS = 50;
const COVID_EXISTS = process.env.COVID_EXISTS != null;

const maleTooltip = props => (<Tooltip {...props}>Male</Tooltip>);
const femaleTooltip = props => (<Tooltip {...props}>Female</Tooltip>);

export const DEFAULT_GUEST_INPUT_FIELDS =
{
    maleGuestsInput: 0,
    femaleGuestsInput: 0,
    guestAllergiesInput: [],
    guestFoodPreferenceInput: [],
    guestMinAgeInput: "",
    guestMaxAgeInput: "",
    guestVaccinatedInput: COVID_EXISTS ? "" : true,
};


export class StudentGuestSelection extends Component
{
    /**
     * Represents the StudentGuestSelection component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {Boolean} props.maintainer - Whether the user is a maintainer.
     * @param {String} props.studentName - The name of the student bringing guests (maintainer only).
     * @param {Array} props.allergyNameList - The list of allergy names that can be selected.
     * @param {Array} props.foodPreferenceNameList - The list of food preference names that can be selected.
     * @param {Function} props.setParentGuestDetails - The function to set the parent component's guest details.
     * @param {Function} props.isErrorMessage - The function to check if an error message is displayed.
     * @param {Object} props.locationDetails - The details of the location the student is signing up for.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            ...DEFAULT_GUEST_INPUT_FIELDS,
            maintainer: props.maintainer,
            studentName: props.studentName,
            allergyNameList: props.allergyNameList,
            foodPreferenceNameList: props.foodPreferenceNameList,
            locationDetails: props.locationDetails,
        }

        this.setParentGuestDetails = props.setParentGuestDetails;
        this.isErrorMessage = props.isErrorMessage;

        if (this.isErrorMessage == null)
            this.isErrorMessage = () => false;
    }

    /**
     * Syncs the parent component's guest details with the current state values.
     */
    syncParentGuestDetails()
    {
        this.setParentGuestDetails(
            {
                maleGuestsInput: this.state.maleGuestsInput,
                femaleGuestsInput: this.state.femaleGuestsInput,
                guestAllergiesInput: this.state.guestAllergiesInput,
                guestFoodPreferenceInput: this.state.guestFoodPreferenceInput,
                guestMinAgeInput: this.state.guestMinAgeInput,
                guestMaxAgeInput: this.state.guestMaxAgeInput,
                guestVaccinatedInput: this.state.guestVaccinatedInput,
                allergyNameList: this.state.allergyNameList,
                foodPreferenceNameList: this.state.foodPreferenceNameList,
            },
        );
    }

   /**
    * Checks if the user chose to bring guests.
    * @returns {Boolean} Whether at least 1 guest of either gender was chosen.
    */
    bringingGuests()
    {
        return this.state.maleGuestsInput > 0 || this.state.femaleGuestsInput > 0;
    }

    /**
     * Checks if the user chose to bring only one guest.
     * @returns {Boolean} Whether only one guest was chosen.
     */
    bringingOneGuest()
    {
        return this.state.maleGuestsInput + this.state.femaleGuestsInput === 1;
    }

    /**
     * Sets the number of male guests the user has chosen. Max MAX_GUESTS.
     * @param {Number} value - The number of male guests chosen.
     */
    setMaleGuests(value)
    {
        this.setState({maleGuestsInput: Math.min(value, MAX_GUESTS)}, () =>
        {
            if (this.bringingOneGuest())
            {
                //Sync min and max age if only one guest is being brought
                this.setState({guestMaxAgeInput: this.state.guestMinAgeInput}, () =>
                {
                    this.syncParentGuestDetails();
                });
            }
            else
                this.syncParentGuestDetails();
        });
    }

    /**
     * Sets the number of female guests the user has chosen. Max MAX_GUESTS.
     * @param {Number} value - The number of female guests chosen.
     */
    setFemaleGuests(value)
    {
        this.setState({femaleGuestsInput: Math.min(value, MAX_GUESTS)}, () =>
        {
            if (this.bringingOneGuest())
            {
                //Sync min and max age if only one guest is being brought
                this.setState({guestMaxAgeInput: this.state.guestMinAgeInput}, () =>
                {
                    this.syncParentGuestDetails();
                });
            }
            else
                this.syncParentGuestDetails();
        });
    }

    /**
     * Sets the minimum age for the guests being brought.
     * @param {Event} e - The default event for changing a form input.
     */
    setMinGuestAge(e)
    {
        let minAge = Number(ProcessTextInput(e, "AGE", false));
        if (isNaN(minAge) || minAge === 0) //Prevent invalid input
            return;

        let maxAge = (this.bringingOneGuest()) ? minAge : this.state.guestMaxAgeInput; //Set both to the same value if only one guest is being brought
        this.setMinMaxGuestAge(minAge, maxAge);
    }

    /**
     * Sets the maximum age for the guests being brought.
     * @param {Event} e - The default event for changing a form input.
     */
    setMaxGuestAge(e)
    {
        let maxAge = Number(ProcessTextInput(e, "AGE", false));
        if (isNaN(maxAge) || maxAge === 0) //Prevent invalid input
            return;

        let minAge = (this.bringingOneGuest()) ? maxAge : this.state.guestMinAgeInput; //Set both to the same value if only one guest is being brought
        this.setMinMaxGuestAge(minAge, maxAge);
    }

    /**
     * Sets the minimum and maximum age for the guests being brought.
     * @param {number} minAge - The minimum age for the guests.
     * @param {number} maxAge - The maximum age for the guests.
     */
    setMinMaxGuestAge(minAge, maxAge)
    {
        this.setState
        ({
            guestMinAgeInput: minAge,
            guestMaxAgeInput: maxAge,
        }, () =>
        {
            this.syncParentGuestDetails();
        });
    }

    /**
     * Sets the allergies for the guests being brought.
     * @param {string} allergies - The allergies to set for the guest.
     */
    setGuestAllergies(allergies)
    {
        this.setState({guestAllergiesInput: allergies}, () =>
        {
            this.syncParentGuestDetails();
        });
    }

    /**
     * Sets the food preference for the guests being brought.
     * @param {string} foodPref - The food preferences to set for the guest.
     */
    setGuestFoodPreference(foodPref)
    {
        this.setState({guestFoodPreferenceInput: foodPref}, () =>
        {
            this.syncParentGuestDetails();
        });
    }

    /**
     * Updates the list of allergy names that can be selected.
     * @param {Array} allergyList - The list of allergy names.
     */
    setAllergyNameList(allergyList)
    {
        this.setState({allergyNameList: allergyList}, () =>
        {
            this.syncParentGuestDetails();
        });
    }

    /**
     * Updates the list of food preference names that can be selected.
     * @param {Array} foodPrefList - The list of food preference names.
     */
    setFoodPreferenceNameList(foodPrefList)
    {
        this.setState({foodPreferenceNameList: foodPrefList}, () =>
        {
            this.syncParentGuestDetails();
        });
    }

    /**
     * Sets whether or not the guests being brought are all vaccinated against COVID-19.
     * @param {Boolean} isVaccinated - Whether the guests are vaccinated.
     */
    setGuestsVaccinated(isVaccinated)
    {
        this.setState({guestVaccinatedInput: isVaccinated});
    }

    /**
     * Renders the StudentGuestSelection component.
     * @returns {JSX.Element} The rendered StudentGuestSelection component.
     */
    render()
    {
        const required = RequiredTooltip();

        return (
        <>
            {/*Male/Female Guest Input*/}
            <Form.Group className="mb-3">
                {
                    !this.state.maintainer &&
                        <Form.Label className="mb-0">    
                            Are you bringing any guests along with you?
                        </Form.Label>
                }
                <div className="mb-1">
                    <Form.Text className="text-muted">
                        Specifically, any {this.state.locationDetails.guestType}s who are not signing up themselves.
                    </Form.Text>
                </div>
                <span className="weekly-guest-input-container">
                    <OverlayTrigger placement="top" overlay={maleTooltip}>
                        <span className="weekly-guest-gender-input-container">
                            <FaMale size={30} className="clickable-icon no-select"
                                onClick = {() => this.setMaleGuests(this.state.maleGuestsInput + 1)}/>
                            <Form.Control
                                required
                                type="number"
                                name="maleGuests"
                                min={0}
                                max={MAX_GUESTS}
                                value={this.state.maleGuestsInput}
                                onChange={(e) => this.setMaleGuests(e.target.value)}
                            />
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={femaleTooltip}>
                        <span className="weekly-guest-gender-input-container">
                            <Form.Control
                                required
                                type="number"
                                name="femaleGuests"
                                min={0}
                                max={MAX_GUESTS}
                                value={this.state.femaleGuestsInput}
                                onChange={(e) => this.setFemaleGuests(e.target.value)}
                            />
                            <FaFemale size={30} className="clickable-icon no-select"
                                onClick = {() => this.setFemaleGuests(this.state.femaleGuestsInput + 1)}/>
                        </span>
                    </OverlayTrigger>
                </span>
            </Form.Group>

            {
                this.bringingGuests() &&
                <>
                    {/*Guest Age Range Input*/}
                    {
                        HostsHaveAgeRequest(this.state.locationDetails) &&
                            <Form.Group className="mb-3">
                                <Form.Label>
                                {
                                    this.state.maintainer ?
                                        <>Guest Age Range</>
                                    :
                                        <>What is the age range of the guests you are bringing?{required}</>
                                }
                                </Form.Label>
                                <br/>
                                <div className="weekly-age-range-container">
                                    <Form.Control
                                        className="weekly-age-range-input"
                                        required
                                        name="minAge"
                                        type="number"
                                        min="1"
                                        max="120"
                                        placeholder="Min"
                                        value={this.state.guestMinAgeInput}
                                        onChange={(e) => this.setMinGuestAge(e)}
                                    />
                                    <div className="weekly-age-range-separator">
                                        to
                                    </div>
                                    <Form.Control
                                        className="weekly-age-range-input"
                                        required
                                        name="maxAge"
                                        type="number"
                                        min="1"
                                        max="120"
                                        placeholder="Max"
                                        value={this.state.guestMaxAgeInput}
                                        onChange={(e) => this.setMaxGuestAge(e)}
                                    />
                                </div>
                            </Form.Group>
                    }

                    {/*Guest Allergy Input*/}
                    <FoodDropdown
                        foodSelection={this.state.guestAllergiesInput}
                        foodNameList={this.state.allergyNameList}
                        fieldLabel={
                            this.state.maintainer ?
                                `Guest Food Allergies`
                            :
                                "Do the guests you are bringing have any food allergies?"
                        }
                        fieldDesc="If one isn't listed here, feel free to add it!"
                        allowAdditions={true}
                        isAllergyList={true}
                        errorCode="INVALID_GUESTALLERGIES"
                        setParentFoodSelection={this.setGuestAllergies.bind(this)}
                        setParentFoodOptions={this.setAllergyNameList.bind(this)}
                        isErrorMessage={this.isErrorMessage.bind(this)} />

                    {/*Guest Food Preference Input*/}
                    <FoodDropdown
                        foodSelection={this.state.guestFoodPreferenceInput}
                        foodNameList={this.state.foodPreferenceNameList}
                        fieldLabel={
                            this.state.maintainer ?
                                `Guest Food Preferences`
                            :
                                "Do the guests you are bringing have any food preferences?"
                        }
                        allowAdditions={false}
                        isAllergyList={false}
                        errorCode="INVALID_FOODPREF"
                        setParentFoodSelection={this.setGuestFoodPreference.bind(this)}
                        setParentFoodOptions={this.setFoodPreferenceNameList.bind(this)}
                        isErrorMessage={this.isErrorMessage.bind(this)} />

                    {/*Vaccinated Input*/}
                    {
                        COVID_EXISTS &&
                            <RadioOptions
                                input={this.state.guestVaccinatedInput}
                                radioLabels={[{label: "Yes", val: true}, {label: "No", val: false}]}
                                fieldLabel={
                                    this.state.maintainer ?
                                        `Are the guests ${this.state.studentName} is bringing fully vaccinated against COVID-19?`
                                    :
                                        "Are your guests fully vaccinated against COVID-19?"
                                }
                                required={true}
                                setParentInput={this.setGuestsVaccinated.bind(this)} />
                    }
                </>
            }
        </>
        );
    }
}
