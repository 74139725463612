/*
    A class for the person maintaing the site to view various statistucs and add new dates.
*/

import React, {Component} from 'react';
import {Accordion, Button, Form} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'; //Pop-Up
import withReactContent from 'sweetalert2-react-content';
import _ from 'lodash'; //For deep copying

import {PATH_MAINTAINER_UI} from './App';
import {Capitalize, HolyDayToString, GetFoodRestrictionMatches, CalculateAge, CalculateDaysSince, UpdateDistanceToHost,
        CreateNameLists, GetMaintainerDashboardInfo, SendFormToServer, ErrorPopUp,
        StudentsHostsHaveProximityChecking, StudentsHostsHavePoliticalLeaning, MealsNeedApproval, GuestsNeedApproval,
        HostsNeedApproval, HostsHaveAgeRequest, HostsGetUnallocatedEmails,
        HOLY_DAY_TYPES, GLOBAL_ERROR_MESSAGES, CUSTOM_POPUP_BUTTON_CLASSES_CONTINUE, CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS} from './Util';
import {GoogleMapsLink} from './subcomponents/AddressField';
import {RenderDatePickerHeader} from './subcomponents/BirthdayField';
import {PasswordField} from './subcomponents/PasswordField';
import {WhatsAppLink} from './subcomponents/PhoneNumberField';
import {AllocationWarnings} from './subcomponents/maintainer/AllocationWarnings';
import {GenderedName} from './subcomponents/maintainer/GenderedName';
import {MoveStudentButton} from './subcomponents/maintainer/MoveStudentButton';
import {WeeklyStudentButton} from './subcomponents/maintainer/WeeklyStudentButton';

import {BiSolidGraduation, BiSolidTrash} from "react-icons/bi";
import {MdCancel, MdCheck, MdRefresh} from "react-icons/md";

import "react-datepicker/dist/react-datepicker.css";
import "./styles/Maintainer.css";

const ReactPopUp = withReactContent(Swal);
const COVID_EXISTS = process.env.COVID_EXISTS != null;
const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_PASSWORD: "Unauthorized access!",
    INVALID_DATES: "One or more of the dates you entered is invalid.",
    WEEK_ALREADY_EXISTS: "One or more of the dates you entered already exists in the database.",
    NOT_SHABBAT: "One or more of the dates you entered is not on Shabbat.",
    NOT_HOLIDAY: "One or more of the dates you entered is not a holiday.",
    DATE_PASSED: "One or more of the dates you entered has already passed.",
    NULL_DATES: "The dates to add were wiped before reaching the server!",

    //Errors when trying to change graduation status
    NULL_STUDENT_LIST: "The list of students was wiped before reaching the server!",
    NULL_HOST_LIST: "The list of hosts was wiped before reaching the server!",
    INVALID_EMAILS: "One or more of the emails submitted does not belong to anyone.",
    INVALID_STUDENTEMAILS: "One or more of the emails submitted does not belong to any student.",
    INVALID_GRADUATEDVAL: "The graduated value submitted is invalid.",

    //Errors when trying to cancel a sign-up
    NO_ACCOUNT_FOUND: "No account with that email exists!",
    NOT_SIGNED_UP: "No sign-up was found for this date!",
    DIDNT_SIGN_UP: "No sign-up was found for this date!",

    //Errors when trying to update allocations
    ALLOCATION_CHANGED: "The allocations have changed since the page was last loaded!\nPlease reload the page.",
    INVALID_REGISTEREDHOSTS: "The new registered hosts data was not formatted correctly!\nPLEASE CONTACT SUPPORT.",
    INVALID_ORIGINALREGISTEREDHOSTS: "The original registered hosts data was not formatted correctly!\nPLEASE CONTACT SUPPORT.",
};

const DETAILS_TITLE_CLASS = "fw-bold small";


export class MaintainerView extends Component
{
    /**
     * Sets up the maintainer view page.
     */
    constructor(props)
    {
        super(props);

        //Set the default date for the add dates section
        let defaultDate = new Date();
        defaultDate.setHours(0, 0, 0, 0);
        if (defaultDate.getDay() !== 6) //If today is not Saturday
            defaultDate.setDate(defaultDate.getDate() + (6 - defaultDate.getDay())); //The default date is the upcoming Saturday
        else
            defaultDate.setDate(defaultDate.getDate() + 7); //If today is Saturday, go to next Saturday

        this.state =
        {
            students: {},
            hosts: {},
            registeredStudents: {},
            registeredHosts: {},
            registeredHostsBackup: {},
            previousRegisteredHosts: {},
            upcomingDates: [],
            previousDates: [],
            setStudentsApproved: {},
            setStudentsUnapproved: {},
            setStudentsGraduated: {},
            setStudentsUngraduated: {},
            setHostsApproved: {},
            setHostsUnapproved: {},
            chosenDatesToAdd: [defaultDate, null],
            chosenHolyDaysToAdd: ["Shabbat", "Shabbat"],
            chosenMealsToAdd: ["lunch", "lunch"],
            useFallbackHostsToAdd: [true, true],
            passwordInput: "",
            showMealHistory: false,
            allergyNameList: [],
            foodPreferenceNameList: [],
            loaded: false, //Page can only be accessed after the user has entered the password
            locationDetails: props.locationDetails,
        }
    }

    /**
     * Sets the state of the component and waits for the state update to complete.
     * @param {Object} newState - The new state object to set.
     * @returns {Promise} A promise that resolves when the state update is complete.
     */
    async setStateAndWait(newState)
    {
        return new Promise(resolve => this.setState(newState, resolve));
    }

    /**
     * @param {Object} e - The form submission event.
     */
    async loadData(e)
    {
        if (e)
            e.preventDefault(); //Prevent the page from reloading

        ReactPopUp.fire
        ({
            title: 'Connecting, please wait...',
            timer: 30000, //30 seconds
            timerProgressBar: true,
            allowOutsideClick: false,
            showConfirmButton: false,
            showCancelButton: false,
            didOpen: async () =>
            {
                let {allergies, foodPreferences} = await CreateNameLists();
                let ret = await GetMaintainerDashboardInfo(this.state.passwordInput, this.state.locationDetails);
                if (ret != null)
                {
                    let {dates, prevDates, studentList, hostList, weekStudents, weekHosts, prevWeekHosts} = ret;

                    //Update the cached distances to the hosts
                    for (let date of Object.values(weekHosts))
                    {
                        for (let meal of Object.values(date))
                        {
                            for (let regHost of Object.values(meal))
                            {
                                for (let studentEmail of regHost.hosting)
                                {
                                    let student = studentList[studentEmail];
                                    let host = hostList[regHost.email];
                                    await UpdateDistanceToHost(student, host);
                                }
                            }
                        }
                    }

                    this.setState
                    ({
                        loaded: true, //Page can now be accessed
                        students: studentList,
                        hosts: hostList,
                        registeredStudents: weekStudents,
                        registeredHosts: weekHosts,
                        registeredHostsBackup: _.cloneDeep(weekHosts),
                        previousRegisteredHosts: prevWeekHosts,
                        upcomingDates: dates,
                        previousDates: prevDates,
                        allergyNameList: allergies,
                        foodPreferenceNameList: foodPreferences,
                    });
                    Swal.close();
                }
                else
                    this.errorPopUp("INVALID_PASSWORD");
            },
        }).then((e) =>
        {
            if (e.dismiss === Swal.DismissReason.timer)
            {
                this.errorPopUp("NO_SERVER_CONNECTION");
                return null;
            }
        });
    }

    /**
     * Checks if the approval feature is enabled based on the location details.
     * @returns {boolean} Whether the approval feature is enabled.
     */
    useApprovals()
    {
        return GuestsNeedApproval(this.state.locationDetails) || HostsNeedApproval(this.state.locationDetails);
    }

    /**
     * Checks if the graduation feature is enabled based on the location details.
     * @returns {boolean} Whether the graduation feature is enabled.
     */
    useGraduation()
    {
        return this.state.locationDetails.guestType === "student";
    }

    /**
     * Gets a student from the list of all students based on the student's email.
     * @param {string} email - The email of the student to get.
     * @returns {Object} The student with the given email.
     */
    getStudent(email)
    {
        return this.state.students[email];
    }

    /**
     * Gets a host from the list of all hosts based on the host's email.
     * @param {string} email - The email of the host to get.
     * @returns {Object} The host with the given email.
     */
    getHost(email)
    {
        return this.state.hosts[email];
    }

    /**
     * Gets a student from the list of registered students based on the student's email.
     * @param {string} email - The email of the student to get.
     * @param {string} date - The date of the meal.
     * @param {string} meal - The meal type of the meal (e.g., lunch or dinner).
     * @returns {Object} The weekly student with the given email.
     */
    getRegisteredStudent(email, date, meal)
    {
        if (this.state.registeredStudents[date] == null
        || this.state.registeredStudents[date][meal] == null
        || this.state.registeredStudents[date][meal][email] == null)
            return null;

        return this.state.registeredStudents[date][meal][email];
    }

    /**
     * Gets a host from the list of registered hosts based on the host's email.
     * @param {string} email - The email of the host to get.
     * @param {string} date - The date of the meal.
     * @param {string} meal - The meal type of the meal (e.g., lunch or dinner).
     * @returns {Object} The weekly host with the given email.
     */
    getRegisteredHost(email, date, meal)
    {
        if (this.state.registeredHosts[date] == null
        || this.state.registeredHosts[date][meal] == null
        || this.state.registeredHosts[date][meal][email] == null)
            return null;

        return this.state.registeredHosts[date][meal][email];
    }

    /**
     * Counts the total number of people signed up for a specific date and meal.
     * @param {string} date - The date for which to count.
     * @param {string} meal - The meal for which to count.
     * @returns {number} - The total number of students and their guests.
     */
    countStudentsAndGuests(date, meal)
    {
        let students = Object.values(this.state.registeredStudents[date][meal]);
        let totalStudents = students.length;

        //Count the number of guests
        for (let student of students)
        {
            totalStudents += student.maleGuests;
            totalStudents += student.femaleGuests;
        }

        return totalStudents;
    }

    /**
     * Calculates the total spots available for a specific date and meal.
     * @param {string} date - The date for which to count.
     * @param {string} meal - The meal for which to count.
     * @returns {number} The total capacity of hosts for the date and meal.
     */
    countTotalHostCapacity(date, meal)
    {
        let totalCapacity = 0;
        for (let host of Object.values(this.state.registeredHosts[date][meal]))
            totalCapacity += host.capacity;
        return totalCapacity;        
    }

    /**
     * Calculates the number of people currently going to a specific host.
     * @param {Object} host - The host to calculate the capacity of.
     * @returns {Number} The number of people currently going to the host.
     */
    caclulateHostCapacity(host, date, meal)
    {
        //The length of the hosting list
        let capacity = host.hosting.length;

        //Add the number of guests of each student signed up for the week
        for (let email of host.hosting)
        {
            //Find the student with the email in the registered students list
            let student = this.getRegisteredStudent(email, date, meal);
            if (student)
            {
                capacity += student.maleGuests;
                capacity += student.femaleGuests;
            }
        }

        return capacity;
    }

    /**
     * Gets the number of guests a past student brought to a specific meal.
     * @param {string} email - The email of the student to check.
     * @param {string} date - The date of the meal.
     * @param {string} meal - The meal type (e.g., lunch or dinner).
     * @returns {number} The number of guests the student brought to the meal.
     */
    calculatePrevWeekStudentGuests(email, date, meal)
    {
        let student = this.getStudent(email);
        if (!student)
            return 0;

        let studentWeekData = student.weeks.find(week =>
            _.isObject(week) //Exclude legacy data
            && "date" in week
            && "meal" in week
            && "community" in week
            && week.date === date
            && week.meal === meal
            && week.community === this.state.locationDetails.community);
        return (studentWeekData && "guests" in studentWeekData) ? studentWeekData.guests : 0;
    }

    /**
     * Calculates the number of people that were hosted by a host in a previous week.
     * @param {Object} host - The host to calculate the capacity of.
     * @param {string} date - The date of the meal.
     * @param {string} meal - The meal type (e.g., lunch or dinner).
     * @returns {Number} The number of people hosted by the host in the previous week.
     */
    caclulatePrevHostCapacity(host, date, meal)
    {
        //The length of the hosting list
        let capacity = host.hosting.length;

        //Add the number of guests of each student signed up for the week
        for (let email of host.hosting)
            capacity += this.calculatePrevWeekStudentGuests(email, date, meal);

        return capacity;
    }

    /**
     * Checks if a student is allocated to a host for a specific meal.
     * @param {Object} student - The student to check.
     * @param {string} date - The date of the meal.
     * @param {string} meal - The meal type (e.g., lunch or dinner).
     * @returns {Object} The host the student is allocated to, or null if the student is not allocated.
     */
    allocatedToHost(student, date, meal)
    {
        //Get the host the student is allocated to
        let registeredHostsList = Object.values(this.state.registeredHosts[date][meal]);
        return registeredHostsList.find(host => host.hosting.includes(student.email)); //Returns null if not found
    }

    /**
     * Formats a list of requested students.
     * @param {Array<String>} requestsList - The list of requested student emails.
     * @param {Object} registeredStudents - The map of registered students for the week.
     * @param {Object} registeredHosts - The map of registered hosts for the week.
     * @returns {JSX.Element[]} - The formatted list of requested students.
     */
    formatRequestsList(requestsList, registeredStudents, registeredHosts)
    {
        requestsList = _.cloneDeep(requestsList); //So the sorting doesn't affect the original list

        //Sort emails that are actually present in the registered students list to the top
        if (requestsList)
        {
            requestsList.sort((a, b) =>
            {
                let aStudent = registeredStudents[a];
                let bStudent = registeredStudents[b];
                if (aStudent && bStudent)
                    return aStudent.name.localeCompare(bStudent.name);
                if (aStudent)
                    return -1;
                if (bStudent)
                    return 1;
                return a.localeCompare(b);
            });
        }

        //Set up the requests view
        let registeredHostsList = Object.values(registeredHosts);
        requestsList = (requestsList) ?
            requestsList.map(
                //Convert the array of friend emails to friend names
                //For students signed up for the week, colour their names
                friendEmail => {
                    let friend = this.getStudent(friendEmail);
                    let regFriend = registeredStudents[friendEmail];
                    let friendOnClick = (friend) ? this.displayStudentDetailsPopUp.bind(this, friend) : null;
                    if (regFriend)
                    {
                        //Show the friend's name in green and the host they're hosted at in brackets
                        let hostedAtFunc = null;
                        let hostedAtName = "";
                        let hostedAt = registeredHostsList.find(host => host.hosting.includes(friendEmail));
                        if (hostedAt)
                        {
                            let host = this.getHost(hostedAt.email);
                            hostedAtName = ` (at ${hostedAt.name})`;
                            hostedAtFunc = this.displayHostDetailsPopUp.bind(this, host); //Show host details when clicked
                        }

                        return (
                            <li>
                                <GenderedName name={regFriend.name} gender={(friend) ? friend.gender : ""}
                                              onClick={friendOnClick} />
                                <span className={"text-success " + ((hostedAtFunc) ? "clickable" : "")}
                                      onClick={hostedAtFunc}>
                                    {hostedAtName}
                                </span>
                            </li>
                        );
                    }

                    //Show the name struck through if the student is not signed up for the week
                    return (
                        <li className="text-decoration-line-through">
                            <span className={(friendOnClick) ? "clickable" : ""} onClick={friendOnClick}>
                                {(friend) ? `${friend.firstName} ${friend.lastName}` : friendEmail}
                            </span>
                        </li>
                    );
                }
            ) : [];

        return requestsList;
    }

    /**
     * Checks if any changes have been made to the registered hosts list.
     * @returns {boolean} Whether the registered hosts list has changed.
     */
    registeredHostsHaveChanged()
    {
        return !_.isEqual(this.state.registeredHosts, this.state.registeredHostsBackup);
    }

    /**
     * Checks if a student is currently toggled to be set as approved or unapproved.
     * @param {string} email - The email of the student to check.
     * @param {boolean} unapprovedList - Whether to check the unapproved list or the approved list.
     * @returns {boolean} - Whether the student is currently toggled to be set as approved or unapproved.
     */
    isStudentToggledForApproval(email, unapprovedList)
    {
        let key = (unapprovedList) ? "setStudentsApproved" : "setStudentsUnapproved";
        return email in this.state[key];
    }

    /**
     * Checks if a student is currently toggled to be set as graduated or ungraduated.
     * @param {String} email - The email of the student to check.
     * @param {Boolean} graduatedList - Whether to check the graduated list or the ungraduated list.
     * @returns {Boolean} Whether the student is currently toggled to be set as graduated or ungraduated.
     */
    isStudentToggledForGraduation(email, graduatedList)
    {
        let key = (graduatedList) ? "setStudentsUngraduated" : "setStudentsGraduated";
        return email in this.state[key];
    }

    /**
     * Checks if a host is currently toggled to be set as approved or unapproved.
     * @param {string} email - The email of the host to check.
     * @param {boolean} unapprovedList - Whether to check the unapproved list or the approved list.
     * @returns {boolean} - Whether the host is currently toggled to be set as approved or unapproved.
     */
    isHostToggledForApproval(email, unapprovedList)
    {
        let key = (unapprovedList) ? "setHostsApproved" : "setHostsUnapproved";
        return email in this.state[key];
    }

    /**
     * Toggles a person in the list based on the provided email and name.
     * If the person is already in the list, they will be removed.
     * If the person is not in the list, they will be added.
     * @param {string} email - The email of the person.
     * @param {string} name - The name of the person.
     * @param {string} listKey - The key of the list in the component's state.
     */
    togglePersonInList(email, name, listKey)
    {
        let setList = this.state[listKey];
        if (email in setList)
            delete setList[email];
        else
            setList[email] = name;

        this.setState({[listKey]: setList});
    }

    /**
     * Toggle a student to be set as approved or unapproved.
     * @param {string} email - The email of the student to toggle.
     * @param {string} name - The name of the student to toggle.
     * @param {boolean} unapprovedList - Whether to toggle the unapproved list or the approved list.
     */
    toggleStudentApproval(email, name, unapprovedList)
    {
        //If unapprovedList, already unapproved so the toggle would be to approve them
        let key = (unapprovedList) ? "setStudentsApproved" : "setStudentsUnapproved";
        this.togglePersonInList(email, name, key);
    }

    /**
     * Toggles a student to be set as graduated or ungraduated.
     * @param {String} email - The email of the student to toggle.
     * @param {String} name - The name of the student to toggle.
     * @param {Boolean} graduatedList - Whether to toggle the graduated list or the ungraduated list.
     */
    toggleStudentGraduation(email, name, graduatedList)
    {
        //If graduatedList, already graduated so the toggle would be to ungraduate them
        let key = (graduatedList) ? "setStudentsUngraduated" : "setStudentsGraduated";
        this.togglePersonInList(email, name, key);
    }

    /**
     * Toggles a host to be set as approved or unapproved.
     * @param {string} email - The email of the host to toggle.
     * @param {string} name - The name of the host to toggle.
     * @param {boolean} unapprovedList - Whether to toggle the unapproved list or the approved list.
     */
    toggleHostApproval(email, name, unapprovedList)
    {
        let key = (unapprovedList) ? "setHostsApproved" : "setHostsUnapproved";
        this.togglePersonInList(email, name, key);
    }

    /**
     * Adds new dates hosting is available to the database.
     */
    async addDates()
    {
        //Zip together the dates holy day types, and meals
        let chosenHolyDaysToAdd = this.state.chosenHolyDaysToAdd;
        let chosenMealsToAdd = this.state.chosenMealsToAdd;
        let useFallbackHostsToAdd = this.state.useFallbackHostsToAdd;
        let data = this.state.chosenDatesToAdd.map(function(date, i) {
            return {date: date, type: chosenHolyDaysToAdd[i], meal: chosenMealsToAdd[i], useFallbackHost: useFallbackHostsToAdd[i]};
        });

        //Remove any empty dates
        data = data.filter(function(date) {
            return date.date !== "" && date.date != null;
        });

        //Sort dates by date and if they're the same then put lunch meals first
        data.sort(function(a, b) {
            if (a.date === b.date)
                return a.meal === "lunch" ? -1 : 1;
            return a.date < b.date ? -1 : 1;
        });

        //Convert all to format YYYY-MM-DD
        data = data.map(function(date) {
            return {date: date.date.toLocaleDateString("en-CA"), type: date.type, meal: date.meal, useFallbackHost: date.useFallbackHost};
        });
        await SendFormToServer({dates: data}, this, "/addweekstodatabase", 'Dates were added successfully!',
                               this.state.locationDetails, /*this.state.upcomingDates.length === 0 ?*/ PATH_MAINTAINER_UI /*: null*/); //Always refresh so all new weeks are shown
    }

    /**
     * Sets the approval status of the people in the list to the given value.
     * @param {String[]} emailList - The list of emails to set the approval status of.
     * @param {Boolean} approvalVal - Whether to approve or unapprove.
     * @param {String} personType - The person type such as student or host.
     * @returns {Boolean} Whether the people were set as approved or unapproved successfully.
     */
    async setPersonApprovalStatus(emailList, approvalVal, personType)
    {
        const route = (personType === "host") ? "/maintainerapprovehosts" : "/maintainerapprovestudents";
        const approvalText = (approvalVal) ? "approve" : "unapprove";

        let {isConfirmed} = await ReactPopUp.fire
        ({
            title: `Are you sure you want to ${approvalText} these ${personType}s `
                 + `for ${this.state.locationDetails.community}?`,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            buttonsStyling: false,
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS,
        });

        if (!isConfirmed)
            return false;

        const data =
        {
            emails: emailList,
            approvalVal: approvalVal,
            password: this.state.passwordInput,
        };

        await SendFormToServer(data, this, route,
            `${Capitalize(personType)}s were ${approvalText}d successfully!`,
            this.state.locationDetails, null);

        //Loop while the formRetVal is null and at most for 30 seconds
        while (this.state.formRetVal == null)
        {
            let time = new Date().getTime();
            await new Promise(resolve => setTimeout(resolve, 100));
            if (new Date().getTime() - time > 30000)
                break;
        }

        return this.state.formRetVal;
    }

    /**
     * Processes the person approval return value and updates the internal state accordingly.
     * @param {Object} ret - The person approval return value.
     * @param {boolean} approvalVal - The approval value to set for the changed people.
     * @param {string} peopleKey - The key of the state property that holds the people list.
     * @param {string} changeListStateKey - The key of the state property that holds the change list.
     */
    async processPersonApprovalRet(ret, approvalVal, peopleKey, changeListStateKey)
    {
        if (!ret || Object.keys(ret).length === 0)
            return;

        //Update the internal value of the people listed in the ret's changed field
        let people = this.state[peopleKey];
        ret.changed.forEach(email =>
        {
            let index = people.findIndex(person => person.email === email);
            if (index !== -1)
                people[index].approved = approvalVal;
        });

        this.setState
        ({
            [peopleKey]: people,
            [changeListStateKey]: [],
            formRetVal: null,
        });
    }

    /**
     * Sets the students selected to be approved.
     */
    async setStudentsApproved()
    {
        let ret = await this.setPersonApprovalStatus(
            Object.keys(this.state.setStudentsApproved), true, this.state.locationDetails.guestType);
        this.processPersonApprovalRet(ret, true, "students", "setStudentsApproved");
    }

    /**
     * Sets the students selected to be unapproved.
     */
    async setStudentsUnapproved()
    {
        let ret = await this.setPersonApprovalStatus(
            Object.keys(this.state.setStudentsUnapproved), false, this.state.locationDetails.guestType);
        this.processPersonApprovalRet(ret, false, "students", "setStudentsUnapproved");
    }

    /**
     * Sets the hosts selected to be approved.
     */
    async setHostsApproved()
    {
        let ret = await this.setPersonApprovalStatus(Object.keys(this.state.setHostsApproved), true, "host");
        this.processPersonApprovalRet(ret, true, "hosts", "setHostsApproved");
    }

    /**
     * Sets the hosts selected to be unapproved.
     */
    async setHostsUnapproved()
    {
        let ret = await this.setPersonApprovalStatus(Object.keys(this.state.setHostsUnapproved), false, "host");
        this.processPersonApprovalRet(ret, false, "hosts", "setHostsUnapproved");
    }

    /**
     * Sets the graduation status of the students in the list to the given value.
     * @param {String[]} emailList - The list of emails of the students to set the graduation status of.
     * @param {Boolean} graduatedVal - The graduation status to set the students to.
     * @param {String} type - The type of graduation status to set the students to.
     * @returns {Boolean} Whether the students were set as graduated or ungraduated successfully.
     */
    async setStudentsGraduationStatus(emailList, graduatedVal, type)
    {
        let {isConfirmed} = await ReactPopUp.fire
        ({
            title: `Are you sure you want to set these students as ${type}?`,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            buttonsStyling: false,
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS,
        });

        if (!isConfirmed)
            return false;

        let data =
        {
            studentEmails: emailList,
            graduatedVal: graduatedVal,
            password: this.state.passwordInput,
        };

        await SendFormToServer(data, this, "/maintainersetstudentsgraduated",
                               `${Capitalize(this.state.locationDetails.guestType)}s were set as ${type} successfully!`,
                               this.state.locationDetails, PATH_MAINTAINER_UI);
        return true;
    }

    /**
     * Sets the students selected to be graduated as graduated.
     */
    async setStudentsGraduated()
    {
        await this.setStudentsGraduationStatus(Object.keys(this.state.setStudentsGraduated), true, "graduated");
    }

    /**
     * Sets the students selected to be ungraduated as ungraduated.
     */
    async setStudentsUngraduated()
    {
        await this.setStudentsGraduationStatus(Object.keys(this.state.setStudentsUngraduated), false, "ungraduated");
    }

    /**
     * Adds a student's sign-up data for a specific date and meal to the registered students list.
     * @param {string} date - The date of the sign-up.
     * @param {string} meal - The meal for which the student is signing up.
     * @param {any} data - The sign-up data of the student.
     */
    addWeeklySignUp(date, meal, data)
    {
        let weeklySignUp = this.state.registeredStudents;
        weeklySignUp[date][meal][data.email] = data;
        this.setState({registeredStudents: weeklySignUp});
    }

    /**
     * Cancels a student's sign-up for the upcoming week.
     * @param {Object} student - The student to cancel the sign-up of.
     */
    async cancelStudentSignUp(student, date, type, meal)
    {
        //Show a pop-up confirming the cancellation
        let {isConfirmed} = await ReactPopUp.fire
        ({
            title: `Are you sure you want to cancel ${student.name}'s sign-up?`,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            buttonsStyling: false,
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS,
        });

        if (!isConfirmed)
            return;

        let data =
        {
            email: student.email,
            password: this.state.passwordInput,
            date: date,
            type: type,
            meal: meal,
            cancelCouple: false,
        };

        await SendFormToServer(data, this, "/maintainerweeklystudentcancellation", `${student.name} is no longer signed up to attend!`,
                               this.state.locationDetails, PATH_MAINTAINER_UI);
    }

    /**
     * Cancels a host's sign-up for the upcoming week.
     * @param {Object} host - The host to cancel the sign-up of.
     */
    async cancelHostSignUp(host, date, type, meal)
    {
        //Show a pop-up confirming the cancellation
        let {isConfirmed} = await ReactPopUp.fire
        ({
            title: `Are you sure you want to cancel ${host.name}'s sign-up?`,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            buttonsStyling: false,
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS,
        });

        if (!isConfirmed)
            return;

        let data =
        {
            email: host.email,
            password: this.state.passwordInput,
            date: date,
            type: type,
            meal: meal,
        };

        await SendFormToServer(data, this, "/maintainerweeklyhostcancellation", `${host.name} is no longer signed up to host!`,
                               this.state.locationDetails, PATH_MAINTAINER_UI);
    }

    /**
     * Moves a student to a specific host for a specific date and meal.
     * @param {Object} regStudent - The student to move.
     * @param {String} hostEmail - The email of the host to move the student to.
     * @param {String} date - The date of the meal.
     * @param {String} meal - The meal type of the meal (e.g., lunch or dinner).
     */
    async moveStudentToHost(regStudent, hostEmail, date, meal)
    {
        //Add the student to the registered host that the student is in
        let registeredHosts = this.state.registeredHosts;
        let studentEmail = regStudent.email;

        let regHost = this.getRegisteredHost(hostEmail, date, meal);
        if (regHost)
        {
            await UpdateDistanceToHost(this.getStudent(studentEmail), this.getHost(hostEmail));
            regHost.hosting.push(studentEmail);
        }

        this.setState({registeredHosts: registeredHosts});
    }

    /**
     * Removes a student from a specific host for a specific date and meal.
     * @param {Object} student - The student to remove.
     * @param {String} date - The date of the meal.
     * @param {String} meal - The meal type of the meal (e.g., lunch or dinner).
     */
    async removeStudentFromHost(student, date, meal)
    {
        //Remove it from the registered host that the student is in
        let registeredHosts = this.state.registeredHosts;
        let studentEmail = student.email;

        for (let host of Object.values(registeredHosts[date][meal]))
        {
            let index = host.hosting.indexOf(studentEmail);
            if (index !== -1)
            {
                host.hosting.splice(index, 1);
                break;
            }
        }

        this.setState({registeredHosts: registeredHosts});
    }

    async handleSaveMovedStudents(date, meal)
    {
        //Show a pop-up confirming the approval
        let {isConfirmed} = await ReactPopUp.fire
        ({
            title: `Save the changes made to the allocation?`,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            buttonsStyling: false,
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS,
        });

        if (!isConfirmed)
            return;

        //Just send the hosting list of each host
        //E.g. {host1: [student1, student2], host2: [student3, student4]}
        let reductionFunc = (acc, host) =>
        {
            acc[host.email] = host.hosting;
            return acc;
        }

        let data =
        {
            registeredHosts: Object.values(this.state.registeredHosts[date][meal]).reduce(reductionFunc, {}),
            originalRegisteredHosts: Object.values(this.state.registeredHostsBackup[date][meal]).reduce(reductionFunc, {}),
            date: date,
            meal: meal,
            password: this.state.passwordInput,
        };

        await SendFormToServer(data, this, `/maintainerupdateallocation`, `Allocation updated successfully!`,
                               this.state.locationDetails, null);

        //Loop while the formRetVal is null and at most for 30 seconds
        while (this.state.formRetVal == null)
        {
            let time = new Date().getTime();
            await new Promise(resolve => setTimeout(resolve, 100));
            if (new Date().getTime() - time > 30000)
                break;
        }

        //Update using the data sent back from the server
        let ret = this.state.formRetVal;
        if (ret && Object.keys(ret).length > 0)
        {
            //Updated the registered hosts list
            let registeredHosts = this.state.registeredHosts;
            registeredHosts[date][meal] = ret.updatedHosts;

            //Update the allocated field of the week
            let upcomingDates = this.state.upcomingDates;
            let dateIndex = upcomingDates.findIndex(dateObj => dateObj.date === date);
            if (dateIndex !== -1)
                upcomingDates[dateIndex].allocated = ret.weekAllocated;

            this.setState({
                registeredHosts: registeredHosts,
                registeredHostsBackup: _.cloneDeep(registeredHosts),
                upcomingDates: upcomingDates,
                formRetVal: null
            });
        }
    }

    /**
     * Handles the approval of a meal.
     * @param {string} date - The date of the meal.
     * @param {string} type - The type of the meal.
     * @param {string} meal - The name of the meal.
     * @param {string} approvalType - The type of approval (e.g., "approve" or "reject").
     * @returns {Promise<any>} - The return value of the form submission.
     */
    async handleMealApproval(date, type, meal, approvalType)
    {
        //Show a pop-up confirming the approval
        let {isConfirmed} = await ReactPopUp.fire
        ({
            title: `Are you sure you want to ${approvalType} ${date} - ${type} ${meal}?`,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            buttonsStyling: false,
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS,
        });

        if (!isConfirmed)
            return;

        let data =
        {
            date: date,
            type: type,
            meal: meal,
            password: this.state.passwordInput,
        };

        await SendFormToServer(data, this, `/maintainer${approvalType}meal`, `Meal was ${approvalType}d successfully!`,
                               this.state.locationDetails, null);
                               
        //Loop while the formRetVal is null and at most for 30 seconds
        while (this.state.formRetVal == null)
        {
            let time = new Date().getTime();
            await new Promise(resolve => setTimeout(resolve, 100));
            if (new Date().getTime() - time > 30000)
                break;
        }
    
        return this.state.formRetVal;
    }

    /**
     * Approves a meal and updates the internal state accordingly.
     * @param {string} date - The date of the meal.
     * @param {string} type - The type of the meal.
     * @param {string} meal - The meal to be approved.
     */
    async approveMeal(date, type, meal)
    {
        let ret = await this.handleMealApproval(date, type, meal, "approve");
        if (ret && Object.keys(ret).length > 0)
            this.setState({upcomingDates: ret.dates, formRetVal: null});
    }

    /**
     * Unapproves a meal and updates the internal state accordingly.
     * @param {string} date - The date of the meal.
     * @param {string} type - The type of the meal.
     * @param {string} meal - The meal to unapprove.
     */
    async unapproveMeal(date, type, meal)
    {
        let ret = await this.handleMealApproval(date, type, meal, "unapprove");
        if (ret && Object.keys(ret).length > 0)
            this.setState({upcomingDates: ret.dates, formRetVal: null});
    }

    /**
     * Displays an error pop-up.
     * @param {*} errorSymbol - The error symbol for the message to be shown on the pop-up.
     */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        ErrorPopUp(text);
    }

    /**
     * Renders the button to quickly reload the dashboard without signing in again.
     * @param {boolean} invisible - Whether the button should be invisible.
     * @returns {JSX.Element} - The reload button.
     */
    printReloadButton(invisible)
    {
        return (
            <Button className={"maintainer-reload-button " + (invisible ? "invisible-button" : "")}
                    onClick={
                        !invisible ?
                            this.setState.bind(this, {loaded: false}, () => { //First set to unloaded again
                                this.loadData(null);
                            })
                        :
                            null
                    } >
                <MdRefresh size={20} />
            </Button>
        );
    }

    /**
     * Renders the location details section of the MaintainerView component.
     * @returns {JSX.Element} An accordian with the ability to view and edit location details.
     */
    printLocationDetails()
    {
        //Still a WIP
        //TODO: Make custom component
        let locationDetails = this.state.locationDetails;
        let community = locationDetails.community;

        //Get keys to display
        const readOnlyKeys = ["city", "country", "maintainerName", "maintainerContact"];
        const editableKeys = ["guestType"];
        let keyNames = Object.keys(locationDetails);
        keyNames = keyNames.map(key => [key, key.split(/(?=[A-Z])/).join(" ")]); //Split on capital letters
        keyNames = Object.fromEntries(keyNames.map(key => [key[0], key[1]])); //Turn into objects

        //Get the features to display
        let featuresKeys = Object.keys(locationDetails.features);
        featuresKeys = featuresKeys.map(key => [key, key.split(/(?=[A-Z])/).join(" ")]); //Split on capital letters
        featuresKeys = Object.fromEntries(featuresKeys.map(key => [key[0], key[1]])); //Turn into objects
        featuresKeys = Object.fromEntries(Object.entries(featuresKeys).sort(
            (a, b) => typeof(locationDetails.features[a[0]]) === "boolean" ? -1 : 1) //Sort the ones with bools to the top
        );

        return (
            <>
                <Accordion.Header>{community}{(community.toLowerCase().endsWith("s")) ? "'" : "'s"} Details</Accordion.Header>
                <Accordion.Body className="maintainer-main-accordian-body">
                    <Form className="maintainer-list-content m-auto">
                        {/* Read-only Fields */}
                        {
                            readOnlyKeys.map((key, i) =>
                            {
                                return (
                                    <Form.Group key={i} className="mb-3 d-flex justify-content-between">
                                        <Form.Label className="mt-auto mb-auto mr-3">{Capitalize(keyNames[key])}</Form.Label>
                                        <Form.Control className="w-50" type="text" readOnly disabled value={locationDetails[key]} />
                                    </Form.Group>
                                );
                            })
                        }

                        {/* Editable Fields */}
                        {
                            editableKeys.map((key, i) =>
                            {
                                return (
                                    <Form.Group key={i} className="mb-3 d-flex justify-content-between">
                                        <Form.Label className="mt-auto mb-auto mr-3">{Capitalize(keyNames[key])}</Form.Label>
                                        <Form.Control className="w-50" type="text" value={locationDetails[key]}
                                                      onChange={(e) => {
                                                          locationDetails[key] = e.target.value;
                                                          this.setState({locationDetails: locationDetails});
                                                      }} />
                                    </Form.Group>
                                );
                            })
                        }

                        {/* Features */}
                        <Form.Label>Features</Form.Label>
                        <div style={{paddingLeft: "10%", paddingRight: "10%", paddingTop: "2%", border: "1px solid black", borderRadius: "5px"}}>
                        {
                            Object.keys(featuresKeys).map((key, i) =>
                            {
                                let isBool = typeof(locationDetails.features[key]) === "boolean";
                                let isNumber = typeof(locationDetails.features[key]) === "number";
                                return (
                                    <Form.Group key={i} className={"mb-3 d-flex" + ((!isBool) ? " justify-content-between" : "")}>
                                    {
                                        isBool ? //If it's a boolean, show a checkbox
                                            <Form.Check type="checkbox" label={featuresKeys[key]} checked={locationDetails.features[key]}
                                                        onChange={(e) => {
                                                            locationDetails.features[key] = e.target.checked;
                                                            this.setState({locationDetails: locationDetails});
                                                        }} />
                                        : isNumber ? //If it's a number, show a number input
                                        //Note: Instead of mt-2, use margin auto
                                        <>
                                            <Form.Label className="mt-auto mb-auto mr-3 fw-normal">{featuresKeys[key]}</Form.Label>
                                            <Form.Control className="w-50" type="number" value={locationDetails.features[key]}
                                                            onChange={(e) => {
                                                                locationDetails.features[key] = parseInt(e.target.value);
                                                                this.setState({locationDetails: locationDetails});
                                                            }} />
                                        </>
                                        : //Otherwise, show a text field
                                        //Note: Instead of mt-2, use margin auto
                                        <>
                                            <Form.Label className="mt-auto mb-auto mr-3 fw-normal">{featuresKeys[key]}</Form.Label>
                                            <Form.Control className="w-50" type="text" value={locationDetails.features[key]}
                                                          onChange={(e) => {
                                                              locationDetails.features[key] = e.target.value;
                                                              this.setState({locationDetails: locationDetails});
                                                          }} />
                                        </>
                                    }
                                    </Form.Group>
                                );
                            })
                        }
                        </div>
                    </Form>
                </Accordion.Body>
            </>
        );
    }

    /**
     * Renders the "Add Dates" section of the MaintainerView component.
     * @returns {JSX.Element} An accordian with the ability to add dates to the database.
     */
    printAddDatesAccordion()
    {
        //TODO: Redesign for mobile
        return (
            <>
                <Accordion.Header>Add Dates</Accordion.Header>
                <Accordion.Body className="maintainer-date-addition maintainer-main-accordian-body">
                    {
                        this.state.chosenDatesToAdd.map((_, i) =>
                        {
                            return (
                                <div className="maintainer-date-input-container" key={i}>
                                    {/* Date picker */}
                                    <DatePicker
                                        className="react-datepicker"
                                        dateFormat={"MMMM d, yyyy" /* Show readable date */}
                                        selected={this.state.chosenDatesToAdd[i]}
                                        renderCustomHeader={RenderDatePickerHeader}
                                        minDate={new Date()}
                                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                        showDisabledMonthNavigation
                                        onChange={(date) =>
                                        {
                                            //Update state
                                            let chosenDatesToAdd = this.state.chosenDatesToAdd;
                                            let chosenMealsToAdd = this.state.chosenMealsToAdd;
                                            let chosenHolyDaysToAdd = this.state.chosenHolyDaysToAdd;
                                            let useFallbackHostsToAdd = this.state.useFallbackHostsToAdd;
                                            chosenDatesToAdd[i] = date;

                                            if (i + 1 >= this.state.chosenDatesToAdd.length)
                                            {
                                                chosenDatesToAdd.push("");
                                                chosenMealsToAdd.push("lunch");
                                                chosenHolyDaysToAdd.push("Shabbat");
                                                useFallbackHostsToAdd.push(true);
                                            }

                                            this.setState({chosenDatesToAdd: chosenDatesToAdd,
                                                        chosenMealsToAdd: chosenMealsToAdd,
                                                        chosenHolyDaysToAdd: chosenHolyDaysToAdd,
                                                        useFallbackHostsToAdd: useFallbackHostsToAdd});    
                                        }}/>

                                    {/* Dropdown for holy day type */}
                                    <Form.Select className="maintainer-holy-day-dropdown"
                                        onChange={(e) =>
                                        {
                                            let chosenHolyDaysToAdd = this.state.chosenHolyDaysToAdd;
                                            chosenHolyDaysToAdd[i] = e.target.value;
                                            this.setState({chosenHolyDaysToAdd: chosenHolyDaysToAdd});
                                        }}>
                                        {
                                            Object.keys(HOLY_DAY_TYPES).map((holyDay, i) =>
                                            {
                                                return (
                                                    <option key={i} value={holyDay}>{HolyDayToString(holyDay)}</option>
                                                );
                                            })
                                        }
                                    </Form.Select>

                                    {/* Dropdown for lunch or dinner */}
                                    <Form.Select className="maintainer-lunch-dinner-dropdown"
                                        onChange={(e) =>
                                        {
                                            let chosenMealsToAdd = this.state.chosenMealsToAdd;
                                            chosenMealsToAdd[i] = e.target.value;
                                            this.setState({chosenMealsToAdd: chosenMealsToAdd});
                                        }}>
                                        <option key="lunch" value="lunch">Lunch</option>
                                        <option key="dinner" value="dinner">Dinner</option>
                                    </Form.Select>

                                    {/* Checkbox for using fallback host */}
                                    <Form.Check className="maintainer-fallback-host-checkbox"
                                        type="checkbox"
                                        label="Use Fallback Host"
                                        checked={this.state.useFallbackHostsToAdd[i]}
                                        onChange={(e) =>
                                        {
                                            let useFallbackHostsToAdd = this.state.useFallbackHostsToAdd;
                                            useFallbackHostsToAdd[i] = e.target.checked;
                                            this.setState({useFallbackHostsToAdd: useFallbackHostsToAdd});
                                        }} />
                                </div>
                            )
                        })
                    }
                    <Button className="maintainer-date-addition-button" onClick={this.addDates.bind(this)}>
                        Add Dates
                    </Button>
                </Accordion.Body>
            </>
        );
    }

    /**
     * Prints the list of students in the database.
     * @param {Boolean} graduated - Whether to print the list of graduated students or regular students.
     * @returns {JSX.Element} - An accordian with the list of students.
     */
    printStudentsList(approved, ungraduated)
    {
        let studentsList = [];
        let graduated = !ungraduated;
        let unapproved = !approved;
        let useApprovals = GuestsNeedApproval(this.state.locationDetails);
        let useGraduation = ungraduated != null && this.useGraduation();

        //Filter out students based on the approval and graduation status
        for (let student of Object.values(this.state.students))
        {
            //Filter the students by whether they are approved or not
            if (useApprovals && student.approved !== approved)
                continue;

            //Filter the students by whether they have graduated or not
            if (useGraduation && student.graduated !== graduated)
                continue;

            studentsList.push(student);
        }
    
        //Exit early if there are no students
        if (studentsList.length === 0)
            return "None!";

        //Sort the students by last name
        studentsList.sort((a, b) => a.lastName.localeCompare(b.lastName));

        //Print the list of students
        return (
            <Accordion>
            {
                studentsList.map((student, i) =>
                {
                    let approvalButton, graduationButton, weeklySignUpButton;
                    let name = <GenderedName name={`${student.firstName} ${student.lastName}`} gender={student.gender} />;
                    let nameWithPopUp = <GenderedName name={`${student.firstName} ${student.lastName}`} gender={student.gender} 
                                                      onClick={this.displayStudentDetailsPopUp.bind(this, student)} />;
                    let email = student.email;

                    //Create the approval/unapproval button
                    if (useApprovals && (unapproved || ungraduated)) //Don't show unapproval button for graduated students
                    {
                        let iconClassName = (this.isStudentToggledForApproval(email, unapproved)) ? (approved ? "btn-danger-always-hovered" : "btn-success-always-hovered") : "";
                        approvalButton =
                            <Button variant={approved ? "danger" : "success"} className={"maintainer-select-for-graduation-button " + iconClassName}
                                onClick={this.toggleStudentApproval.bind(this, email, nameWithPopUp, unapproved)}>
                                {
                                    approved ? //Currently approved list so show icon for unapproval
                                        <MdCancel size={20} />
                                    :
                                        <MdCheck size={20} />
                                }
                                
                            </Button>
                    }

                    //Create the graduate/ungraduate button
                    if (useGraduation)
                    {
                        let iconClassName = (this.isStudentToggledForGraduation(email, graduated)) ? "btn-success-always-hovered" : "";
                        graduationButton =
                            <Button variant="success" className={"maintainer-select-for-graduation-button " + iconClassName}
                                onClick={this.toggleStudentGraduation.bind(this, email, nameWithPopUp, graduated)}>
                                <BiSolidGraduation size={20} />
                            </Button>
                    }

                    //Create the weekly sign-up button
                    if ((approved || !useApprovals) && ungraduated)
                    {
                        weeklySignUpButton =
                            <WeeklyStudentButton
                                key={email}
                                customPopUpButtonClassesContinue={CUSTOM_POPUP_BUTTON_CLASSES_CONTINUE}
                                customPopUpButtonClassesSuccess={CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS}
                                maintainerPassword={this.state.passwordInput}
                                student={student}
                                students={this.state.students}
                                dateList={this.state.upcomingDates}
                                allergyNameList={this.state.allergyNameList}
                                foodPreferenceNameList={this.state.foodPreferenceNameList}
                                locationDetails={this.state.locationDetails}
                                addParentWeeklySignUp={this.addWeeklySignUp.bind(this)} />;
                    }

                    //Print the details accordian for the student
                    return (
                        <div className="maintainer-accordian-with-button-container">
                            <Accordion.Item key={i} eventKey={`${i}`}>
                                <div className="d-flex">
                                    {approvalButton}
                                    {graduationButton}
                                    <Accordion.Header className="maintainer-accordian-header-with-button"> {name}</Accordion.Header>
                                    {weeklySignUpButton}
                                </div>
                                <Accordion.Body>
                                    {this.printStudentDetails(student)}
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    );
                })
            }
            </Accordion>
        );
    }

    /**
     * Prints the details of a student.
     * @param {Object} student - The student to print the details of.
     * @returns {JSX.Element} - The details of the student.
     */
    printStudentDetails(student)
    {
        let email = student.email;
        let gender = <GenderedName name={Capitalize(student.gender)} gender={student.gender} />;
        let birthday = student.birthday;
        let age = (birthday !== "") ? CalculateAge(birthday) : "";
        let weeksPresent = student.weeks;

        return (
        <>
            <p><span className={DETAILS_TITLE_CLASS}>Email:</span> {email}</p>
            <p><span className={DETAILS_TITLE_CLASS}>Gender:</span> {gender}</p>
            {
                birthday &&
                    <p><span className={DETAILS_TITLE_CLASS}>Birthday:</span> {birthday} ({age})</p>
            }
            <p><span className={DETAILS_TITLE_CLASS}>Phone:</span> {WhatsAppLink(student.phone)}</p>
            {this.printStudentDecisionDetails(student, true)}
            {
                student.notes.length !== 0 &&
                    <p><span className={DETAILS_TITLE_CLASS}>Notes:</span> {student.notes}</p>
            }
            {
                weeksPresent.length !== 0 &&
                <div>
                    <p><span className={DETAILS_TITLE_CLASS}>Weeks Present ({weeksPresent.length}):</span></p>
                    <ul>
                    {
                        weeksPresent.map((week, i) =>
                        {
                            let legacyData = !_.isObject(week);
                            let weekDate = (legacyData) ? week : week.date;
                            let prevWeek = this.state.previousDates.find(prevDate => prevDate.date === weekDate);
                            if (!prevWeek) //Not found because still in the upcoming dates
                                prevWeek = this.state.upcomingDates.find(upcomingDate => upcomingDate.date === weekDate);

                            let host, meal;
                            let type = prevWeek.type;
                            if (legacyData)
                            {
                                //Handle legacy data where only the date was stored
                                if (week in this.state.previousRegisteredHosts && Object.keys(this.state.previousRegisteredHosts[weekDate]).length === 1)
                                {
                                    //Find the one meal and host for the week
                                    meal = Object.keys(this.state.previousRegisteredHosts[weekDate])[0];
                                    let regHost = Object.values(this.state.previousRegisteredHosts[weekDate][meal]).find(host => host.hosting.includes(email));
                                    if (regHost)
                                        host = this.getHost(regHost.email);
                                }
                            }
                            else
                            {
                                meal = week.meal;
                                if ("host" in week)
                                    host = this.getHost(week.host);

                                if (!host //Either the host is not in the student's previous week data or the host wasn't found
                                && weekDate in this.state.previousRegisteredHosts
                                && week.meal in this.state.previousRegisteredHosts[weekDate])
                                {
                                    host = Object.values(this.state.previousRegisteredHosts[weekDate][week.meal]).find(host => host.hosting.includes(email));
                                    if (host)
                                        host = this.getHost(host.email);
                                    else
                                        console.log(`Warning: Host ${host.email} not found for student ${email} in ${weekDate} - ${week.meal}`);
                                }
                            }

                            if (host)
                                host = <GenderedName name={host.name} gender={host.gender}
                                                     onClick={this.displayHostDetailsPopUp.bind(this, host)} />;

                            return (
                                <li key={i}><small>{weekDate} {type ? type : ""} {meal ? Capitalize(meal) : ""} {host ? <>({host})</> : ""}</small></li>
                            );
                        })
                    }
                    </ul>
                </div>
            }
        </>
        );
    }

    /**
     * Prints the details of a student useful for making an informed decision for their placement.
     * @param {Object} student - The student to print the details of.
     * @param {Boolean} showPetFearIfEmpty - Whether to show the animal fears line if there are none.
     * @returns {JSX.Element} - The details of the student.
     */
    printStudentDecisionDetails(student, showPetFearIfEmpty)
    {
        let address = (student.address) ? student.address.split("|")[0] : "";
        let maxTravelTime = student.maxTravelTime;
        let allergies = student.allergies.join(", ").split(" ").map(allergy => Capitalize(allergy)).join(" ");
        let foodPreferences = student.foodPreferences.join(", ").split(" ").map(foodPref => Capitalize(foodPref)).join(" ");
        let animalFear = student.animalFear.join(", ").split(" ").map(animal => Capitalize(animal)).join(" ");
        let politicalLeaning = Capitalize(student.poliLeaning);
        //let hatedPeople = this.formatRequestsList(student.peopleHated... //TODO: Implement hated people
        let relationship = (student.dating) ? this.getStudent(student.dating.email) : null;
        let relationshipClickFunc = (relationship) ? this.displayStudentDetailsPopUp.bind(this, relationship) : null;

        return (
        <>
            {
                address &&
                    <p><span className={DETAILS_TITLE_CLASS}>Address:</span> {GoogleMapsLink(address, address)}</p>
            }
            {
                maxTravelTime == null || !StudentsHostsHaveProximityChecking(this.state.locationDetails) ?
                    ""
                : maxTravelTime === 0 ?
                    <p><span className={DETAILS_TITLE_CLASS}>Travel Time:</span> Unlimited</p>
                :
                    <p><span className={DETAILS_TITLE_CLASS}>Max Travel Time:</span> {student.maxTravelTime} min</p>
            }
            <p><span className={DETAILS_TITLE_CLASS}>Allergies:</span> {student.allergies.length === 0 ? "None" : allergies}</p>
            <p><span className={DETAILS_TITLE_CLASS}>Food Preferences:</span> {student.foodPreferences.length === 0 ? "None" : foodPreferences}</p>
            {
                (showPetFearIfEmpty || student.animalFear.length !== 0) &&
                    <p><span className={DETAILS_TITLE_CLASS}>Afraid of Pet:</span> {student.animalFear.length === 0 ? "None" : animalFear}</p>
            }
            {
                COVID_EXISTS &&
                <>
                    <p><span className={DETAILS_TITLE_CLASS}>Vaccinated:</span> {student.vaccinated}</p>
                    <p><span className={DETAILS_TITLE_CLASS}>Unvaccinated host comfort:</span> {student.unvaccinatedComfort}</p>
                </>
            }
            {
                StudentsHostsHavePoliticalLeaning(this.state.locationDetails) &&
                    <p><span className={DETAILS_TITLE_CLASS}>Political Leaning:</span> {politicalLeaning}</p>
            }
            {
                student.dating &&
                    <p><span className={DETAILS_TITLE_CLASS}>Relationship:</span> <GenderedName name={student.dating.name} gender={student.dating.gender}
                                                                                                onClick={relationshipClickFunc} /></p>
            }
            {/*
                hatedPeople &&
                    <p><span className={DETAILS_TITLE_CLASS}>Dislikes:</span>{hatedPeople}</p>
            */}
        </>
        );
    }

    /**
     * Prints the list of all the hosts in the database.
     * @returns {JSX.Element} - An accordian with all the hosts in the database.
     */
    printHostsList(approved)
    {
        let hostsList = [];
        let unapproved = !approved;
        let useApprovals = HostsNeedApproval(this.state.locationDetails);

        //Filter the hosts by whether they are approved or not
        for (let host of Object.values(this.state.hosts))
        {
            if (useApprovals && host.approved !== approved)
                continue;

            hostsList.push(host);
        }

        //Exit early if there are no hosts
        if (hostsList.length === 0)
            return "None!";

        //Sort the hosts by name
        hostsList.sort((a, b) => a.name.localeCompare(b.name));

        //Print the list of hosts
        return (
            <Accordion>
            {
                hostsList.map((host, i) =>
                {
                    let approvalButton;
                    let name = <GenderedName name={host.name} gender={host.gender} />;
                    let nameWithPopUp = <GenderedName name={host.name} gender={host.gender}
                                                      onClick={this.displayHostDetailsPopUp.bind(this, host)} />;
                    let email = host.email;
                    let weeksHosted = host.weeks;
                    let lastHostedDate = (weeksHosted.length === 0) ? "" : !_.isObject(weeksHosted[weeksHosted.length - 1]) ? weeksHosted[weeksHosted.length - 1] : weeksHosted[weeksHosted.length - 1].date;
                    let hostedDaysAgo = (lastHostedDate === "") ? -1 : CalculateDaysSince(lastHostedDate);

                    if (useApprovals)
                    {
                        let iconClassName = (this.isHostToggledForApproval(email, unapproved)) ? (approved ? "btn-danger-always-hovered" : "btn-success-always-hovered") : "";
                        approvalButton =
                            <Button variant={approved ? "danger" : "success"} className={"maintainer-select-for-graduation-button " + iconClassName}
                                onClick={this.toggleHostApproval.bind(this, email, nameWithPopUp, unapproved)}>
                                {
                                    approved ? //Currently approved list so show icon for unapproval
                                        <MdCancel size={20} />
                                    :
                                        <MdCheck size={20} />
                                }
                            </Button>
                    }

                    return (
                        <div className="maintainer-accordian-with-button-container">
                            <Accordion.Item key={i} eventKey={`${i}`}>
                                <div className="d-flex">
                                    {approvalButton}
                                    <Accordion.Header className="maintainer-accordian-header-with-button">
                                    {
                                        hostedDaysAgo === -1 ?
                                            <p>{name}<br/><small className="fw-bold">Never Hosted</small></p>
                                        :
                                            <p>{name}<br/><small className="fw-bold">Last Hosted {hostedDaysAgo} Days Ago</small></p>
                                    }
                                    </Accordion.Header>
                                </div>
                                <Accordion.Body>
                                    {this.printHostDetails(host)}
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    );
                })
            }
            </Accordion>
        );
    }

    /**
     * Prints the details of a host.
     * @param {Object} host - The host to print the details of.
     * @returns {JSX.Element} - The details of the host.
     */
    printHostDetails(host)
    {
        let gender = (host.gender && host.gender !== "none") ? Capitalize(host.gender) : "";
        let unallocatedEmail = (HostsGetUnallocatedEmails(this.state.locationDetails)) ? (host.unallocatedEmail ? "Yes" : "No") : "";
        let weeksHosted = host.weeks;

        //Filter out the weeks that are not for the current community
        weeksHosted = weeksHosted.filter(week => {
            if (!_.isObject(week) || !("community" in week)) //Legacy data
                return true;
            return week.community === this.state.locationDetails.community;
        });

        return (
        <>
            <p><span className={DETAILS_TITLE_CLASS}>Email:</span> {host.email}</p>
            {
                gender &&
                    <p><span className={DETAILS_TITLE_CLASS}>Gender:</span> <GenderedName name={gender} gender={gender} /></p>
            }
            <p><span className={DETAILS_TITLE_CLASS}>Phone:</span> {WhatsAppLink(host.phone)}</p>
            {
                this.printHostDecisionDetails(host, true)
            }
            {
                host.eatTime &&
                    <p><span className={DETAILS_TITLE_CLASS}>Lunch Time:</span> {host.eatTime}</p>
            }
            {
                unallocatedEmail &&
                    <p><span className={DETAILS_TITLE_CLASS}>Gets Unallocated Emails:</span> {unallocatedEmail}</p>
            }
            {
                host.notes.length !== 0 &&
                    <p><span className={DETAILS_TITLE_CLASS}>Notes:</span> {host.notes}</p>
            }
            {
                weeksHosted.length !== 0 &&
                    <div>
                        <p><span className={DETAILS_TITLE_CLASS}>Weeks Hosted ({weeksHosted.length}):</span></p>
                        <ul>
                        {
                            weeksHosted.map((week, i) =>
                            {
                                let legacyData = !_.isObject(week);
                                let date = (legacyData) ? week : week.date;
                                let prevWeek = this.state.previousDates.find(dateObj => dateObj.date === date);
                                if (!prevWeek) //Not found because still in the upcoming dates
                                    prevWeek = this.state.upcomingDates.find(dateObj => dateObj.date === date);

                                if (!prevWeek) //Still not found so just print the date
                                    return <li key={i}><small>{date}</small></li>;

                                let meal;
                                let type = prevWeek.type;
                                if (legacyData) //Handle legacy data where only the date was stored
                                {
                                    meal = Object.keys(this.state.previousRegisteredHosts[week])[0];
                                    return <li key={i}><small>{week} {type ? type : ""} {meal ? Capitalize(meal) : ""}</small></li>;
                                }
                                else //New data format
                                {
                                    meal = week.meal;
                                    return <li key={i}><small>{week.date} {type} {Capitalize(meal)}</small></li>;
                                }
                            })
                        }
                        </ul>
                    </div>
            }
        </>
        );
    }

    /**
     * Prints the details of a host useful for making an informed decision for a student's placement.
     * @param {Object} host - The host to print the details of.
     * @param {Boolean} showPetsIfEmpty - Whether to show the pets line if there are none.
     * @returns {JSX.Element} - The details of the student.
     */
    printHostDecisionDetails(host, showPetsIfEmpty)
    {
        let address = host.address.endsWith("|") ? host.address.slice(0, -1) : host.address.replace("|", ", ");
        let addressLink = host.address.split("|")[0];
        let pets = host.pets.join(", ").split(" ").map(pet => Capitalize(pet)).join(" ");
        let goodForAllergy = host.goodForAllergy.join(", ").split(" ").map(allergy => Capitalize(allergy)).join(" ");
        let politicalLeaning = Capitalize(host.poliLeaning);
        let allergyPetsAtBeginning = !showPetsIfEmpty; //Place the goodForAllergy field at the beginning so it's dirrectly beneath the badForAllergy field
        let petSection = (showPetsIfEmpty || pets.length > 0) &&
            <p><span className={DETAILS_TITLE_CLASS}>Pets:</span> {pets.length === 0 ? "None" : pets}</p>
        let goodForAllergySection = goodForAllergy.length > 0 && //Don't show if empty ever
            <p><span className={DETAILS_TITLE_CLASS}>Good For:</span> {goodForAllergy}</p>

        return (
        <>
            {allergyPetsAtBeginning && goodForAllergySection}
            {allergyPetsAtBeginning && petSection}
            <p><span className={DETAILS_TITLE_CLASS}>Address:</span> {GoogleMapsLink(address, addressLink)}</p>
            {
                COVID_EXISTS &&
                <>
                    <p><span className={DETAILS_TITLE_CLASS}>Vaccinated:</span> {host.vaccinated}</p>
                    <p><span className={DETAILS_TITLE_CLASS}>Unvaccinated student comfort:</span> {host.unvaccinatedComfort}</p>
                </>
            }
            {
                StudentsHostsHavePoliticalLeaning(this.state.locationDetails)
                && (showPetsIfEmpty || (politicalLeaning.length !== 0 && politicalLeaning.toLowerCase() !== "none")) &&
                    <p><span className={DETAILS_TITLE_CLASS}>Political Leaning:</span> {politicalLeaning}</p>
            }
            {!allergyPetsAtBeginning && petSection}
            {!allergyPetsAtBeginning && goodForAllergySection}
        </>
        );
    }

    /**
     * Prints the list of all the students in the database who are approved and have not graduated yet.
     * @returns {JSX.Element} - An accordian with the students list.
     */
    printApprovedStudentsList()
    {
        return this.printStudentsList(true, true);
    }

    /**
     * Prints the list of all the students in the database who are not approved.
     * @returns {JSX.Element} - An accordian with the students list.
     */
    printUnapprovedStudentsList()
    {
        return this.printStudentsList(false, null); //Graduated val doesn't matter
    }

    /**
     * Prints the list of all the students who previously signed up and were approved but have since graduated.
     * @returns {JSX.Element} - An accordian with the students list.
     */
    printGraduatedStudentsList()
    {
        return this.printStudentsList(true, false);
    }

    /**
     * Prints the list of all the hosts in the database who are approved.
     * @returns {JSX.Element} - An accordian with the hosts list.
     */
    printApprovedHostsList()
    {
        return this.printHostsList(true);
    }

    /**
     * Prints the list of unapproved hosts.
     * @returns {JSX.Element} - An accordian with the hosts list.
     */
    printUnapprovedHostsList()
    {
        return this.printHostsList(false);
    }

    /**
     * Renders a section for setting the graduation status of a list of people.
     * @param {string} stateKey - The key of the state object containing the list of people.
     * @param {string} personType - The type of person (e.g., student, host).
     * @param {string} approvalType - The type of approval (e.g., graduated, approved).
     * @param {function} buttonFunc - The function to be called when the button is clicked.
     * @returns {JSX.Element} - The rendered section.
     */
    printGraduationToggle(stateKey, personType, approvalType, buttonFunc)
    {
        let nameList = Object.values(this.state[stateKey]);
        if (nameList.length === 0)
            return "";

        return (
            <div className="maintainer-list-content">
                <h3 className="text-center">Set {Capitalize(personType)}s {Capitalize(approvalType)}</h3>
                <ul>
                {
                    nameList.map((name, i) =>
                    {
                        return <li key={i}>{name}</li>;
                    })
                }
                </ul>
                <Button className="maintainer-set-graduated-button" onClick={buttonFunc}>
                    Set {Capitalize(approvalType)}
                </Button>
            </div>
        ); 
    }

    /**
     * Prints the lists of students currently toggled to be set as graduated or ungraduated.
     * @param {String} listKey - The key of the main accordian list to print.
     * @returns {JSX.Element} - The list of students currently toggled to be set as graduated or ungraduated and a button to confirm the change.
     */
    tryPrintGraduationToggle(listKey)
    {
        const className = "maintainer-list-section";
        let guestType = this.state.locationDetails.guestType;

        if (listKey.startsWith("Approved") || listKey.startsWith("Current"))
        {
            return (
                <div className={className}>
                    {/* Toggle to set students as unapproved */}
                    {this.printGraduationToggle("setStudentsUnapproved", guestType, "unapproved", this.setStudentsUnapproved.bind(this))}

                    {/* Toggle to set students as graduated */}
                    {this.printGraduationToggle("setStudentsGraduated", guestType, "graduated", this.setStudentsGraduated.bind(this))}

                    {/* Toggle to set hosts as unapproved */}
                    {this.printGraduationToggle("setHostsUnapproved", "host", "unapproved", this.setHostsUnapproved.bind(this))}
                </div>
            );
        }
        else if (listKey.startsWith("Unapproved"))
        {
            return (
                <div className={className}>
                    {/* Toggle to approve students */}
                    {this.printGraduationToggle("setStudentsApproved", guestType, "approved", this.setStudentsApproved.bind(this))}

                    {/* Toggle to approve hosts */}
                    {this.printGraduationToggle("setHostsApproved", "host", "approved", this.setHostsApproved.bind(this))}
                </div>
            );
        }
        else if (listKey.startsWith("Graduated"))
        {
            //Toggle to set students as ungraduated again
            return (
                <div className={className}>
                    {this.printGraduationToggle("setStudentsUngraduated", guestType, "ungraduated", this.setStudentsUngraduated.bind(this))}
                </div>
            );
        }

        return "";  
    }

    /**
     * Prints the list of all the students registered for the week.
     * @returns {JSX.Element} - An accordian with all the students registered for the week.
     */
    printRegisteredStudentsList(date, type, meal)
    {
        let registeredStudents = this.state.registeredStudents[date][meal];
        let registeredStudentList = Object.values(registeredStudents);
        if (registeredStudentList.length === 0)
            return "None!";

        let registeredHosts = this.state.registeredHosts[date][meal];

        return (
            <Accordion>
            {
                registeredStudentList.map((regStudent, i) =>
                {
                    let guestTotal = regStudent.maleGuests + regStudent.femaleGuests;
                    let ageRange = regStudent.guestMinAge === regStudent.guestMaxAge ? regStudent.guestMinAge : `${regStudent.guestMinAge} - ${regStudent.guestMaxAge}`;
                    let allocatedHost = this.allocatedToHost(regStudent, date, meal);
                    let student = this.getStudent(regStudent.email);
                    let age = (student && student.birthday) ? <small>{` (${CalculateAge(student.birthday)})`}</small> : "";
                    let studentName = <span><GenderedName name={regStudent.name} gender={(student) ? student.gender : ""} />{age}</span>

                    return (
                        <div className="maintainer-accordian-with-button-container">
                            <Accordion.Item key={i} eventKey={`${i}`}>
                                <div className="d-flex">
                                    {/* Cancel sign-up button */}
                                    <Button variant="danger" className="maintainer-cancel-sign-up-button"
                                            onClick={this.cancelStudentSignUp.bind(this, regStudent, date, type, meal)}>
                                        <BiSolidTrash size={20} />
                                    </Button>
                                
                                    {/* Details accordian */}
                                    <Accordion.Header className="maintainer-accordian-header-with-button">
                                    {
                                        guestTotal > 0 ?
                                            <p>
                                                {studentName}
                                                <br/>
                                                <small className="fw-bold">
                                                    {guestTotal} Guest{guestTotal !== 1 ? "s" : ""}
                                                    {
                                                        ageRange !== 0 &&
                                                            <> (Aged {ageRange})</>
                                                    }
                                                </small>
                                            </p>
                                        :
                                            studentName
                                    }
                                    </Accordion.Header>

                                    {/* Allocate student to host button */}
                                    <MoveStudentButton
                                        key={regStudent.email + (allocatedHost ? allocatedHost.email : "")}
                                        student={regStudent} 
                                        registeredHosts={registeredHosts}
                                        date={date}
                                        meal={meal}
                                        allocatedHost={allocatedHost}
                                        customPopUpButtonClassesSuccess={CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS}
                                        locationDetails={this.state.locationDetails}
                                        allocateToHost={this.moveStudentToHost.bind(this)}
                                        removeFromHost={this.removeStudentFromHost.bind(this)} />
                                </div>

                                <Accordion.Body>
                                    {this.printRegisteredStudentDetails(regStudent, date, meal, registeredStudents, registeredHosts)}
                                    {
                                        //Add certain details from main student field
                                        student &&
                                            <div className="border-top pt-3">
                                                {this.printStudentDecisionDetails(student, false)}
                                            </div>
                                    }
                                    {
                                        student &&
                                            <div className="d-flex justify-content-center mt-3">
                                                <Button onClick={this.displayStudentDetailsPopUp.bind(this, student)}>
                                                    More Details
                                                </Button>
                                            </div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    );
                })
            }
            </Accordion>
        )
    }

    /**
     * Prints the details of a student's weekly sign up.
     * @param {Object} regStudent - The student to print the details of.
     * @param {string} date - The date for which the student is signed up.
     * @param {string} meal - The meal for which the student is signed up.
     * @param {Object} registeredStudents - The map of all registered students for the week.
     * @param {Object} registeredHosts - The map of all registered hosts for the week.
     * @returns {JSX.Element} - The details of the student's weekly sign up.
     */
    printRegisteredStudentDetails(regStudent, date, meal, registeredStudents, registeredHosts)
    {
        //Get the host this student is allocated to
        let allocatedHost = this.allocatedToHost(regStudent, date, meal);
        let host = this.getHost(allocatedHost ? allocatedHost.email : null);

        //Get the list of students who requested this student
        let registeredStudentsList = Object.values(registeredStudents);
        let requestedBy = registeredStudentsList.filter(student => student.friends.includes(regStudent.email)) //This student is requested
                                                .map(student => student.email); //Convert to list of emails

        //Get the different friend lists
        let friendsAndRequestedBy = regStudent.friends.filter(email => requestedBy.includes(email));
        let justFriends = regStudent.friends.filter(email => !friendsAndRequestedBy.includes(email));
        let justRequestedBy = requestedBy.filter(email => !friendsAndRequestedBy.includes(email));

        //Format the friends lists
        let friendsAndRequestedByList = this.formatRequestsList(friendsAndRequestedBy, registeredStudents, registeredHosts);
        let friendsList = this.formatRequestsList(justFriends, registeredStudents, registeredHosts);
        let requestedByList = this.formatRequestsList(justRequestedBy, registeredStudents, registeredHosts);

        return (
        <>
            {
                allocatedHost &&
                    <p>
                        <span className={DETAILS_TITLE_CLASS}>Host:</span> <span className={"text-success " + (host ? "clickable" : "")}
                                                                                 onClick={this.displayHostDetailsPopUp.bind(this, host)}>
                                                                                {allocatedHost.name}
                                                                            </span>
                    </p>
            }
            <p><span className={DETAILS_TITLE_CLASS}><GenderedName name="Male" gender="male" /> Guests:</span> {regStudent.maleGuests}</p>
            <p><span className={DETAILS_TITLE_CLASS}><GenderedName name="Female" gender="female" /> Guests:</span> {regStudent.femaleGuests}</p>
            {
                (regStudent.maleGuests > 0 || regStudent.femaleGuests > 0)
                && HostsHaveAgeRequest(this.state.locationDetails)
                && (
                    regStudent.maleGuests + regStudent.femaleGuests === 1 ?
                        <p><span className={DETAILS_TITLE_CLASS}>Guest Age:</span> {regStudent.guestMinAge}</p>
                    :
                        <p><span className={DETAILS_TITLE_CLASS}>Guest Age Range:</span> {regStudent.guestMinAge} - {regStudent.guestMaxAge}</p>
                )
            }
            {
                (regStudent.maleGuests > 0 || regStudent.femaleGuests > 0) && regStudent.guestAllergies.length > 0 &&
                    <p><span className={DETAILS_TITLE_CLASS}>Guest Allergies:</span> {regStudent.guestAllergies.join(", ")}</p>
            }
            {
                (friendsAndRequestedByList.length > 0) &&
                    <p><span className={DETAILS_TITLE_CLASS}>Friends & Requested By:</span>{friendsAndRequestedByList}</p>
            }
            {
                (friendsList.length > 0) &&
                    <p><span className={DETAILS_TITLE_CLASS}>Friends:</span>{friendsList}</p>
            }
            {
                (requestedByList.length > 0) &&
                    <p><span className={DETAILS_TITLE_CLASS}>Requested By:</span>{requestedByList}</p>
            }
        </>
        );
    }

    /**
     * Prints the list of all the hosts signed-up to host for the upcoming week.
     * @param {string} date - The date for which to retrieve the list of registered hosts.
     * @param {string} type - The type of the meal for which to retrieve the list of registered hosts.
     * @returns {JSX.Element} - An accordian with all the hosts signed-up to host for the upcoming week.
     */
    printRegisteredHostsList(date, type, meal)
    {
        //Sort the hosts by name
        let registeredHosts = this.state.registeredHosts[date][meal];
        let registeredHostsList = Object.values(this.state.registeredHosts[date][meal]);
        if (registeredHostsList.length === 0)
            return "None!";

        //Render an accordion for each host
        return (
            <Accordion>
            {
                registeredHostsList.map((regHost, i) =>
                {
                    let currCapacity = this.caclulateHostCapacity(regHost, date, meal);
                    let requestsList = this.formatRequestsList(regHost.requests, this.state.registeredStudents[date][meal], registeredHosts);
                    let host = this.getHost(regHost.email);

                    return (
                        <div className="maintainer-accordian-with-button-container">
                            <Accordion.Item key={regHost.email + i} eventKey={`${i}`}>
                                <div className="d-flex">
                                    <Button variant="danger" className="maintainer-cancel-sign-up-button"
                                            onClick={this.cancelHostSignUp.bind(this, regHost, date, type, meal)}>
                                        <BiSolidTrash size={20} />
                                    </Button>
                                    <Accordion.Header className="maintainer-accordian-header-with-button">
                                        <p>
                                            <span className="maintainer-registered-host-name">
                                                <GenderedName name={regHost.name} gender={(host) ? host.gender : ""} />
                                            </span>
                                            <span className={currCapacity > regHost.capacity ? "text-danger" : ""}>
                                                {regHost.hosting.length === 0 ? `(0/${regHost.capacity})` : `(${currCapacity}/${regHost.capacity})`}
                                            </span>
                                        </p>
                                    </Accordion.Header>
                                </div>

                                <Accordion.Body>
                                {
                                    regHost.mealTime &&
                                        <p><span className={DETAILS_TITLE_CLASS}>Meal Time:</span> {regHost.mealTime}</p>
                                }
                                {
                                    requestsList.length > 0 &&
                                        <p><span className={DETAILS_TITLE_CLASS}>Wants:</span>{requestsList}</p>
                                }
                                {
                                    regHost.genderPref.length > 0 &&
                                        <p>
                                            <span className={DETAILS_TITLE_CLASS}>Prefers:</span> <GenderedName name={Capitalize(regHost.genderPref)} gender={regHost.genderPref} />
                                        </p>
                                }
                                {
                                    (regHost.minAge > 0 || regHost.maxAge > 0) &&
                                    (
                                        regHost.maxAge === 0 ?
                                            <p><span className={DETAILS_TITLE_CLASS}>Age Range:</span> {regHost.minAge} and Above</p>
                                        : (regHost.minAge === 0) ?
                                            <p><span className={DETAILS_TITLE_CLASS}>Age Range:</span> Up to {regHost.maxAge}</p>
                                        :
                                            <p><span className={DETAILS_TITLE_CLASS}>Age Range:</span> {regHost.minAge} - {regHost.maxAge}</p>
                                    )
                                }
                                {
                                    regHost.badForAllergy.length > 0 &&
                                        <p><span className={DETAILS_TITLE_CLASS}>Prefers None Of:</span> {regHost.badForAllergy.join(", ")}</p>
                                }
                                {
                                    //Add certain details from main host field
                                    host &&
                                        <div className="border-top pt-3 pb-3">
                                            {this.printHostDecisionDetails(host, false)}
                                        </div>
                                }
                                {
                                    this.printHostHostingList(regHost, date, meal)
                                }
                                {
                                    host &&
                                        <div className="d-flex justify-content-center mt-3">
                                            <Button onClick={this.displayHostDetailsPopUp.bind(this, host)}>
                                                More Details
                                            </Button>
                                        </div>
                                }
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Attach the allocation warnings to the bottom of the host accordian */}
                            <AllocationWarnings
                                key={regHost.email + parseInt(regHost.hosting.length)}
                                registeredHost={regHost}
                                students={this.state.students}
                                hosts={this.state.hosts}
                                registeredStudents={this.state.registeredStudents[date][meal]}
                                registeredHosts={registeredHosts} />
                        </div>
                    );
                })
            }
            {
                //Save Button
                this.registeredHostsHaveChanged(date, meal) &&
                    <div className="mt-3">
                        <div className="d-flex justify-content-center mt-3">
                            <Button variant="success" className="maintainer-save-registered-hosts-button"
                                    onClick={this.handleSaveMovedStudents.bind(this, date, meal)} >
                                Save Changes
                            </Button>
                        </div>
                    </div>
            }
            </Accordion>
        );
    }

    printHostHostingList(regHost, date, meal)
    {
        if (regHost.hosting.length === 0)
            return <div>No {Capitalize(this.state.locationDetails.guestType)}s Assigned Yet</div>;

        let host = this.getHost(regHost.email);
        let ret = regHost.hosting.map((studentEmail, j) =>
        {
            //Display the student's name along with their number of guests
            let student = this.getStudent(studentEmail);
            let regStudent = this.getRegisteredStudent(studentEmail, date, meal);
            let age = (student && student.birthday) ? <small>{` (${CalculateAge(student.birthday)})`}</small> : "";
            let studentName = [<GenderedName name={(regStudent) ? regStudent.name : studentEmail}
                                             gender={(student) ? student.gender : ""} />, age];

            if (regStudent && (regStudent.maleGuests > 0 || regStudent.femaleGuests > 0))
            {
                studentName.push(" + ")
                if (regStudent.maleGuests > 0)
                    studentName.push(<GenderedName name={`${regStudent.maleGuests} ♂`} gender="male" />);
                if (regStudent.femaleGuests > 0)
                {
                    if (regStudent.maleGuests)
                        studentName.push(" & ");

                    studentName.push(<GenderedName name={`${regStudent.femaleGuests} ♀`} gender="female" />);
                }
            }

            //Display which "goodForAllergy" the student matches
            let foodRestrictionMatches = [];
            if (student.allergies.length > 0 || student.foodPreferences.length > 0)
            {
                let matches = GetFoodRestrictionMatches((student) ? student.allergies.concat(student.foodPreferences) : [],
                                                        (host) ? host.goodForAllergy : []);
                for (let match of matches)
                    foodRestrictionMatches.push(<li>Matches {match}</li>);
            }

            let registeredStudents = this.state.registeredStudents[date][meal];
            let registeredHosts = this.state.registeredHosts[date][meal];
            return (
                <li key={studentEmail + j}
                    className="d-flex maintainer-allocated-student-list-item">
                    <MoveStudentButton
                        key={studentEmail + regHost.email}
                        student={regStudent} 
                        registeredHosts={registeredHosts}
                        date={date}
                        meal={meal}
                        allocatedHost={regHost}
                        customPopUpButtonClassesSuccess={CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS}
                        locationDetails={this.state.locationDetails}
                        allocateToHost={this.moveStudentToHost.bind(this)}
                        removeFromHost={this.removeStudentFromHost.bind(this)} />
                    <div className="maintainer-allocated-student-name"
                         onClick={this.displayFullStudentDetailsPopUp.bind(this, student, regStudent, date, meal, registeredStudents, registeredHosts)}>
                    {
                        foodRestrictionMatches.length === "" ?
                            <p>{studentName}</p>
                        :
                            <div>{studentName}<br/><small><ul>{foodRestrictionMatches}</ul></small></div>
                    }
                    </div>
                </li>
            );
        });

        //Return div, not ul
        return <div>{ret}</div>;
    }

    /**
     * Displays a pop-up with the details of a student and their registered student details for the week.
     * @param {Object} student - The student to display the details of.
     * @param {Object} regStudent - The registered student to display the details of.
     * @param {string} date - The date for which the student is signed up.
     * @param {string} meal - The meal for which the student is signed up.
     * @param {Object} registeredStudents - The map of all registered students for the week.
     * @param {Object} registeredHosts - The map of all registered hosts for the week.
     */
    displayFullStudentDetailsPopUp(student, regStudent, date, meal, registeredStudents, registeredHosts)
    {
        if (!student && !regStudent)
            return;

        //Pop-up to show combined student and registered student details
        ReactPopUp.fire
        ({
            title: <GenderedName name={`${student.firstName} ${student.lastName}`} gender={student.gender} />,
            html:
                <div className="text-start">
                    {
                        regStudent &&
                            this.printRegisteredStudentDetails(regStudent, date, meal, registeredStudents, registeredHosts)}
                    {
                        student &&
                            this.printStudentDetails(student)
                    }
                </div>,
            buttonsStyling: false,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Close",
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_CONTINUE,
        });
    }

    /**
     * Displays a pop-up with the details of a student.
     * @param {Object} student - The student to display the details of.
     */
    displayStudentDetailsPopUp(student)
    {
        if (!student)
            return;

        //Pop-up to show combined student and registered student details
        ReactPopUp.fire
        ({
            title: <GenderedName name={`${student.firstName} ${student.lastName}`} gender={student.gender} />,
            html:
                <div className="text-start">
                    {
                        student &&
                            this.printStudentDetails(student)
                    }
                </div>,
            buttonsStyling: false,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Close",
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_CONTINUE,
        });
    }

    /**
     * Displays a pop-up with the details of a host.
     * @param {Object} host - The host to display the details of.
     */
    displayHostDetailsPopUp(host)
    {
        if (!host)
            return;

        //Pop-up to show just the host details
        ReactPopUp.fire
        ({
            title: <GenderedName name={host.name} gender={host.gender} />,
            html:
                <div className="text-start">
                    {this.printHostDetails(host)}
                </div>,
            buttonsStyling: false,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Close",
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_CONTINUE,
        });
    }

    /**
     * Prints the list of all the hosts signed-up to host on a previous week.
     * @param {string} date - The date for which to retrieve the list of registered hosts.
     * @param {string} meal - The meal for which to retrieve the list of registered hosts.
     * @returns {JSX.Element} - An accordian with all the hosts signed-up to host for the specified week.
     */
    printPrevRegisteredHostsList(date, meal)
    {
        //Sort the hosts by name
        let prevRegisteredHostsList = Object.values(this.state.previousRegisteredHosts[date][meal]);
        if (prevRegisteredHostsList.length === 0)
            return "None!";

        //Render an accordion for each host
        return (
            <Accordion>
            {
                prevRegisteredHostsList.map((regHost, i) =>
                {
                    let host = this.getHost(regHost.email);

                    return (
                        <div className="maintainer-accordian-with-button-container">
                            <Accordion.Item key={i} eventKey={`${i}`}>
                                <Accordion.Header>
                                    <p><GenderedName name={regHost.name} gender={(host) ? host.gender : ""} /> ({this.caclulatePrevHostCapacity(regHost, date, meal)})</p>
                                </Accordion.Header>
                                <Accordion.Body>
                                {
                                    regHost.hosting.length === 0
                                    ?
                                        `No ${Capitalize(this.state.locationDetails.guestType)}s Assigned`
                                    :
                                        regHost.hosting.map((studentEmail, j) =>
                                        {
                                            //Display the student's name along with their number of guests
                                            let student = this.getStudent(studentEmail);
                                            let studentName = student ? `${student.firstName} ${student.lastName}` : studentEmail;
                                            let studentGender = student ? student.gender : "";
                                            let guests = this.calculatePrevWeekStudentGuests(studentEmail, date, meal);

                                            return (
                                                <li key={j}>
                                                {
                                                    <GenderedName name={studentName} gender={studentGender}
                                                                  onClick={student ? this.displayStudentDetailsPopUp.bind(this, student) : null} />
                                                }
                                                {
                                                    guests > 0 &&
                                                        <span> + {guests} Guest{guests !== 1 ? "s" : ""}</span>
                                                }
                                                </li>
                                            );
                                        })
                                }
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    );
                })
            }
            </Accordion>
        );
    }

    /**
     * Tries to print the meal approval toggle button based on the given parameters.
     * @param {string} date - The date of the meal.
     * @param {string} type - The type of the meal.
     * @param {string} meal - The name of the meal.
     * @param {boolean} allocated - Indicates if the meal is allocated.
     * @param {boolean} alreadyApproved - Indicates if the meal is already approved.
     * @returns {JSX.Element} - The JSX element representing the meal approval toggle button.
     */
    tryPrintMealApprovalToggle(date, type, meal, allocated, alreadyApproved)
    {
        if (MealsNeedApproval(this.state.locationDetails))
        {
            if (alreadyApproved)
            {
                return (
                    <Button className="maintainer-approve-meal-button" variant="danger"
                        onClick={this.unapproveMeal.bind(this, date, type, meal)}
                    >
                        Unapprove Meal
                    </Button>
                );
            }
            else
            {
                return (
                    <Button className="maintainer-approve-meal-button" variant="success"
                        disabled={!allocated}
                        onClick={this.approveMeal.bind(this, date, type, meal)}
                    >
                        Approve Meal
                    </Button>
                );
            }
        }

        return "";
    }

    /**
     * Prints the initial maintainer page before the user enters the password to unlock it.
     * @returns {JSX.Element} - The maintainer page.
     */
    printPageLocked()
    {
        return (
            <Form className="form-page" onSubmit={(e) => this.loadData(e)}>
                <div className="form-loading">
                    <PasswordField
                            password={this.state.passwordInput}
                            fieldPrefix={`${this.state.locationDetails.city} ${this.state.locationDetails.community} `}
                            setParentPassword={(password) => this.setState({passwordInput: password})} />

                    <div className="submit-form-button-container mt-2">
                        <Button variant="success" className="submit-form-button" type="submit">
                            Go
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }

    /**
     * Retrieves the accordions to be printed.
     * @returns {Object} An object containing the accordions to be printed.
     */
    getAccordiansToPrint()
    {
        let prefix;
        let lists = {}; //The page is diaplyed as a list of accordians
        let guestType = this.state.locationDetails.guestType;
        let guestTypeCap = Capitalize(guestType);
        let hostType = "host";
        let hostTypeCap = Capitalize(hostType);

        //Approved students and hosts
        prefix = this.useApprovals() ? "Approved" : "Current";
        lists[`${prefix} ${guestTypeCap}s & ${hostTypeCap}s`] =
            [{title: `${prefix} ${guestTypeCap}s`, list: this.printApprovedStudentsList()},
             {title: `${prefix} ${hostTypeCap}s`, list: this.printApprovedHostsList()}];
        
        //Unapproved students and hosts
        if (this.useApprovals())
        {
            prefix = "Unapproved";
            lists[`${prefix} ${guestTypeCap}s & ${hostTypeCap}s`] =
                [{title: `${prefix} ${guestTypeCap}s`, list: this.printUnapprovedStudentsList()},
                {title: `${prefix} ${hostTypeCap}s`, list: this.printUnapprovedHostsList()}];
        }

        //Former students (that are approved)
        if (this.useGraduation())
        {
            prefix = "Graduated";
            lists[`${prefix} ${guestTypeCap}s`] =
                [{title: `${prefix} ${guestTypeCap}s`, list: this.printGraduatedStudentsList()}];
        }

        //Weeks
        if (!this.state.showMealHistory)
        {
            let needMealApproval = MealsNeedApproval(this.state.locationDetails);

            //Upcoming weeks
            this.state.upcomingDates.map((week, i) =>
            {
                let studentAmount = this.countStudentsAndGuests(week.date, week.meal);
                let totalHostCap = this.countTotalHostCapacity(week.date, week.meal);

                lists[`${week.date} - ${HolyDayToString(week.type)} ${Capitalize(week.meal)} `
                    + `(${week.allocated ? "Allocated" : "Not Allocated Yet"}${needMealApproval && week.approved ? " & Approved" : ""})`] =
                    [{title: `Registered ${guestTypeCap}s (${studentAmount})`, list: this.printRegisteredStudentsList(week.date, week.type, week.meal)},
                    {title: `Registered ${hostTypeCap}s (${totalHostCap} Spot${totalHostCap !== 1 ? "s" : ""})`, list: this.printRegisteredHostsList(week.date, week.type, week.meal)},
                    {title: "", list: this.tryPrintMealApprovalToggle(week.date, week.type, week.meal, week.allocated, week.approved)}];
                return null;
            });

            if (this.state.upcomingDates.length === 0)
                lists[ "No Upcoming Date"] = [];    
        }
        else if (this.state.previousDates.length !== 0)
        {
            //Previous weeks
            this.state.previousDates.slice().reverse().map((week, i) => //Go in reverse
            {
                let meal = (week.meal) ? Capitalize(week.meal) : "Lunch"; //Handle legacy data
                lists[`${week.date} - ${HolyDayToString(week.type)} ${meal}`] =
                    [{title: `Registered ${hostTypeCap}s`, list: this.printPrevRegisteredHostsList(week.date, week.meal)}];
                return null;
            })
        }

        return lists;
    }

    /**
     * Renders the details accordion for a given list.
     * @param {number} i - The index of the accordion item.
     * @param {string} listKey - The key of the list from the lists object.
     * @param {Array} list - The list to be rendered.
     * @returns {JSX.Element} - The rendered details accordion.
     */
    printDetailsAccordian(i, listKey, list)
    {
        return (
            <Accordion.Item key={i + 2} eventKey={`${i + 2}`}>
                <Accordion.Header>{listKey}</Accordion.Header>
                <Accordion.Body className="maintainer-main-accordian-body">
                    {/* Map over each list and create a div for each one */}
                    <div className="maintainer-list-section">
                    {
                        list.map((list, i) =>
                        {
                            if (list.title === "" && list.list === "")
                                return "";

                            return (
                                <div className="maintainer-list" key={i}>
                                    <div className="maintainer-list-title" key={0}>
                                        {list.title}
                                    </div>
                                    <div className="maintainer-list-content" key={1}>
                                        {list.list}
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    {this.tryPrintGraduationToggle(listKey)}
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    /**
     * Prints the maintainer page.
     */
    render()
    {
        if (!this.state.loaded)
            return this.printPageLocked();

        const lists = this.getAccordiansToPrint();
        return (
            <div className="maintainer-page">
                <div className="maintainer-lists">
                    <div className="maintainer-page-title">
                        {this.printReloadButton(true) /* Dummy for alignment */}
                        <h1 className="mt-0 mb-0">Maintainer Dashboard</h1>
                        {this.printReloadButton(false)}
                    </div>

                    <Accordion>
                    {/* Create the add dates accordian */}
                        <Accordion.Item key={0} eventKey={`${0}`}>
                            {this.printAddDatesAccordion()}
                        </Accordion.Item>

                    {/* Create the location details accordian */}
                        <Accordion.Item key={1} eventKey={`${1}`}>
                            {this.printLocationDetails()}
                        </Accordion.Item>

                    {/* Map over lists and create an accordian for each one */}
                    {
                        Object.keys(lists).map((listKey, i) =>
                        {
                            let list = lists[listKey];
                            return this.printDetailsAccordian(i, listKey, list);
                        })
                    }
                    </Accordion>

                    {/* Show a button to toggle the previous weeks */}
                    <div className="mt-5 maintainer-meal-history-button-container">
                        <Button onClick={() => this.setState({showMealHistory: !this.state.showMealHistory})}>
                        {
                            this.state.showMealHistory ?
                                "Upcoming Meals"
                            :
                                "Previous Meals"
                        }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default MaintainerView;
