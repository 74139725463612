/*
    A class for the form hosts use to cancel weekly.
*/

import React, {Component} from 'react';

import CancellationForm from './CancellationForm';
import {GetUpcomingDateNameList, PageLoading, NoDatesAvailablePopUp} from "./Util";


export class HostCancellation extends Component
{
    /*
        Sets up the weekly host cancellation page.
    */
    constructor(props)
    {
        super(props);

        this.state =
        {
            loaded: false,
            dateList: [],
            locationDetails: props.locationDetails,
        }
    }

    /*
        Loads data from the server when the page loads.
    */
    async componentDidMount()
    {
        let dateList = await GetUpcomingDateNameList(this.state.locationDetails);

        if (this.state.loaded)
            return; //Prevent the pop-up for displaying multiple times

        this.setState
        ({
            loaded: true,
            dateList: dateList,
        });

        if (dateList.length === 0) //No dates available
            NoDatesAvailablePopUp(this.state.locationDetails);
    }

    /*
        Prints the weekly host cancellation page.
    */
    render()
    {
        if (!this.state.loaded)
            return PageLoading();

        return (
            <CancellationForm dateList={this.state.dateList} type="host" locationDetails={this.state.locationDetails}
                cancellationMsg={"Cancellation complete.\nSorry to hear you won't be able to host!"}
                deadlineMsg="Please don't cancel at the last minute."/>
        )
    }
}

export default HostCancellation;
