/**
 * This file defines the BirthdayField component.
 * It is used to input a user's birthday.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker";

import {RequiredTooltip, DATE_PICKER_YEAR_RANGE} from '../Util';


export class BirthdayField extends Component
{
    /**
     * Represents the BirthdayField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.birthday - The default birthday input value.
     * @param {string} props.rationaleText - The rationale text to display.
     * @param {Function} props.setParentBirthday - The function to set the parent's birthday input.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {string} props.idMod - The string to modify the id of the birthday input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            birthdayInput: props.birthday,
            warningCardText: props.warningCardText,
            rationaleText: props.rationaleText,
            idMod: props.idMod || "",
        }

        this.setParentBirthday = props.setParentBirthday;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Determines whether the rationale above the input field should be shown.
     * @returns {boolean} Whether the rationale should be shown.
     */
    shouldShowRationale()
    {
        return this.state.rationaleText
            && this.state.rationaleText !== "";
    }

    /**
     * Sets the birthday and also updates the parent component.
     * @param {string} birthday - The birthday to set.
     */
    setBirthday(birthday)
    {
        this.setState({birthdayInput: birthday});
        this.setParentBirthday(birthday);
    }

    /**
     * Renders the BirthdayField component.
     * @returns {JSX.Element} The rendered BirthdayField component.
     */
    render()
    {
        const required = RequiredTooltip();

        return (
            <Form.Group className="mb-3" controlId={"formBirthdayInput" + this.state.idMod}>
                {/* Title */}
                <Form.Label className={this.shouldShowRationale() ? "mb-0" : "mb-1"}>
                    Date of Birth{required}
                </Form.Label>

                {/* Rationale */}
                {
                    this.shouldShowRationale() &&
                        <div className="mb-1">
                            <Form.Text className="text-muted">
                                {this.state.rationaleText}
                            </Form.Text>
                        </div>
                }

                {/* Input Field */}
                <span data-testid={"birthday-input" + this.state.idMod}>
                    <DatePicker
                        className="react-datepicker"
                        dateFormat={"MMMM d, yyyy" /* Show readable date */}
                        selected={this.state.birthdayInput !== "" ? new Date(this.state.birthdayInput) : null}
                        renderCustomHeader={RenderDatePickerHeader}
                        minDate={new Date(`${new Date().getFullYear() - DATE_PICKER_YEAR_RANGE}-01-01`) /* Start n years behind */}
                        maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                        showDisabledMonthNavigation
                        onChange={(date) =>
                        {
                            //Format to YYYY-MM-DD
                            const ymd = date.getFullYear() + "-"
                                    + (date.getMonth() + 1).toString().padStart(2, '0') + "-"
                                    + date.getDate().toString().padStart(2, '0');
                            this.setBirthday(ymd);
                        }}/>
                </span>
            </Form.Group>
        );
    }
}

/**
 * Renders the date picker header component.
 *
 * @param {Object} props - The component props.
 * @param {Date} props.date - The selected date.
 * @param {Function} props.changeYear - The function to change the selected year.
 * @param {Function} props.changeMonth - The function to change the selected month.
 * @param {Function} props.decreaseMonth - The function to decrease the selected month.
 * @param {Function} props.increaseMonth - The function to increase the selected month.
 * @param {boolean} props.prevMonthButtonDisabled - Indicates if the previous month button is disabled.
 * @param {boolean} props.nextMonthButtonDisabled - Indicates if the next month button is disabled.
 * @returns {JSX.Element} The rendered date picker header component.
 */
export function RenderDatePickerHeader({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
})
{
    //Range of past n years
    const years = Array.from({ length: DATE_PICKER_YEAR_RANGE }, (_, i) => new Date().getFullYear() + 1 - i);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return (
        <div className="date-picker">
            {/* Prev Month Button */}
            <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><polyline points="15 18 9 12 15 6"></polyline></svg>
            </button>

            {/* Year Selection */}
            <select
                className="form-select year-select"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            { /* Month Selection */ }
            <select
                className="form-select month-select"
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            {/* Next Month Button */}
            <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </button>
        </div>
    );
}

export default BirthdayField;
