/**
 * This file defines the GenderField component.
 * It is used to input a user's gender.
 */

import React, {Component} from 'react';
import {Form, Card} from "react-bootstrap";
import {Dropdown} from 'semantic-ui-react';

import {CreateGenderList, RequiredTooltip} from '../Util';


export class GenderField extends Component
{
    /**
     * Represents the GenderField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.gender - The default gender input value.
     * @param {string} props.warningCardText - The text to display in the warning card.
     * @param {Function} props.setParentGender - The function to set the parent's gender input.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {string} props.idMod - The string to modify the id of the gender input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            genderInput: props.gender,
            warningCardText: props.warningCardText,
            idMod: props.idMod || "",
        }

        this.setParentGender = props.setParentGender;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Sets the gender and also updates the parent component.
     * @param {string} gender - The gender to set.
     */
    setGender(gender)
    {
        this.setState({genderInput: gender});
        this.setParentGender(gender);
    }

    /**
     * Renders the GenderField component.
     * @returns {JSX.Element} The rendered GenderField component.
     */
    render()
    {
        const required = RequiredTooltip();

        return (
            <Form.Group className="mb-3" controlId={"formGenderInput" + this.state.idMod}>
                {/* Title */}
                <Form.Label className="mb-1">Gender{required}</Form.Label>

                {/* Input Field */}
                <Dropdown
                    fluid
                    selection
                    defaultValue={this.state.genderInput}
                    options={CreateGenderList()}
                    className={`form-control ${this.state.genderInput !== "" && this.isErrorMessage("INVALID_GENDER") ? 'is-invalid' : ''}`}
                    data-testid={"gender-input" + this.state.idMod}
                    onChange={(e, data) => this.setGender(data.value)}
                />

                {/* Warning Card */}
                <div className="mb-1 mt-2">
                {
                    this.state.genderInput === "none" &&
                        <Card bg="warning">
                            <Card.Body>
                                {this.state.warningCardText}
                            </Card.Body>
                        </Card>
                }
                </div>
            </Form.Group>
        );
    }
}

export default GenderField;
