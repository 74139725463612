/**
 * This file defines the NavBar component.
 * It is used to display the navigation bar at the top of the page.
 */

import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Swal from 'sweetalert2'; //Pop-Up


/**
 * Represents the navigation bar component.
 * @returns {JSX.Element} The JSX element representing the navigation bar.
 */
const NavBar = () =>
{
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * Navigates to the base path for the current location.
     */
    const navigateToBase = () =>
    {
        if (Swal.isVisible())
            return; //Don't navigate if a pop-up is visible

        if (location.pathname === "/")
            return;

        let path = location.pathname.split("/")[1];
        navigate(`/${path}`);
    };

    return (
        <div className="app-header">
            <div className="app-title no-select" onClick={navigateToBase}>
                <img src="/shlabot-icon.png" alt="Shlabot Icon" className="app-icon" />
                <h1 className="no-select"><span className="shla">SHLA</span><span className="bot">BOT</span></h1>
            </div>
        </div>
    );
}

export default NavBar;
