/**
 * A class for the page the site admin uses to deal with admin tasks.
 */

import React from 'react';
import {Accordion, Button, Form} from "react-bootstrap";
import Swal from 'sweetalert2'; //Pop-Up
import withReactContent from 'sweetalert2-react-content';

import {Capitalize, ErrorPopUp, SendFormToServer, GetAdminDashboardInfo,
        GLOBAL_ERROR_MESSAGES} from "./Util";
import {PasswordField} from './subcomponents/PasswordField';

import {MdCheck, MdCancel} from 'react-icons/md';

import "./styles/Admin.css";
import "./styles/Maintainer.css";

const ReactPopUp = withReactContent(Swal);
const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    NULL_ALLERGY_LIST: "The details were wiped before reaching the server!",
    INVALID_ALLERGIES: "Invalid allergy list!",
    INVALID_PASSWORD: "Unauthorized access!",
    FAILED_TO_CHANGE_APPROVAL: "Failed to change the approval status of the allergies!",
};


class AdminPortal extends React.Component
{
    /**
     * Sets up the admin portal page.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            loaded: false,
            formRetVal: null,
            passwordInput: "",

            //Data for allergy
            allergyList: [],
            allergiesAwaitingApproval: [],
            unapprovedAllergies: [],
            allergiesToApprove: new Set(),
            allergiesToUnapprove: new Set(),
        }
    }

    /**
     * Gets the allergy lists from the server.
     * @param {Event} e - The form submission event.
     */
    async loadData(e)
    {   
        if (e)
            e.preventDefault(); //Prevent the page from reloading

        ReactPopUp.fire
        ({
            title: 'Connecting, please wait...',
            timer: 30000, //30 seconds
            timerProgressBar: true,
            allowOutsideClick: false,
            showConfirmButton: false,
            showCancelButton: false,
            didOpen: async () =>
            {
                let ret = await GetAdminDashboardInfo(this.state.passwordInput);
                if (ret == null)
                {
                    this.errorPopUp("INVALID_PASSWORD");
                    return null;
                }

                let {approved, awaitingApproval, unapproved} = ret.allergies;

                console.log(approved, awaitingApproval, unapproved);
                this.setState
                ({
                    loaded: true,
                    allergyList: approved,
                    allergiesAwaitingApproval: awaitingApproval,
                    unapprovedAllergies: unapproved,
                });

                Swal.close();
            },
        }).then((e) =>
        {
            if (e.dismiss === Swal.DismissReason.timer)
            {
                this.errorPopUp("NO_SERVER_CONNECTION");
                return null;
            }
        });
    }

    /**
     * Marks an allergy as one of the ones to be approved.
     * @param {String} allergy - The allergy to approve.
     */
    toggleAllergyApproval(allergy)
    {
        this.toggleAllergyApprovalStatus(allergy, "allergiesToApprove", "allergiesToUnapprove");
    }

    /**
     * Marks an allergy as one of the ones to be unapproved.
     * @param {String} allergy - The allergy to unapprove.
     */
    toggleAllergyUnapproval(allergy)
    {
        this.toggleAllergyApprovalStatus(allergy, "allergiesToUnapprove", "allergiesToApprove");
    }

    /**
     * Toggles the approval status of an allergy.
     * @param {String} allergy - The allergy to toggle the approval status of.
     * @param {String} stateKey - The key for the state to toggle the status in.
     * @param {String} otherListKey - The state key for the opposite toggle list.
     */
    toggleAllergyApprovalStatus(allergy, stateKey, otherListKey)
    {
        //Ensure it's not in the other list
        let otherList = this.state[otherListKey];
        otherList.delete(allergy);

        //Toggle the approval
        let set = this.state[stateKey];
        if (set.has(allergy))
            set.delete(allergy);
        else
            set.add(allergy);

        this.setState({[stateKey]: set, [otherListKey]: otherList});
    }

    /**
     * Approves all the allergies set to be approved.
     */
    async approveAllergies()
    {
        const route = `/allergyapproval`;
        await this.makeAllergyApprovalServerCall(route, Array.from(this.state.allergiesToApprove), "Allergy approval updated!");
    }

    /**
     * Unapproves all the allergies set to be unapproved.
     */
    async unapproveAllergies()
    {
        const route = `/allergyunapproval`;
        await this.makeAllergyApprovalServerCall(route, Array.from(this.state.allergiesToUnapprove), "Allergy unapproval updated!");
    }

    /**
     * Updates the database with the allergies to approve or unapprove.
     * @param {String} route - The route to send the data to.
     * @param {Array} allergyList - The list of allergies to approve or unapprove.
     * @param {String} successMessage - The message to show on success.
     */
    async makeAllergyApprovalServerCall(route, allergyList, successMessage)
    {
        const data = {allergies: allergyList, password: this.state.passwordInput};
        await SendFormToServer(data, this, route, successMessage, {}, null);

        //Loop while the formRetVal is null and at most for 30 seconds
        while (this.state.formRetVal == null)
        {
            let time = new Date().getTime();
            await new Promise(resolve => setTimeout(resolve, 100));
            if (new Date().getTime() - time > 30000)
                break;
        }

        //Update using the data sent back from the server
        let ret = this.state.formRetVal;
        if (ret && Object.keys(ret).length > 0)
        {
            this.setState
            ({
                allergyList: ret.allergyList,
                allergiesAwaitingApproval: ret.awaitingApproval,
                unapprovedAllergies: ret.unapproved,
                allergiesToApprove: new Set(),
                allergiesToUnapprove: new Set(),
                formRetVal: null,
            });
        }
    }

    /**
     * Displays an error pop-up.
     * @param {String} errorSymbol - The error symbol for the message to be shown on the pop-up.
     */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        ErrorPopUp(text);
    }

    /**
     * Prints one of the allergy list accordians.
     * @param {Number} key - The key for the accordian.
     * @param {String} title - The title of the accordian.
     * @param {Array} list - The list of allergies to print.
     * @param {Boolean} useApprovalButton - Whether to use the approval button.
     * @param {Boolean} useAnapprovalButton - Whether to use the unapproval button.
     * @returns {JSX.Element} - The accordian element.
     */
    printAllergyAccordian(key, title, list, useApprovalButton, useAnapprovalButton)
    {
        const accordianBody = list.length > 0 ?
            <ul>
            {
                list.map((allergy, index) =>
                {
                    let approvalIconClassName = (this.state.allergiesToApprove.has(allergy)) ? "btn-success-always-hovered" : "";
                    let unapprovalIconClassName = (this.state.allergiesToUnapprove.has(allergy)) ? "btn-danger-always-hovered" : "";

                    return (
                        <li key={index} className="d-flex maintainer-allocated-student-list-item">
                            {
                                useApprovalButton &&
                                    <Button variant="success" className={approvalIconClassName}
                                            onClick={this.toggleAllergyApproval.bind(this, allergy)}>
                                        <MdCheck size={20} />
                                    </Button>
                            }
                            {
                                useAnapprovalButton &&
                                    <Button variant="danger" className={unapprovalIconClassName}
                                            onClick={this.toggleAllergyUnapproval.bind(this, allergy)}>
                                        <MdCancel size={20} />
                                    </Button>
                            }
                            <p className="admin-allergy-approval-allergy-name">{allergy}</p>
                        </li>
                    );
                })
            }
            </ul>
        :
            <p className="text-center">None!</p>;

        return (
            <Accordion.Item key={key} eventKey={`${key}`}>
                <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body>
                    {accordianBody}
                </Accordion.Body>
            </Accordion.Item>
        );   
    }

    /**
     * Prints the toggle for approving or unapproving allergies.
     * @param {String} stateKey - The key for the state to use.
     * @param {String} approvalType - The type of approval to show in the message.
     * @param {Function} buttonFunc - The function to call when the button is clicked.
     * @returns {JSX.Element} The toggle element.
     */
    printAllergyApprovalToggle(stateKey, approvalType, buttonFunc)
    {
        let nameList = Array.from(this.state[stateKey]); //Convert set to list
        if (nameList.length === 0)
            return "";

        return (
            <div className="maintainer-list-content">
                <h3 className="text-center">Set Allergies {Capitalize(approvalType)}</h3>
                <ul>
                {
                    nameList.map((name, i) =>
                    {
                        return <li key={i}>{name}</li>;
                    })
                }
                </ul>
                <Button className="maintainer-set-graduated-button" onClick={buttonFunc}>
                    Set {Capitalize(approvalType)}
                </Button>
            </div>
        ); 
    }

    /**
     * Prints the initial admin page before the user enters the password to unlock it.
     * @returns {JSX.Element} - The maintainer page.
     */
    printPageLocked()
    {
        return (
            <Form className="form-page" onSubmit={(e) => this.loadData(e)}>
                <div className="form-loading">
                    <PasswordField
                        password={this.state.passwordInput}
                        fieldPrefix={`Admin `}
                        setParentPassword={(password) => this.setState({passwordInput: password})} />

                    <div className="submit-form-button-container mt-2">
                        <Button variant="success" className="submit-form-button" type="submit">
                            Go
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }

    /**
     * Renders the allergy approval page.
     * @returns {JSX.Element} The allergy approval page.
     */
    render()
    {
        if (!this.state.loaded)
            return this.printPageLocked();

        return (
            <div className="form-page">
                <h1 className="form-title reset-password-form-title">Admin Dashboard</h1>

                {/* Lists of Allergies */}
                <h2 className="form-title reset-password-form-title">Allergy Approval</h2>
                <Accordion>
                    {this.printAllergyAccordian(0, "Approved", this.state.allergyList, false, true)}
                    {this.printAllergyAccordian(1, "Awaiting Approval", this.state.allergiesAwaitingApproval, true, true)}
                    {this.printAllergyAccordian(2, "Unapproved", this.state.unapprovedAllergies, true, false)}
                </Accordion>

                {/* Approval Buttons */}
                <div className="mt-3 d-flex justify-content-center">
                    <div>
                        {this.printAllergyApprovalToggle("allergiesToApprove", "approved", this.approveAllergies.bind(this))}
                        {this.printAllergyApprovalToggle("allergiesToUnapprove", "unapproved", this.unapproveAllergies.bind(this))}
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminPortal;
