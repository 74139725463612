/**
 * A class students and hosts can use to send a link to reset their password.
 */

import React from 'react';
import {Button, Form} from "react-bootstrap";

import UnknownPage from "./UnknownPage";

import {PATH_HOME} from './App';
import {Capitalize, ValidateEmail, ErrorPopUp,
        SendFormToServer, ComponentWithRouter,
        GLOBAL_ERROR_MESSAGES} from "./Util";
import {EmailField} from './subcomponents/EmailField';

import "./styles/FormPage.css";
import "./styles/ResetPassword.css";

const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_EMAIL: "Invalid email!",
    NO_ACCOUNT_FOUND: "No {TYPE} account with that email exists!",
    RESET_COOLDOWN: "Please wait 1 hour between changing passwords.",
    LINK_COOLDOWN: "Please wait at least a minute before sending another link.",
};


class ForgotPassword extends React.Component
{
    /**
     * Sets up the forgot password page.
     * @param {Object} props - The props including router data.
     */
    constructor(props)
    {
        super(props);
        let routerParams = this.props.router.params;

        this.state =
        {
            type: routerParams.type, //Access the URL parameter
            emailInput: "",
            invalidEmail: "",
            errorMsg: "",
            showedErrorPopUp: false,
            locationDetails: props.locationDetails,
        }
    }

    /**
     * Checks if the current state type is for a student.
     * @returns {boolean} Whether the edit details page is for a student.
     */
    isStudent()
    {
        return this.state.type === this.state.locationDetails.guestType;
    }

    /**
     * Checks if the current state type is for a host.
     * @returns {boolean} Whether the edit details page is for a host.
     */
    isHost()
    {
        return this.state.type === "host";
    }

   /**
    * Checks if the input email is a valid email.
    * @returns {Boolean} true if the email is valid, false otherwise.
    */
    validEmail()
    {
        return ValidateEmail(this.state.emailInput);
    }

    /**
     * Checks if the current email input is for an account that doesn't exist.
     * @returns {Boolean} true if the the account for the email input doesn't exist, false otherwise.
     */
    emailNotAccount()
    {
        return this.state.invalidEmail !== ""
            && this.state.invalidEmail === this.state.emailInput;
    }

   /**
    * Gets the error message (if present) at the time of form submission.
    * @returns {String} The error message symbol.
    */
    getErrorMessage()
    {
        let errorMsg = "";

        if (!this.validEmail())
            errorMsg = "INVALID_EMAIL";

        return errorMsg;
    }

   /**
    * Displays an error pop-up.
    * @param {String} errorSymbol - The error symbol for the message to be shown on the pop-up.
    */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        text = text.replaceAll("{TYPE}", this.state.type);
        ErrorPopUp(text);
    }

    /**
     * Submits the forgot password form and emails the user a link to reset their password.
     * @param {Object} e - The form submission event. 
     */
    async sendPasswordResetLink(e)
    {
        e.preventDefault(); //Prevent page reload
        const type = (this.isHost()) ? "host" : "student";
        const route = `/send${type}passwordresettoken`;
        let errorMsg = this.getErrorMessage();

        if (errorMsg === "") //No error
        {
            let data =
            {
                email: this.state.emailInput.toLowerCase(),
            };

            await SendFormToServer(data, this, route, 'Link sent!\nCheck your email to reset your password.',
                                   this.state.locationDetails, PATH_HOME);
        }
        else
        {
            this.setState({errorMsg: errorMsg});
            this.errorPopUp(errorMsg);
        }
    }

    /**
     * Prints the forgot password page.
     * @returns {JSX.Element} 
     */
    render()
    {
        let formType = Capitalize(this.state.type);

        if (!this.isStudent() && !this.isHost())
            return <UnknownPage />;

        return (
            <div className="form-page">
                <h1 className="form-title reset-password-form-title">Forgot Password ({formType})</h1>
                <div className="mb-3"><Form.Text>Forgot your password? Enter it here and a link will be sent to your email to reset it.</Form.Text></div>

                <Form onSubmit={(e) => this.sendPasswordResetLink(e)}>
                    {/* Email Input */}
                    <EmailField
                        email={this.state.emailInput}
                        setParentEmail={(email) => this.setState({emailInput: email})}
                        isInvalid={() => !this.validEmail() || this.emailNotAccount()} />

                    {/* Submit Button */}
                    <div className="submit-form-button-container mt-2">
                        <Button className="submit-form-button" type="submit">
                            Send Link
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

export default ComponentWithRouter(ForgotPassword);
