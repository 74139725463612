/*
    A class for the form students and hosts use to cancel weekly.
*/

import React, {Component} from 'react';
import {Button, Form} from "react-bootstrap";

import {PATH_HOME} from './App';
import {Capitalize, ExtractCountryCityCommunity, ValidateEmail,
        ErrorPopUp, SendFormToServer,
        GLOBAL_ERROR_MESSAGES} from "./Util";
import {DateDropdown} from "./subcomponents/DateDropdown";
import {EmailField} from './subcomponents/EmailField';
import {PasswordField} from './subcomponents/PasswordField';
import {RadioOptions} from './subcomponents/RadioOptions';

import "./styles/FormPage.css";
import "./styles/CancellationForm.css";

const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_EMAIL: "Invalid email!",
    INVALID_PASSWORD: "Incorrect password!",
    INVALID_DATE: "Invalid date!",
    NO_ACCOUNT_FOUND: "No account with that email exists!",
    NOT_SIGNED_UP: "You haven't signed up for this week!",
    EMPTY_REQUEST: "The details were wiped before reaching the server!",
    STUDENT_DEADLINE_PASSED: "It's too late to cancel! Please contact your host directly.",
    HOST_DEADLINE_PASSED: "This date has already passed!",
    DIDNT_SIGN_UP: "No sign-up was found for this date!",
};


export class CancellationForm extends Component
{
    /*
        Sets up the cancellation page.
    */
    constructor(props)
    {
        super(props);

        this.state =
        {
            emailInput: "",
            passwordInput: "",
            dateInput: (props.dateList.length > 0) ? props.dateList[0].date : "", //Default to the first entry if there is one
            holyDayInput: (props.dateList.length > 0) ? props.dateList[0].type : "", //Default to the first entry if there is one
            mealInput: (props.dateList.length > 0) ? props.dateList[0].meal : "", //Default to the first entry if there is one
            cancelCouple: true,
            dateList: props.dateList,
            showedErrorPopUp: false,
            errorMsg: "",
            invalidEmail: "",
            type: props.type,
            cancellationMsg: props.cancellationMsg,
            deadlineMsg: props.deadlineMsg,
            locationDetails: props.locationDetails,
        }
    }

    /**
     * Checks if the current state type is for a host.
     * @returns {boolean} Whether the cancellation form is for a host.
     */
    isHost()
    {
        return this.state.type === "host";
    }

    /**
     * Checks if the current state type is for a student.
     * @returns {boolean} Whether the cancellation form is for a student.
     */
    isStudent()
    {
        return this.state.type === this.state.locationDetails.guestType;
    }

    /*
        Ensures all necessary fields in the form are filled.
        returns: true if all required fields are filled, false otherwise.
    */
    allRequiredFieldsFilled()
    {
        return this.state.emailInput !== ""
            && this.state.passwordInput !== ""
            && this.state.dateInput !== ""
            && this.state.holyDayInput !== ""
            && this.state.mealInput !== "";
    }

    /*
        Checks if the input email is a valid email.
        returns: true if the email is valid, false otherwise.
    */
    validEmail()
    {
        return ValidateEmail(this.state.emailInput);
    }

    /*
        Gets the error message (if present) at the time of form submission.
        returns: The error message symbol.
    */
    getErrorMessage()
    {
        let errorMsg = "";

        if (!this.allRequiredFieldsFilled())
            errorMsg = "MISSING_REQUIRED_FIELD";
        else if (!this.validEmail())
            errorMsg = "INVALID_EMAIL";

        return errorMsg;
    }

    /*
        Checks if an error message symbol is the last shown error message.
        param errorMsg: The error message symbol to check.
        returns: true if the the error message symbol was last shown, false otherwise.
    */
    isErrorMessage(errorMsg)
    {
        return this.state.errorMsg === errorMsg;
    }

    /*
        Checks if the current email input is for an account that doesn't exist.
        returns: true if the the account for the email input doesn't exist, false otherwise.
    */
    emailNotAccount()
    {
        return this.state.invalidEmail !== ""
            && this.state.invalidEmail === this.state.emailInput;
    }

    async submitCancellation(e)
    {
        e.preventDefault(); //Prevent page reload
        let errorMsg = this.getErrorMessage();
        const type = (this.isHost()) ? "host" : "student";
        const route = `/weekly${type}cancellation`;

        if (errorMsg === "") //No error
        {
            let data =
            {
                email: this.state.emailInput.toLowerCase(),
                password: this.state.passwordInput,
                date: this.state.dateInput,
                type: this.state.holyDayInput,
                meal: this.state.mealInput,
            };

            if (this.isStudent())
                data.cancelCouple = this.state.cancelCouple;

            await SendFormToServer(data, this, route, this.state.cancellationMsg, this.state.locationDetails, PATH_HOME);
        }
        else
        {
            this.setState({errorMsg: errorMsg});
            this.errorPopUp(errorMsg);
        }
    }

    /*
        Displays an error pop-up.
        param errorSymbol: The error symbol for the message to be shown on the pop-up.
    */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        ErrorPopUp(text);
    }

    /*
        Sets the date inputs for the form.
        param date: The date to set.
        param holyDay: The holy day to set.
        param meal: The meal to set.
    */
    setDateInputs(date, holyDay, meal)
    {
        this.setState({dateInput: date, holyDayInput: holyDay, mealInput: meal});
    }

    /*
        Prints the cancellation page.
    */
    render()
    {
        let {country, city, community} = ExtractCountryCityCommunity(this.state.locationDetails);

        return (
            <div className="form-page">
                <h1 className="form-title weekly-student-form-title">{Capitalize(this.state.type)} Cancellation</h1>
                <h2 className="form-title weekly-student-form-title">{community}, {city}, {country}</h2>
                <div className="mb-3"><Form.Text>{this.state.deadlineMsg}</Form.Text></div>

                <Form onSubmit={(e) => this.submitCancellation(e)}>
                    {/*Email Input*/}
                    <EmailField
                        email={this.state.emailInput}
                        setParentEmail={(email) => this.setState({emailInput: email})}
                        isInvalid={() => !this.validEmail() || this.emailNotAccount()} />

                    {/*Password Input*/}
                    <PasswordField
                        password={this.state.passwordInput}
                        showForgotPasswordLink={true}
                        setParentPassword={(password) => this.setState({passwordInput: password})}
                        personType={this.state.type}
                        basePath={this.state.locationDetails.basePath} />

                    {/*Date Input*/}
                    <DateDropdown dateList={this.state.dateList} dateInput={this.state.dateInput}
                                  holyDayInput={this.state.holyDayInput} mealInput={this.state.mealInput}
                                  setParentDateInputs={this.setDateInputs.bind(this)}
                                  text="Which date are you cancelling for?" />

                    {/*Single or Married Input*/}
                    {
                        this.isStudent() &&
                            <RadioOptions
                                input={this.state.cancelCouple}
                                radioLabels={[{label: "Yes", val: true}, {label: "No", val: false}]}
                                fieldLabel="Are you cancelling for your spouse too?"
                                fieldDesc={"Only applicable if you originally registered as a couple. Ignore this if you didn't."}
                                setParentInput={(val) => this.setState({cancelCouple: val})} />
                    }

                    {/* Submit Button */}
                    <div className="submit-form-button-container mt-2">
                        <Button variant="danger" className="submit-form-button" type="submit">
                            Cancel Meal
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default CancellationForm;
