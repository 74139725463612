/**
 * This file defines the PasswordField component.
 * It is used to input a password.
 */

import React, {Component} from 'react';
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {FiEye, FiEyeOff} from "react-icons/fi";

import {PATH_RESET_PASSWORD} from '../App';
import {ProcessTextInput, RequiredTooltip} from '../Util';

import "../styles/PasswordField.css";


export class PasswordField extends Component
{
    /**
     * Represents the NewPasswordField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.password - The default password input value.
     * @param {string} props.fieldPrefix - The prefix for the field title.
     * @param {string} props.fieldDesc - The description for the field.
     * @param {boolean} props.isConfirmPassword - Whether the field is for confirming the password.
     * @param {boolean} props.showForgotPasswordLink - Whether to show the forgot password link under the field.
     * @param {Function} props.setParentPassword - The function to set the parent component's password.
     * @param {Function} props.showParentPassword - The function to show password based on the parent component's state.
     * @param {Function} props.toggleShowParentPassword - The function to toggle the parent component's show password state.
     * @param {Function} props.showError - The function to check if there is a problem with the field.
     * @param {string} props.idMod - The string to modify the id of the password input.
     * @param {string} props.personType - The type of person using the password field.
     * @param {string} props.basePath - The base path for the forgot password link.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            passwordInput: props.password,
            isConfirmPassword: props.isConfirmPassword || false,
            showForgotPasswordLink: props.showForgotPasswordLink || false,
            fieldPrefix: props.fieldPrefix || "",
            fieldDesc: props.fieldDesc || "",
            idMod: props.idMod || "",
            personType: props.personType || "",
            basePath: props.basePath || "",
            showPassword: false,
        }

        this.setParentPassword = props.setParentPassword;
        this.showParentPassword = props.showParentPassword;
        this.toggleShowParentPassword = props.toggleShowParentPassword;
        this.showError = props.showError;

        if (this.showError == null)
            this.showError = () => false; //No error by default
    }

    /**
     * Returns the value indicating whether the password should be shown or not.
     * If `showParentPassword` is defined, it calls the `showParentPassword` function to determine the value.
     * @returns {boolean} Whether the password should be shown or not.
     */
    showPassword()
    {
        if (this.showParentPassword != null)
            return this.showParentPassword(); //Show password based on parent component's state.

        return this.state.showPassword;
    }

    /**
     * Toggles the visibility of the password field.
     * If a `toggleShowParentPassword` function is provided, the show password state in the parent component will be toggled instead.
     */
    toggleView()
    {
        if (this.toggleShowParentPassword != null)
            this.toggleShowParentPassword(); //Toggle show password for the parent component's state.

        this.setState({showPassword: !this.state.showPassword});
    }

    /**
     * Sets the password and also updates the parent component.
     * @param {Object} e - The event object to get the input from.
     */
    setPassword(e)
    {
        let password = ProcessTextInput(e, "PASSWORD", true);
        this.setState({passwordInput: password});
        this.setParentPassword(password);
    }

    /**
     * Renders the password view toggle button.
     * @returns {JSX.Element} The password view toggle button.
     */
    printPasswordViewToggle()
    {
        const size = 24;
        const showPasswordTooltip = props => (<Tooltip {...props}>Show Password</Tooltip>);
        const hidePasswordTooltip = props => (<Tooltip {...props}>Hide Password</Tooltip>);
        const tooltip = (this.showPassword()) ? hidePasswordTooltip : showPasswordTooltip;

        return (
            <OverlayTrigger placement="top" overlay={tooltip}>
                <div className="view-password-toggle no-select"
                    onClick={this.toggleView.bind(this)}>
                {
                    this.showPassword() ?
                        <FiEyeOff size={size} />
                    :
                        <FiEye size={size} />
                }
                </div>
            </OverlayTrigger>
        );
    }

    /**
     * Renders the PasswordField component.
     * @returns {JSX.Element} The rendered PasswordField component.
     */
    render()
    {
        const required = RequiredTooltip();
        const controlId = (this.state.isConfirmPassword) ? "formBasicConfirmPassword" : "formBasicPassword";

        return (
            <Form.Group className="mb-3" controlId={controlId + this.state.idMod}>
                {/* Title */}
                <Form.Label className={this.state.fieldDesc ? "mb-0" : "mb-1"}>
                    {this.state.fieldPrefix}Password{required}
                </Form.Label>

                {/* Note */}
                {
                    this.state.fieldDesc &&
                        <div className="mb-1">
                            <Form.Text className="text-muted">
                                {this.state.fieldDesc}
                            </Form.Text>
                        </div>
                }

                {/* Input */}
                <div className="d-flex">
                    <Form.Control
                        required
                        type={this.showPassword() ? "text" : "password"}
                        name={this.state.isConfirmPassword ? "confirmPassword" : "password"}
                        data-testid={(this.state.isConfirmPassword ? "confirm-" : "") + "password-input" + this.state.idMod}
                        //placeholder="Password"
                        className={`form-control ${this.showError() ? 'is-invalid' : ''}`}
                        value={this.state.passwordInput}
                        onChange={(e) => this.setPassword(e)}
                    />
                    {this.printPasswordViewToggle()}
                </div>

                {/* Forget Password Link */}
                {
                    this.state.showForgotPasswordLink &&
                        <ForgotPasswordLink type={this.state.personType} basePath={this.state.basePath} />
                }
            </Form.Group>
        );
    }
}

/**
 * Renders a link for resetting the password.
 * @param {Object} props - The component props.
 * @param {string} props.basePath - The base path for the reset password link.
 * @param {string} props.type - The type of password reset.
 * @returns {JSX.Element} The rendered component.
 */
function ForgotPasswordLink(props)
{
    const navigate = useNavigate();

    return (
        <small className="link-text" onClick={() => navigate(props.basePath + PATH_RESET_PASSWORD + "/" + props.type)}>
            Forgot your password?
        </small>
    );
};

export default PasswordField;
