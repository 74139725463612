/**
 * This file defines the StudentDropdown component.
 * It is used to input a list of students.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {Dropdown} from 'semantic-ui-react';

import {CreateStudentNameList, RequiredTooltip} from '../Util';


export class StudentDropdown extends Component
{
    /**
     * Represents the StudentDropdown component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.students - The default students input value.
     * @param {string} props.fieldLabel - The label for the students input.
     * @param {string} props.fieldDesc - The description for the students input.
     * @param {string} props.errorCode - The error code for the students input.
     * @param {string} props.filteredStudents - The student emails to filter out.
     * @param {number} props.maxAllowed - The maximum number of students allowed to be selected.
     * @param {boolean} props.required - The flag to indicate if the field is required.
     * @param {boolean} props.disabled - The flag to indicate if the field is disabled.
     * @param {Function} props.setParentStudents - The function to set the parent component's student list.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {Object} props.locationDetails - The location details for the current community.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            studentInput: props.students,
            diffCommunityStudentInput: [],
            studentNameList: null,
            fieldLabel: props.fieldLabel,
            fieldDesc: props.fieldDesc,
            errorCode: props.errorCode,
            filteredStudents: props.filteredStudents || [],
            maxAllowed: props.maxAllowed || 0,
            required: props.required || false,
            disabled: props.disabled || false,
            locationDetails: props.locationDetails,
        }

        this.setParentStudents = props.setParentStudents;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Fetches the list of student names for the dropdown when the component mounts.
     */
    async componentDidMount()
    {
        //Load here and not in the parent component because whether this component shows is very conditional
        let studentNameList = [];
        if (!this.state.disabled)
            studentNameList = await CreateStudentNameList(this.state.locationDetails);

        //Filter out the students that are specified to be filtered
        studentNameList = studentNameList.filter(student => !this.state.filteredStudents.includes(student.value));

        //Set the state
        this.setState({studentNameList: studentNameList}, () =>
        {
            //Check if there are any students that are not in the community and set them aside so their data is not lost
            let newStudentInput = [];
            for (let email of this.state.studentInput)
            {
                if (!this.state.studentNameList.find(student => student.value === email))
                    this.state.diffCommunityStudentInput.push(email);
                else
                    newStudentInput.push(email);
            }

            this.setState({studentInput: newStudentInput});
        });
    }

    /**
     * Sets the students and also updates the parent component.
     * @param {Array} students - The array of students to set.
     */
    setStudents(students)
    {
        if (this.state.maxAllowed > 0 && students.length > this.state.maxAllowed)
            students.shift(); //Remove the oldest choice

        this.setState({studentInput: students});
        this.setParentStudents(students.concat(this.state.diffCommunityStudentInput)); //Add the students previously selected that are not in the community
    }

    /**
     * Renders the StudentDropdown component.
     * @returns {JSX.Element} The rendered StudentDropdown component.
     */
    render()
    {
        const required = (this.state.required) ? RequiredTooltip() : "";

        return (
            <Form.Group className="mb-3">
                {/* Title */}
                {
                    this.state.fieldLabel &&
                        <Form.Label className={this.state.fieldDesc ? "mb-0" : "mb-1"}>{this.state.fieldLabel}{required}</Form.Label>
                }

                {/* Note */}
                {
                    this.state.fieldDesc &&
                        <div className="mb-1">
                            <Form.Text className="text-muted">
                                {this.state.fieldDesc}
                            </Form.Text>
                        </div>
                }

                {/* Dropdown */}
                {
                    this.state.studentNameList === null ?
                        <div className="mb-1">
                            <Form.Text className="text-muted">
                                Loading {this.state.locationDetails.guestType} names...
                            </Form.Text>
                        </div>
                    :
                        <Dropdown
                            fluid
                            multiple
                            search
                            selection
                            disabled={this.state.disabled}
                            placeholder={this.state.disabled ? "Disabled" : ""}
                            options={this.state.studentNameList}
                            value={this.state.studentInput}
                            className={`form-control ${this.state.studentInput.length > 0
                                && this.errorMessage != null
                                && this.isErrorMessage(this.state.errorCode) ? 'is-invalid' : ''}`}
                            onChange={(e, data) => this.setStudents(data.value)}
                        />
                }
            </Form.Group>
        );
    }
}

export default StudentDropdown;
