/**
 * A class students and hosts can use to use a link to reset their password.
 */

import React from 'react';
import {Button, Form} from "react-bootstrap";
import Swal from 'sweetalert2'; //Pop-Up

import UnknownPage from "./UnknownPage";

import {PATH_HOME} from './App';
import {Capitalize, PageLoading, ErrorPopUp,
        SendFormToServer, SendQuickDataToServer, ComponentWithRouter,
        GLOBAL_ERROR_MESSAGES} from "./Util";
import {EmailField} from './subcomponents/EmailField';
import {NewPasswordField} from './subcomponents/NewPasswordField';

import "./styles/FormPage.css";
import "./styles/ResetPassword.css";

const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_EMAIL: "Invalid email!",
    INVALID_PASSWORD: "Invalid password!",
    INVALID_RESETTOKEN: "This password reset link is no longer valid!",
    NULL_PASSWORD_RESET: "This password reset details were wiped before reaching the server!",
    NO_ACCOUNT_FOUND: "No {TYPE} account with that email exists!",
    MISMATCHED_PASSWORDS: "Passwords don't match!",
};


class ResetPassword extends React.Component {
    /**
     * Sets up the reset password page.
     * @param {Object} props - The props including router data.
     */
    constructor(props)
    {
        super(props);
        let routerParams = this.props.router.params;

        this.state =
        {
            type: routerParams.type, //Access the URL parameters
            encryptedEmail: routerParams.email, //Access the URL parameters
            token: routerParams.token, //Access the URL parameters
            loaded: false,
            failedToLoad: false,
            showedErrorPopUp: false,
            emailInput: "",
            passwordInput: "",
            confirmPasswordInput: "",
            invalidEmail: "",
            errorMsg: "",
            locationDetails: props.locationDetails,
        }
    }

    /**
     * Checks if the current state type is for a student.
     * @returns {boolean} Whether the edit details page is for a student.
     */
    isStudent()
    {
        return this.state.type === this.state.locationDetails.guestType;
    }

    /**
     * Checks if the current state type is for a host.
     * @returns {boolean} Whether the edit details page is for a host.
     */
    isHost()
    {
        return this.state.type === "host";
    }

    /**
     * Confirms the link is valid when the page loads.
     */
    async componentDidMount()
    {
        if (!this.state.failedToLoad)
        {
            const type = (this.isHost()) ? "host" : "student";
            const route = `/validate${type}resettoken`;
            let data =
            {
                email: this.state.encryptedEmail,
                resetToken: this.state.token,
                database: this.state.locationDetails.collection,
            };

            let ret = await SendQuickDataToServer(data, this, route);
            if (ret == null)
            {
                this.setState({failedToLoad: true});
                Swal.close();
            }
            else
                this.setState({loaded: true, emailInput: ret.data.email});
        }
    }

    /**
     * Checks if both password inputs match.
     * @returns {Boolean} - true if both password inputs match, false if they're different.
     */
    passwordsMatch()
    {
        return this.state.passwordInput === this.state.confirmPasswordInput;
    }

    /**
     * Checks if both password inputs are filled.
     * @returns {Boolean} - true if both password inputs are filled, false if at least one is blank.
     */
    bothPasswordsFilled()
    {
        return this.state.passwordInput !== ""
            && this.state.confirmPasswordInput !== "";
    }

   /**
    * Gets the error message (if present) at the time of form submission.
    * @returns {String} The error message symbol.
    */
    getErrorMessage()
    {
        let errorMsg = "";

        if (!this.bothPasswordsFilled())
            errorMsg = "MISSING_REQUIRED_FIELD";
        else if (!this.passwordsMatch())
            errorMsg = "MISMATCHED_PASSWORDS";

        return errorMsg;
    }

    /**
     * Gets the text to display in an error pop-up.
     * @param {String} errorSymbol - The error message symbol.
     * @returns {String} The error text to display in the pop-up.
     */
    errorMsgText(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        return text.replaceAll("{TYPE}", this.state.type);
    }

   /**
    * Displays an error pop-up.
    * @param {String} errorSymbol - The error symbol for the message to be shown on the pop-up.
    */
    errorPopUp(errorSymbol)
    {
        ErrorPopUp(this.errorMsgText(errorSymbol));
    }

    /**
     * Submits the reset password form and reset's the user's password.
     * @param {Object} e - The form submission event. 
     */
    async resetPassword(e)
    {
        e.preventDefault(); //Prevent page reload
        const type = (this.isHost()) ? "host" : "student";
        const route = `/reset${type}password`;
        let errorMsg = this.getErrorMessage();

        if (errorMsg === "") //No error
        {
            let data =
            {
                email: this.state.encryptedEmail,
                newPassword: this.state.passwordInput,
                resetToken: this.state.token,
            };

            await SendFormToServer(data, this, route, 'Your password was successfully reset!',
                                   this.state.locationDetails, PATH_HOME);
        }
        else
        {
            this.setState({errorMsg: errorMsg});
            this.errorPopUp(errorMsg);
        }
    }

    /**
     * Prints the reset password.
     * @returns {JSX} - The reset password page.
     */
    render()
    {
        let formType = Capitalize(this.state.type); //Capitalize the first letter

        if (this.state.failedToLoad)
        {
            return (
                <div className="form-page">
                    <h1 className="form-loading">{this.errorMsgText(this.state.errorMsg)}</h1>
                </div>
            )
        }

        if (!this.state.loaded)
            return PageLoading();

        if (!this.isStudent() && !this.isHost())
            return <UnknownPage />;

        return (
            <div className="form-page">
                <h1 className="form-title">Reset Password ({formType})</h1>

                <Form onSubmit={(e) => this.resetPassword(e)}>
                    {/* Email Input */}
                    <EmailField
                        email={this.state.emailInput}
                        readOnly={true} />

                    {/* New Password Input */}
                    <NewPasswordField
                        password={this.state.newPassword}
                        confirmPassword={this.state.confirmPasswordInput}
                        setParentPassword={(password) => this.setState({passwordInput: password})}
                        setParentConfirmPassword={(password) => this.setState({confirmPasswordInput: password})}
                        bothPasswordsFilled={this.bothPasswordsFilled.bind(this)}
                        passwordsMatch={this.passwordsMatch.bind(this)}
                        isErrorMessage={(error) => this.isErrorMessage(error)}
                        isReset={true} />

                    {/* Submit Button */}
                    <div className="submit-form-button-container mt-2">
                        <Button variant="success" className="submit-form-button" type="submit">
                            Reset Password
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

export default ComponentWithRouter(ResetPassword);
