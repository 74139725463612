/**
 * This file defines the NewPasswordField component.
 * It is used to input a new password and confirm the new password.
 */

import React, {Component} from 'react';

import {PasswordField} from './PasswordField';


export class NewPasswordField extends Component
{
    /**
     * Represents the NewPasswordField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.password - The default password input value.
     * @param {string} props.confirmPassword - The default confirm password input value.
     * @param {Function} props.setParentPassword - The function to set the parent component's password.
     * @param {Function} props.setParentConfirmPassword - The function to set the parent component's confirm password.
     * @param {Function} props.bothPasswordsFilled - The function to check if both password and confirm password are filled.
     * @param {Function} props.passwordsMatch - The function to check if the password and confirm password match.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {boolean} props.isReset - The boolean to check if the password is being reset as opposed to being created.
     * @param {string} props.idMod - The string to modify the id of the password input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            passwordInput: props.password,
            confirmPasswordInput: props.confirmPassword,
            isReset: props.isReset,
            idMod: props.idMod || "",
            showPassword: false,
        }

        this.setParentPassword = props.setParentPassword;
        this.setParentConfirmPassword = props.setParentConfirmPassword;
        this.bothPasswordsFilled = props.bothPasswordsFilled;
        this.passwordsMatch = props.passwordsMatch;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Returns the current state of the password visibility.
     * @returns {boolean} Whether the passwords are visible.
     */
    showPassword()
    {
        return this.state.showPassword;
    }

    /**
     * Toggles the visibility of both password fields.
     */
    toggleView()
    {
        this.setState({showPassword: !this.state.showPassword});
    }

    /**
     * Sets the password and also updates the parent component.
     * @param {string} password - The password to set.
     */
    setPassword(password)
    {
        this.setState({passwordInput: password});
        this.setParentPassword(password);
    }

    /**
     * Sets the confirmed password and also updates the parent component.
     * @param {string} password - The password to set.
     */
    setConfirmPassword(password)
    {
        this.setState({confirmPasswordInput: password});
        this.setParentConfirmPassword(password);
    }

    /**
     * Renders the NewPasswordField component.
     * @returns {JSX.Element} The rendered NewPasswordField component.
     */
    render()
    {
        return (
        <>
            {/*Password Input*/}
            <PasswordField
                password={this.state.passwordInput}
                fieldPrefix={this.state.isReset ? "New " : ""}
                fieldDesc="Your password will be encrypted and secure."
                setParentPassword={this.setPassword.bind(this)}
                showParentPassword={this.showPassword.bind(this)}
                toggleShowParentPassword={this.toggleView.bind(this)} //Allow toggling one field to toggle both
                showError={() => false}
                idMod={this.state.idMod} />

            {/*Confirm Password Input*/}
            <PasswordField
                password={this.state.passwordInput}
                fieldPrefix="Confirm "
                isConfirmPassword={true}
                setParentPassword={this.setConfirmPassword.bind(this)}
                showParentPassword={this.showPassword.bind(this)}
                toggleShowParentPassword={this.toggleView.bind(this)} //Allow toggling one field to toggle both
                showError={() => {return this.bothPasswordsFilled() && !this.passwordsMatch();}}
                idMod={this.state.idMod} />
        </>
        );
    }
}

export default NewPasswordField;
