import React, {Component} from 'react';
import {Button, Card, Form} from "react-bootstrap";

import {PATH_WEEKLY_HOST} from './App';
import {HostsGetNewDatesEmails, HostsGetUnallocatedEmails,
        CreateNameLists, /*CreateTotalFoodRestrictionNameList,*/ CreatePoliticalLeaningList,
        StudentsHostsHaveProximityChecking, StudentsHostsHavePoliticalLeaning,
        ExtractCountryCityCommunity, ValidateEmail, ValidateAddress, ValidateName,
        PageLoading, ErrorPopUp, SendFormToServer,
        GLOBAL_ERROR_MESSAGES} from "./Util";
import {AddressField, ExtractAddressLine1, ExtractAddressLine2} from './subcomponents/AddressField';
import {EmailField} from './subcomponents/EmailField';
import {FoodDropdown} from './subcomponents/FoodDropdown';
import {GenderField} from './subcomponents/GenderField';
//import {MealTimeField} from './subcomponents/MealTimeField';
import {NameFields} from './subcomponents/NameFields';
import {NewPasswordField} from './subcomponents/NewPasswordField';
import {NotesField} from './subcomponents/NotesField';
import {PetDropdown} from './subcomponents/PetDropdown';
import {PhoneNumberField, ValidatePhoneNumber, PrettyPrintPhoneNumber} from './subcomponents/PhoneNumberField';
import {PoliLeaningField} from './subcomponents/PoliLeaningField';
import {RadioOptions} from './subcomponents/RadioOptions';
import {StudentDropdown} from './subcomponents/StudentDropdown';
import {VaccinationFields} from './subcomponents/VaccinationFields';

import "./styles/FormPage.css";
import "./styles/RegistrationForm.css";

const COVID_EXISTS = process.env.COVID_EXISTS != null;
const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_EMAIL: "Invalid email!",
    INVALID_NAME: "Invalid first name!",
    INVALID_FAMILY: "Invalid last name!",
    INVALID_SINGLE: "Invalid single/family status!",
    INVALID_GENDER: "Invalid gender!",
    INVALID_COUNTRYCODE: "Invalid country code!",
    INVALID_PHONE: "Invalid phone number!",
    INVALID_ADDRESS: "Invalid address! Make sure to choose your exact address on the map.",
    INVALID_COORDS: "Invalid address coordinates! Choose a new location on the map.",
    INVALID_VACCINATED: "Invalid vaccination status!",
    INVALID_UNVACCINATEDGUESTCOMFORT: "Invalid guest comfort vaccination status!",
    INVALID_UNALLOCATEDEMAIL: "Invalid weekly reminder!",
    INVALID_PETS: "Invalid pet!",
    INVALID_POLILEANING: "Invalid political leaning!",
    INVALID_HATEDSTUDENTS: "Invalid student!",
    INVALID_GOODFORALLERGY: "Invalid allergy!",
    INVALID_BADFORALLERGY: "Invalid allergy!",
    INVALID_NOTES: "Invalid message to student!",
    INVALID_EATTIME: "Invalid eat time!",
    INVALID_DEFAULTCAPACITY: "Invalid minimum capacity!",
    MISMATCHED_PASSWORDS: "Passwords don't match!",
    ACCOUNT_EXISTS: "An account with that email already exists!",
};


export class NewHost extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            loaded: false,
            emailInput: (props.editData) ? props.editData.email : "",
            passwordInput: (props.editData) ? props.editData.password : "",
            confirmPasswordInput: (props.editData) ? props.editData.password : "",
            firstNameInput: (props.editData) ? props.editData.name : "",
            lastNameInput: (props.editData) ? props.editData.family : "",
            singleInput: (props.editData) ? (props.editData.single ? "single" : "family") : "",
            genderInput: (props.editData && props.editData.single) ? props.editData.gender : "",
            phoneNumberInput: (props.editData) ? props.editData.phone : "",
            addressLine1Input: (props.editData)
                                ? ExtractAddressLine1(props.editData.address)
                                : "",
            addressLine2Input: (props.editData)
                                ? ExtractAddressLine2(props.editData.address)
                                : "",
            addressCoords: (props.editData) ? props.editData.coords : null,
            vaccinatedInput: COVID_EXISTS ? ((props.editData) ? props.editData.vaccinated : "") : true,
            vaccineComfortInput: COVID_EXISTS ? ((props.editData) ? props.editData.vaccineComfort : "") : true,
            weeklyReminderInput: (props.editData) ? props.editData.unallocatedEmail : true,
            eatTimeInput: (props.editData) ? props.editData.eatTime : "",
            minCapacityInput: (props.editData) ? props.editData.defaultCapacity : 4,
            hatedStudentInput: (props.editData) ? props.editData.hatedStudents : [],
            goodForAllergyInput: (props.editData) ? props.editData.goodForAllergy : [],
            badForAllergyInput: (props.editData) ? props.editData.badForAllergy : [],
            petInput: (props.editData) ? props.editData.pets : [],
            poliLeaningInput: (props.editData) ? props.editData.poliLeaning : "none",
            notesInput: (props.editData) ? ((props.editData.notes.length > 0) ? props.editData.notes : "") : "",
            //foodRestrictionNameList: [],
            foodPreferenceNameList: [],
            petNameList: [],
            politicalLeaningList: [],
            showedErrorPopUp: false,
            errorMsg: "",
            invalidEmail: "",
            editData: props.editData,
            locationDetails: props.locationDetails,
        };
    }

    async componentDidMount()
    {
        let {foodPreferences, pets} = await CreateNameLists();
        //let foodRestrictionNameList = await CreateTotalFoodRestrictionNameList(this.state.locationDetails);
        let politicalLeaningList = CreatePoliticalLeaningList();

        this.setState
        ({
            loaded: true,
            //foodRestrictionNameList: foodRestrictionNameList,
            foodPreferenceNameList: foodPreferences,
            petNameList: pets,
            politicalLeaningList: politicalLeaningList,
        });
    }

    isEditing()
    {
        return this.state.editData != null;
    }

    isSingleHost()
    {
        return this.state.singleInput === "single";
    }

    isFamilyHost()
    {
        return this.state.singleInput === "family";
    }

    isApprovedForCommunity()
    {
        return this.isEditing()
            && this.state.editData.approved;
    }

    bothPasswordsFilled()
    {
        return this.state.passwordInput !== ""
            && this.state.confirmPasswordInput !== "";
    }

    allRequiredFieldsFilled()
    {
        return this.state.emailInput !== ""
            && this.state.firstNameInput !== ""
            && this.state.lastNameInput !== ""
            && (this.state.genderInput !== "" || this.isFamilyHost())
            && this.state.countryCodeInput !== ""
            && this.state.phoneNumberInput !== ""
            && this.state.addressLine1Input !== ""
            && this.state.addressCoords != null
            && this.state.vaccinatedInput !== ""
            && this.state.vaccineComfortInput !== ""
            && this.state.weeklyReminderInput !== ""
            && this.state.minCapacityInput !== ""
            && this.state.poliLeaningInput !== "" //Required to be at least None
            && this.bothPasswordsFilled();
    }

    validEmail()
    {
        return ValidateEmail(this.state.emailInput);
    }

    passwordsMatch()
    {
        return this.state.passwordInput === this.state.confirmPasswordInput;
    }

    validFirstName()
    {
        return ValidateName(this.state.firstNameInput);
    }

    validLastName()
    {
        return ValidateName(this.state.lastNameInput);
    }

    validPhoneNumber()
    {
        return ValidatePhoneNumber(this.state.phoneNumberInput);
    }

    validAddress()
    {
        return ValidateAddress(this.state.addressLine1Input);
    }

    validEatTime()
    {
        return this.state.eatTimeInput != null;
    }

    getErrorMessage()
    {
        let errorMsg = "";

        if (!this.allRequiredFieldsFilled())
            errorMsg = "MISSING_REQUIRED_FIELD";
        else if (!this.validEmail())
            errorMsg = "INVALID_EMAIL";
        else if (!this.passwordsMatch())
            errorMsg = "MISMATCHED_PASSWORDS";
        else if (!this.validFirstName())
            errorMsg = "INVALID_NAME";
        else if (!this.validLastName())
            errorMsg = "INVALID_FAMILY";
        else if (!this.validPhoneNumber())
            errorMsg = "INVALID_PHONE";
        else if (!this.validAddress())
            errorMsg = "INVALID_ADDRESS";

        return errorMsg;
    }

    isErrorMessage(errorMsg)
    {
        return this.state.errorMsg === errorMsg;
    }

    emailAlreadyInUse()
    {
        return this.state.invalidEmail !== ""
            && this.state.invalidEmail === this.state.emailInput;
    }

    setGender(gender)
    {
        this.setState({genderInput: gender});
    }

    setCountryCode(input)
    {
        this.setState({countryCodeInput: input});
    }

    setPhoneNumber(input)
    {
        this.setState({phoneNumberInput: input});
    }

    setAddressDetails(addressLine1, addressLine2, coords)
    {
        this.setState
        ({
            addressLine1Input: addressLine1,
            addressLine2Input: addressLine2,
            addressCoords: coords
        });
    }

    setVaccinated(isVaccinated)
    {
        this.setState({vaccinatedInput: isVaccinated});
    }

    setVaccineComfort(comfortableHostingUnvaccinatedStudent)
    {
        this.setState({vaccineComfortInput: comfortableHostingUnvaccinatedStudent});
    }

    setWeeklyReminder(sendWeeklyReminder)
    {
        this.setState({weeklyReminderInput: sendWeeklyReminder});
    }

    setHatedStudents(students)
    {
        this.setState({hatedStudentInput: students});
    }

    async submitRegistration(e)
    {
        e.preventDefault(); //Prevent page reload
        let errorMsg = this.getErrorMessage();
        const route = (this.isEditing()) ? `/edithost` : `/registerhost`;
        const successMessage = (this.isEditing())
            ? "Your details were updated successfully!\n"
                + `If you have a ${this.state.locationDetails.guestType} account, your details were updated there as well.`
            : "Account created!\nNext, sign up to host a meal.";

        if (errorMsg === "") //No error
        {
            let data =
            {
                people:
                [{
                    Email: this.state.emailInput.toLowerCase(),
                    Password: this.state.passwordInput,
                    Family: this.state.lastNameInput.trim(),
                    Name: this.state.firstNameInput.trim(),
                    Single: this.isSingleHost(),
                    Gender: (this.state.genderInput !== "" && this.isSingleHost()) ? this.state.genderInput : "none",
                    Phone: PrettyPrintPhoneNumber(this.state.phoneNumberInput || ""),
                    Address: this.state.addressLine1Input.trim() + "|" + this.state.addressLine2Input.trim(), //"Address Line 1|Address Line 2"
                    Coords: this.state.addressCoords,
                    Vaccinated: this.state.vaccinatedInput,
                    UnvaccinatedGuestComfort: this.state.vaccineComfortInput,
                    UnallocatedEmail: this.state.weeklyReminderInput,
                    Pets: this.state.petInput,
                    PrefStudents: [],
                    HatedStudents: this.state.hatedStudentInput,
                    GoodForAllergy: this.state.goodForAllergyInput,
                    BadForAllergy: this.state.badForAllergyInput,
                    DefaultCapacity: this.state.minCapacityInput,
                    EatTime: this.validEatTime() ? this.state.eatTimeInput : "",
                    PoliLeaning: this.state.poliLeaningInput,
                    Notes: this.state.notesInput.trim(),
                }],
            }

            if (this.isEditing())
            {
                data = data.people[0]; //Only send the first person's data
                data.originalPassword = this.state.editData.password;
            }

            await SendFormToServer(data, this, route, successMessage, this.state.locationDetails, PATH_WEEKLY_HOST);
        }
        else
        {
            this.setState({errorMsg: errorMsg});
            this.errorPopUp(errorMsg);
        }
    }

    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        ErrorPopUp(text);
    }

    render()
    {
        let {country, city} = ExtractCountryCityCommunity(this.state.locationDetails);
        let title = (this.isEditing()) ? "Edit Host Details" : "Host Registration";
        let buttonText = (this.isEditing()) ? "Save Changes" : "Continue";
        let buttonVariant = (this.isEditing()) ? "success" : "primary";
        let guestType = this.state.locationDetails.guestType;
        let community = this.state.locationDetails.community;
        let unallocatedEmails = HostsGetUnallocatedEmails(this.state.locationDetails);
        let newDatesEmails = HostsGetNewDatesEmails(this.state.locationDetails);

        if (!this.state.loaded)
            return PageLoading();

        return (
            <div className="form-page">
                <h1 className="form-title weekly-student-form-title">{title}</h1>
                <h2 className="form-title weekly-student-form-title">{city}, {country}</h2>
                <div className="mb-3">
                {
                    !this.isEditing() &&
                        <Form.Text>This form is filled out once. A different form is filled out each time you wish to host.</Form.Text>
                }
                </div>

                <Form onSubmit={(e) => this.submitRegistration(e)}>
                    {/*Email Input*/}
                    <EmailField
                        email={this.state.emailInput}
                        setParentEmail={(email) => this.setState({emailInput: email})}
                        isInvalid={() => !this.validEmail() || this.emailAlreadyInUse()}
                        readOnly={this.isEditing()} />

                    {/*Password Input*/}
                    <NewPasswordField
                        password={this.state.passwordInput}
                        confirmPassword={this.state.confirmPasswordInput}
                        setParentPassword={(password) => this.setState({passwordInput: password})}
                        setParentConfirmPassword={(password) => this.setState({confirmPasswordInput: password})}
                        bothPasswordsFilled={this.bothPasswordsFilled.bind(this)}
                        passwordsMatch={this.passwordsMatch.bind(this)}
                        isErrorMessage={(error) => this.isErrorMessage(error)} />

                    {/*First & Last Name Input*/}
                    <NameFields
                        firstName={this.state.firstNameInput}
                        lastName={this.state.lastNameInput}
                        firstNameErrorCode="INVALID_NAME"
                        lastNameErrorCode="INVALID_FAMILY"
                        setParentFirstName={(name) => this.setState({firstNameInput: name})}
                        setParentLastName={(name) => this.setState({lastNameInput: name})}
                        validFirstName={this.validFirstName.bind(this)}
                        validLastName={this.validLastName.bind(this)}
                        isErrorMessage={(error) => this.isErrorMessage(error)} />

                    {/*Single or Family Input*/}
                    <RadioOptions
                        input={this.state.singleInput}
                        radioLabels={[{label: "Single", val: "single"}, {label: "Family", val: "family"}]}
                        fieldLabel="Are you a single host or do you have a family?"
                        required={true}
                        setParentInput={(input) => this.setState({singleInput: input})} />

                    {/*Gender Input*/}
                    {
                        this.isSingleHost() &&
                            <GenderField
                                gender={this.state.genderInput}
                                setParentGender={this.setGender.bind(this)}
                                isErrorMessage={this.isErrorMessage.bind(this)}
                                warningCardText={
                                    <Card.Text>
                                        This is used to help optimize meal allocation.
                                        <br/>
                                        Please reconsider to improve the allocation process.
                                    </Card.Text>
                                } />
                    }

                    {/*Phone Number Input*/}
                    <PhoneNumberField
                        currentCountry={this.state.locationDetails.country}
                        phoneNumber={this.state.phoneNumberInput}
                        setParentPhoneNumber={this.setPhoneNumber.bind(this)}
                        validPhoneNumber={this.validPhoneNumber.bind(this)}
                        isErrorMessage={this.isErrorMessage.bind(this)} />

                    {/*Address Input with Map*/}
                    <AddressField
                        addressLine1={this.state.addressLine1Input}
                        addressLine2={this.state.addressLine2Input}
                        coords={this.state.addressCoords}
                        country={this.state.locationDetails.country}
                        defaultCenter={this.state.locationDetails.coords}
                        fieldDesc={
                            `Shared only with the ${guestType}s you host.`
                            +
                            (
                                StudentsHostsHaveProximityChecking(this.state.locationDetails) ?
                                    ` Also used to match you with ${guestType}s who can reach you within their specified walking time.`
                                :
                                    ""
                            )
                        }
                        setParentAddress={this.setAddressDetails.bind(this)}
                        isErrorMessage={(error) => this.isErrorMessage(error)}
                        showAddressLine2={true} />

                    {/*Min Capacity Input*/}
                    {/*<Form.Group className="mb-3">
                        <Form.Label className="mb-1">What's the general number of {guestType}s you'd like to host?{required}</Form.Label>
                        <br/>
                        <Form.Text className="mb-1">
                            This number can be changed the week you host.
                        </Form.Text>
                        <Form.Control
                            required
                            name="quantity"
                            type="number"
                            min="1"
                            placeholder=""
                            value={this.state.minCapacityInput}
                            onChange={(e) => this.setState({minCapacityInput: e.target.value})}
                        />
                    </Form.Group>*/}

                    {/*Vaccination Input - COVID Only*/}
                    <VaccinationFields
                        vaccinated={this.state.vaccinatedInput}
                        vaccineComfort={this.state.vaccineComfortInput}
                        vaccinatedLabel="Are all eligible members of your household fully vaccinated against COVID-19?"
                        vaccineComfortLabel={`Are you comfortable hosting unvaccinated ${guestType}s?`}
                        setParentVaccinated={this.setVaccinated.bind(this)}
                        setParentVaccineComfort={this.setVaccineComfort.bind(this)}
                        useDefaultValue={this.isEditing()}
                        usePreferNotToSay={true} />

                    {/*Weekly Reminder Input*/}
                    {
                        (unallocatedEmails || newDatesEmails) &&
                            <RadioOptions
                                input={this.state.weeklyReminderInput}
                                radioLabels={[{label: "Yes", val: true}, {label: "No", val: false}]}
                                fieldLabel={
                                    (unallocatedEmails && newDatesEmails) ?
                                        `Would you like to receive emails when new dates are available or on Wednesdays if there are still ${guestType}s that need a Shabbat meal?`
                                    : (unallocatedEmails) ?
                                        `Would you like to receive emails on Wednesdays if there are still ${guestType}s that need a Shabbat meal?`
                                    : (newDatesEmails) ?
                                        `Would you like to receive emails when new dates are available?`
                                    : ""
                                }
                                required={true}
                                setParentInput={this.setWeeklyReminder.bind(this)} />
                    }

                    {/*Bad for Allergy Input - already asked on second form so no point here*/}
                    {/* <FoodDropdown
                        foodSelection={this.state.goodForAllergyInput}
                        foodNameList={this.state.foodRestrictionNameList}
                        fieldLabel="Are there any food restrictions you'd prefer to NEVER cater to?"
                        fieldDesc="This can also be customized the week you host."
                        allowAdditions={false}
                        isAllergyList={false}
                        errorCode="INVALID_BADFORALLERGY"
                        setParentFoodSelection={(foodPref) => this.setState({badForAllergyInput: foodPref})}
                        setParentFoodOptions={(foodPref) => this.setState({foodRestrictionNameList: foodPref})}
                        isErrorMessage={this.isErrorMessage.bind(this)} /> */}

                    {/*Good for Allergy Input*/}
                    <FoodDropdown
                        foodSelection={this.state.goodForAllergyInput}
                        foodNameList={this.state.foodPreferenceNameList}
                        fieldLabel="Are there any food preferences you'd prefer to cater to if possible?"
                        fieldDesc={`E.g. if your family is vegan, then vegan ${guestType}s would especially enjoy eating in your home.`}
                        testId="good-for-allergy-input"
                        allowAdditions={false}
                        isAllergyList={false}
                        errorCode="INVALID_GOODFORALLERGY"
                        setParentFoodSelection={(foodPref) => this.setState({goodForAllergyInput: foodPref})}
                        setParentFoodOptions={(foodPref) => this.setState({foodPreferenceNameList: foodPref})}
                        isErrorMessage={this.isErrorMessage.bind(this)} />

                    {/*Pet Input*/}
                    <PetDropdown
                        pets={this.state.petInput}
                        petNameList={this.state.petNameList}
                        fieldLabel="Do you have any of the listed pets?"
                        errorCode="INVALID_PETS"
                        setParentPets={(pets) => this.setState({petInput: pets})}
                        isErrorMessage={this.isErrorMessage.bind(this)} />

                    {/* Political Leaning Input */}
                    {
                        StudentsHostsHavePoliticalLeaning(this.state.locationDetails) &&
                            <PoliLeaningField
                                poliLeaning={this.state.poliLeaningInput}
                                poliLeaningList={this.state.politicalLeaningList}
                                fieldDesc={`If you choose, you will not be assigned ${guestType}s who hold the opposite viewpoint.`}
                                setParentPoliLeaning={(poliLeaning) => this.setState({poliLeaningInput: poliLeaning})}
                                isErrorMessage={this.isErrorMessage.bind(this)} />
                    }

                    {/*Lunch Time Input*/}
                    {/* <MealTimeField
                        mealTime={this.state.eatTimeInput}
                        mealType="lunch"
                        errorCode="INVALID_EATTIME"
                        setParentMealTime={(time) => this.setState({eatTimeInput: time})}
                        isErrorMessage={this.isErrorMessage.bind(this)} /> */}

                    {/*Notes Input*/}
                    <NotesField
                        notes={this.state.notesInput}
                        fieldLabel={`Do you have any messages for ${guestType}s you'd be hosting?`}
                        fieldDesc={"For example: \"Knock on the side door.\""}
                        setNotes={(notes) => this.setState({notesInput: notes})} />

                    {/*Hated Students Input*/}
                    {
                        this.isEditing() && //Only show hated students if editing
                            <StudentDropdown
                                students={this.state.hatedStudentInput}
                                fieldLabel={`Are there any ${community} ${guestType}s you'd prefer to NEVER host?`}
                                fieldDesc={(!this.isApprovedForCommunity()) ? `This field cannot be edited until you are approved for ${community}.` : ""}
                                errorCode="INVALID_HATEDSTUDENTS"
                                disabled={!this.isApprovedForCommunity()}
                                setParentStudents={this.setHatedStudents.bind(this)}
                                isErrorMessage={this.isErrorMessage.bind(this)}
                                locationDetails={this.state.locationDetails} />
                    }

                    {/* Submit Button */}
                    <div className="submit-form-button-container mt-2">
                        <Button variant={buttonVariant} className="submit-form-button" type="submit">
                            {buttonText}
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default NewHost;
