/**
 * This file defines the AddressField component.
 * It is used to input a user's address.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {GoogleMap, LoadScript, Autocomplete} from '@react-google-maps/api';

import {ProcessTextInput, RequiredTooltip} from '../Util';
import CountryCodes from "../data/CountryCodes.json";

const LIBRARIES = ["places"];
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;


export class AddressField extends Component
{
    /**
     * Represents the AddressField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.addressLine1 - The default address line 1 input.
     * @param {string} props.addressLine2 - The default address line 2 input.
     * @param {Object} props.coords - The default coordinates of the address input.
     * @param {number} props.maxTravelTime - The default max walking time input.
     * @param {string} props.country - The default country of the address input.
     * @param {Object} props.defaultCenter - The default centre of the map.
     * @param {string} props.fieldDesc - The description of the field.
     * @param {Function} props.setParentAddress - The function to set the parent's address input.
     * @param {Function} props.setParentMaxTravelTime - The function to set the parent's max walking time input.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {boolean} props.showAddressLine2 - Whether to show the address line 2 input.
     * @param {boolean} props.showMaxTravelTime - Whether to show the max walking time input.
     * @param {string} props.idMod - The string to modify the id of the address input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            addressLine1Input: props.addressLine1,
            addressLine2Input: props.addressLine2 || "",
            addressLine1InternalInput: null, //Backup of input for autocomplete
            addressLine1PrettyInput: props.addressLine1, //Formatted address for storing in the DB
            addressCoords: props.coords,
            maxTravelTimeInput: props.maxTravelTime || 0,
            country: props.country,
            fieldDesc: props.fieldDesc || "",
            idMod: props.idMod || "",
            showAddressLine2: props.showAddressLine2 || false,
            showMaxTravelTime: props.showMaxTravelTime || false,
            defaultCenter: props.defaultCenter || {lat: 0, lng: 0},

            //Maps Stuff
            autocomplete: null, //Google Autocomplete instance
            map: null, //Google Map instance
            marker: null, //Marker instance
            mapCenter: CountryCodes.countryLatLngByCountryName[props.country],
        }

        this.setParentAddress = props.setParentAddress;
        this.setParentMaxTravelTime = props.setParentMaxTravelTime;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Upon loading the autocomplete component, saves it to the state.
     * @param {Object} autocomplete - The autocomplete component.
     */
    handleAutocompleteLoad(autocomplete)
    {
        let locationBias = new window.google.maps.Circle({
            center: this.state.defaultCenter || {lat: 0, lng: 0}, //Around the community
            radius: 5000, //5km radius
        });

        autocomplete.setBounds(locationBias.getBounds());
        this.setState({autocomplete});
    }

    /**
     * Upon loading the map, saves it to the state and adds a marker to the map if the coords are already set.
     * @param {Object} map - The map object.
     */
    async handleMapLoad(map)
    {
        if (this.state.defaultCenter != null)
        {
            //Set map on community if no address is set
            map.setCenter(this.state.defaultCenter);
            map.setZoom(15);
        }

        this.setState({map}, async () =>
        {
            //If the coords are already set, then add a marker to the map
            if (this.state.addressCoords != null)
            {
                const newMarker = await this.placeMapMarker(this.state.addressCoords);
                this.setState({marker: newMarker}, () =>
                {
                    this.state.map.panTo(this.state.addressCoords);
                    this.state.map.setZoom(15);
                });
            }
        });
    }

    /**
     * Places a map marker at the specified position and returns the marker object.
     * @param {google.maps.LatLng} position - The position where the marker should be placed.
     * @returns {google.maps.Marker} - The marker object that was created.
     */
    async placeMapMarker(position)
    {
        if (this.state.marker)
            this.state.marker.setMap(null);

        const newMarker = new window.google.maps.Marker
        ({
            map: this.state.map,
            position,
            draggable: false, //Prevent dragging for now so the user doesn't screw up their coords
        });

        newMarker.addListener("dragend", () =>
        {
            const lat = newMarker.getPosition().lat();
            const lng = newMarker.getPosition().lng();
            this.setState({addressCoords: {lat, lng}}, () =>
            {
                this.updateParentAddress();
            });
        });

        return newMarker;
    }

    /**
     * Handles the event when the selected map location changes.
     */
    async handlePlaceChanged()
    {
        //Get autocomplete from state
        const autocomplete = this.state.autocomplete;
        if (autocomplete == null)
        {
            console.log("Autocomplete is not loaded yet!");
            return;
        }

        //Get the place from the autocomplete
        const place = autocomplete.getPlace();
        if (!place.geometry)
        {
            console.log("Place not found!");
            return;
        }

        //Set the new marker and address
        const newMarker = await this.placeMapMarker(place.geometry.location);
        const formattedAddress = place.formatted_address;
        const prettyAddress = place.vicinity ? `${place.name}, ${place.vicinity}` : place.name;
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        this.setState
        ({
            marker: newMarker,
            addressLine1Input: formattedAddress,
            addressLine1InternalInput: formattedAddress,
            addressLine1PrettyInput: prettyAddress,
            addressCoords: {lat, lng},
            mapCenter: {lat, lng},
        }, () =>
        {
            this.updateParentAddress();
            this.state.map.panTo(place.geometry.location);
            this.state.map.setZoom(15);
        });
    }

    /**
     * Sets the internal address in the component's state.
     * If the environment is set to "test", it also updates the parent address.
     * @param {Event} e - The event object for the input field.
     */
    setAddress1(e)
    {
        this.setState({addressLine1InternalInput: e.target.value});

        //In test environment, update all address 1 fields
        if (process.env.NODE_ENV === "test")
        {
            this.setState({addressLine1Input: e.target.value, addressLine1PrettyInput: e.target.value, addressCoords: {lat: 0, lng: 0}}, () =>
            {
                this.updateParentAddress();
            });
        }
    }

    /**
     * Sets the extra address details (e.g. apartment number) in the state.
     * @param {Event} e - The event object for the input field.
     */
    setAddress2(e)
    {
        let address2 = ProcessTextInput(e, "ADDRESS", false);
        address2 = address2.replace("|", ""); //Remove any "|" characters from the input

        this.setState({addressLine2Input: address2}, () =>
        {
            this.updateParentAddress();
        });
    }

    /**
     * Sets the max walking time in the state and updates the parent.
     * @param {Event} e - The event object for the input field.
     */
    setMaxTravelTime(e)
    {
        let maxTravelTime = ProcessTextInput(e, "MINUTES", true);

        if (Number(maxTravelTime) < 0)
            maxTravelTime = 0;
        else if (Number(maxTravelTime) > 999)
            maxTravelTime = 999;

        this.setState({maxTravelTimeInput: maxTravelTime});
        this.setParentMaxTravelTime(Number(maxTravelTime));
    }

    /**
     * Updates the parent address with the current address information.
     */
    updateParentAddress()
    {
        if (this.state.showAddressLine2)
            this.setParentAddress(this.state.addressLine1PrettyInput, this.state.addressLine2Input, this.state.addressCoords);
        else
            this.setParentAddress(this.state.addressLine1PrettyInput, this.state.addressCoords);
    }

    /**
     * Renders the AddressField component.
     * @returns {JSX.Element} The rendered AddressField component.
     */
    render()
    {
        const required = RequiredTooltip();
        const countryCode = CountryCodes.countryCodeByCountryName[this.state.country];
        const addressLine1Title = (this.state.showAddressLine2) ? "Address Line 1" : "Address";

        return (
        <>
            {/* Create fake input field so Chrome doesn't autocomplete address field */}
            <div style={{ display: 'none' }}>
                <input type="text" name="address" />
            </div>

            {/* Address Line 1 Input for Street Address */}
            <Form.Group className="mb-3" controlId={"formAddressLine1" + this.state.idMod} >
                <Form.Label className="mb-1">{addressLine1Title}{required}</Form.Label>
                <br/>

                {
                    //Optional field description
                    this.state.fieldDesc &&
                    <>
                        <Form.Text className="text-muted">
                            {this.state.fieldDesc}
                        </Form.Text>
                        <br/>
                    </>
                }

                <Form.Text className="mb-1">
                    Start typing and <span className="fw-bold">SELECT</span> your <span className="fw-bold">EXACT ADDRESS</span> from the suggestions.
                </Form.Text>
                <LoadScript googleMapsApiKey={API_KEY} libraries={LIBRARIES}>
                    <GoogleMap
                        id="searchbox-example"
                        zoom={4}
                        center={this.state.mapCenter}
                        onLoad={this.handleMapLoad.bind(this)}
                        mapContainerStyle={{width: "100%", height: "300px"}}
                        options={{mapTypeControl: false, streetViewControl: false, fullscreenControl: false}}
                    >
                        <Autocomplete
                            onLoad={this.handleAutocompleteLoad.bind(this)}
                            onPlaceChanged={this.handlePlaceChanged.bind(this)}
                            options={{componentRestrictions: {country: countryCode}}}
                        >
                            <input
                                type="text"
                                data-testid={"address-line-1-input" + this.state.idMod}
                                placeholder="Enter your address"
                                autoComplete="nope"
                                value={(this.state.addressLine1InternalInput == null) ? this.state.addressLine1Input : this.state.addressLine1InternalInput}
                                onChange={this.setAddress1.bind(this)}
                                onBlur={(e) => this.setState({addressLine1InternalInput: this.state.addressLine1Input})} //Restore original value if user doesn't select an address
                                onKeyDown={(e) => {if (e.key === "Enter") e.preventDefault();}} //Disable pressing enter from submitting the form
                                style={{ //Must use style because className doesn't work with Autocomplete
                                    boxSizing: "border-box",
                                    border: "1px solid transparent",
                                    width: "calc(100% - 8px)",
                                    height: "38px",
                                    padding: ".375rem .75rem",
                                    borderRadius: "3px",
                                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                                    fontSize: "14px",
                                    outline: "none",
                                    textOverflow: "ellipses",
                                    position: "absolute",
                                    left: "0",
                                    margin: "4px",
                                }}
                            />
                        </Autocomplete>
                    </GoogleMap>
                </LoadScript>
            </Form.Group>

            {/* Address Line 2 Input for Apartment Number etc. */}
            {
                this.state.showAddressLine2 &&
                    <Form.Group className="mb-3" controlId={"formAddressLine2" + this.state.idMod} >
                        <Form.Label className="mb-1">Address Line 2</Form.Label>
                        <br/>
                        <Form.Text className="mb-1">
                            For example: "Apt 13, Entrance B"
                        </Form.Text>
                        <Form.Control
                            name="addressLine2"
                            data-testid={"address-line-2-input" + this.state.idMod}
                            placeholder="Apartment Number, Suite, etc. (optional)"
                            value={this.state.addressLine2Input}
                            onChange={this.setAddress2.bind(this)}
                        />
                    </Form.Group>
            }

            {/* Max Walking Time Input */}
            {
                this.state.showMaxTravelTime &&
                    <Form.Group className="mb-3" controlId={"formMaxTravelTime" + this.state.idMod} >
                        <Form.Label className="mb-1">How many minutes are you willing to walk for a meal?{required}</Form.Label>
                        <br/>
                        <Form.Text className="mb-1">
                            Entering 0 means no limit.
                        </Form.Text>
                        <Form.Control
                            required
                            name="maxTravelTime"
                            data-testid={"max-travel-time-input" + this.state.idMod}
                            type="number"
                            min="0"
                            max="999"
                            placeholder="Max"
                            value={this.state.maxTravelTimeInput}
                            onChange={(e) => this.setMaxTravelTime(e)}
                        />
                    </Form.Group>
            }
        </>
        );
    }
}

/**
 * Extracts the main part of the address from the full address.
 * @param {string} address - The full address.
 * @returns {string} The main part of the address.
 */
export function ExtractAddressLine1(address)
{
    if (address == null)
        return "";

    return address.split("|")[0].trim();
}

/**
 * Extracts the secondary part of the address from the full address such as apartment number.
 * @param {string} address - The full address.
 * @returns {string} The secondary part of the address.
 */
export function ExtractAddressLine2(address)
{
    if (address != null && address.includes("|"))
        return address.split("|")[1].trim();

    return "";
}

/**
 * Generates a Google Maps link for the given address.
 *
 * @param {string} displayAddress - The address to display as the link text.
 * @param {string} linkAddress - The address to use in the link.
 * @returns {JSX.Element} - The component that, when clicked, opens the Google Maps page for the given address in a new tab.
 */
export function GoogleMapsLink(displayAddress, linkAddress)
{
    const url = `https://maps.google.com/?q=${linkAddress}`;

    //Return component that when clicked opens the page in a new tab
    return (
        <a href={url} target="_blank" rel="noreferrer">
            {displayAddress}
        </a>
    );
}

export default AddressField;
