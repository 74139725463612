/**
 * This file defines the AllocationWarnings component.
 * It is used for the maintainer to see issues with the allocation to a specific host.
 */

import React, {Component} from 'react';
import {Card} from "react-bootstrap";
import {isObject} from 'lodash';

import {CalculateAge} from '../../Util';
import {GenderedName} from './GenderedName';

import "../../styles/Maintainer.css";

const WALKING_SPEED = 5000 / 60; //Metres per second, average human walking speed is 5 km/h -- update in bot code too if changed

//TODO: More robust food conflict?


export class AllocationWarnings extends Component
{
    /**
     * Represents the AllocationWarnings component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {Object} props.registeredHost - The host's weekly registration.
     * @param {Object} props.students - All of the students in the community.
     * @param {Object} props.hosts - All of the hosts in the community.
     * @param {Object} props.registeredStudents - All of the students registered for the given week.
     * @param {Object} props.registeredHosts - All of the hosts registered for the given week.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            registeredHost: props.registeredHost,
            students: props.students,
            hosts: props.hosts,
            registeredHosts: props.registeredHosts,
            registeredStudents: props.registeredStudents,
        };
    }

    /**
     * Gets all warnings between a specific host and a student attending that host.
     * @param {Object} host - The host with all of the host's details.
     * @param {Object} regHost - The host's weekly registration.
     * @param {Object} student - The student with all of the student details.
     * @param {Object} regStudent - The student's weekly registration.
     * @returns {Array<String>} A list of warnings.
     */
    getWarningsHostStudent(host, regHost, student, regStudent)
    {
        let studentName = regStudent.name;
        let warnings = GetUnapprovedWarnings(host, regHost, student, regStudent, studentName)
            .concat(GetGenderPrefWarnings(host, regHost, student, regStudent, studentName))
            .concat(GetGenderAloneWarnings(host, regHost, student, regStudent, studentName, this.state.students, this.state.registeredStudents))
            .concat(GetHatedPeopleWarnings(host, regHost, student, regStudent, studentName, this.state.registeredStudents))
            .concat(GetRelationshipWarnings(host, regHost, student, regStudent, studentName, this.state.registeredStudents))
            .concat(GetFoodRestrictionWarnings(host, regHost, student, regStudent, studentName))
            .concat(GetPoliticalLeaningWarnings(host, regHost, student, regStudent, studentName))
            .concat(GetPetWarnings(host, regHost, student, regStudent, studentName))
            .concat(GetVaccinationWarnings(host, regHost, student, regStudent, studentName))
            .concat(GetAgeRangeWarnings(host, regHost, student, regStudent, studentName))
            .concat(GetDistanceWarnings(host, regHost, student, regStudent, studentName))
            .concat(GetRecentVisitWarnings(host, regHost, student, regStudent, studentName));

        return warnings;
    }

    /**
     * Gets all issues with the allocation to the current host.
     * @returns {Array<JSX.Element>} A list of list item elements.
     */
    getWarnings()
    {
        let warnings = [];
        let regHost = this.state.registeredHost;

        let host = this.state.hosts[regHost.email];
        if (host == null)
            warnings.push(`Host is not in the host list. PLEASE CONTACT SUPPORT`);
        else
        {
            //Get any warnings specific to the host
            warnings = warnings.concat(GetOverCapacityWarnings(regHost, this.state.registeredStudents));

            //Get any warnings for each guest not necessarily attending the host
            for (let regStudent of Object.values(this.state.registeredStudents))
                warnings = warnings.concat(GetPreferredPeopleWarnings(regHost, regStudent, regStudent.name, this.state.registeredHosts));

            //Get any warnings for each student attending the host
            for (let studentEmail of regHost.hosting)
            {
                let student = this.state.students[studentEmail];
                let regStudent = this.state.registeredStudents[studentEmail];
                if (student == null || regStudent == null)
                    warnings.push(`Student ${studentEmail} is not in the student list. PLEASE CONTACT SUPPORT`);
                else
                    warnings = warnings.concat(this.getWarningsHostStudent(host, regHost, student, regStudent));
            }
        }

        //Convert each warning to a list item element
        for (let i = 0; i < warnings.length; ++i)
            warnings[i] = <li key={i}>{warnings[i]}</li>

        return warnings;
    }

    /**
     * Renders the AllocationWarnings component.
     * @returns {JSX.Element} The rendered AllocationWarnings component.
     */
    render()
    {
        let warningItems = this.getWarnings();

        if (warningItems.length === 0)
            return "";

        return (
            <Card bg="warning" className="allocation-warnings-card">
                <Card.Body>
                    <ul>
                        {warningItems}
                    </ul>
                </Card.Body>
            </Card>
        );
    }
}


/**
 * Gets warnings when the host is over capacity.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} registeredStudents - The map of students registered for the week.
 * @returns {Array<String>} A list of warnings.
 */
export function GetOverCapacityWarnings(regHost, registeredStudents)
{
    let warnings = [];
    let capacity = regHost.capacity;
    let numStudents = regHost.hosting.length;
    let numGuests = regHost.hosting.reduce((total, email) =>
    {
        let student = registeredStudents[email];
        return total + ((student) ? student.maleGuests + student.femaleGuests : 0);
    }, 0);

    if (numStudents + numGuests > capacity)
        warnings.push(`Over capacity by ${numStudents + numGuests - capacity}`);

    return warnings;
}


/**
 * Gets warnings when the student is not approved.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetUnapprovedWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];

    if (!student.approved) //Communities without approval should have the students automatically approved
        warnings.push(`${studentName} is not approved`);

    return warnings;
}


/**
 * Gets warnings when the student or guest doesn't match the host's gender preference.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetGenderPrefWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];

    //Check if the gender preference is set and the student or their guests don't match
    if (regHost.genderPref.length > 0 && regHost.genderPref !== "none")
    {
        let warning = [];
        let warningPrefix = [
            `Gender preference is `,
            <GenderedName key={regHost.genderPref} name={regHost.genderPref} gender={regHost.genderPref} />, //Genders in text are highlighted
            `, but `,
        ];

        if (student.gender !== regHost.genderPref)
        {
            warning = warning.concat(warningPrefix);
            warning.push(`${studentName} is `);
            warning.push(<GenderedName key={student.gender} name={student.gender} gender={student.gender} />);
            warnings.push(warning);
        }
        else if (regHost.genderPref === "male" && regStudent.femaleGuests > 0)
        {
            warning = warning.concat(warningPrefix);
            warning.push(`${studentName} has a `);
            warning.push(<GenderedName key="female" name="female" gender="female" />);
            warning.push(` guest`);
            warnings.push(warning);
        }
        else if (regHost.genderPref === "female" && regStudent.maleGuests > 0)
        {
            warning = warning.concat(warningPrefix);
            warning.push(`${studentName} has a `);
            warning.push(<GenderedName key="male" name="male" gender="male" />);
            warning.push(` guest`);
            warnings.push(warning);
        }
    }

    return warnings;
}


/**
 * Gets warnings when the student is the only one of their gender at the meal.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @param {Object} students - The map of students in the community.
 * @param {Object} registeredStudents - The map of students registered for the week.
 * @returns {Array<String>} A list of warnings.
 */
export function GetGenderAloneWarnings(host, regHost, student, regStudent, studentName, students, registeredStudents)
{
    let warnings = [];

    //Check if the student is the only one going to the host
    if (regHost.hosting.length <= 1)
        return warnings; //No one else is going to the host so no warnings needed

    //Check if the student is the only one of their gender going to the host
    let gendersHosting = {"male": 0, "female": 0, "none": 0};
    regHost.hosting.map((email) =>
    {
        let student = students[email];
        let regStudent = registeredStudents[email];

        //Count the student and their guests
        ++gendersHosting[(student) ? student.gender : ""];
        if (regStudent)
        {
            gendersHosting["male"] += regStudent.maleGuests;
            gendersHosting["female"] += regStudent.femaleGuests;
        }

        return null;
    });

    if (host.gender !== "" && host.gender !== "none")
        ++gendersHosting[host.gender]; //Treat single hosts as another guest of the same gender

    if (student.gender !== "" && student.gender !== "none"
    && gendersHosting[student.gender] <= 1)
        warnings.push([`${studentName} is the only `,
                      <GenderedName key={student.gender} name={student.gender} gender={student.gender} />]);

    return warnings;
}


/**
 * Gets warnings when the host doesn't get someone they requested.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @param {Array<Object>} registeredHosts - The list of hosts registered for the week.
 * @returns {Array<JSX.Element>} A list of warnings.
 */
export function GetPreferredPeopleWarnings(regHost, regStudent, studentName, registeredHosts)
{
    let warnings = [];
    let studentEmail = regStudent.email;

    //Check if the host wanted this student and didn't get them
    if (regHost.requests.includes(studentEmail) //This host requested this student
    && Object.values(registeredHosts).some((host) => host.email !== regHost.email
            && host.hosting.includes(studentEmail) //And a different host is hosting this student
            && !host.requests.includes(studentEmail))) //But that different host didn't request this student
        warnings.push(`${studentName} was requested`); //Done separately so include the <li> tag

    return warnings;
}


/**
 * Gets warnings when the host dislikes the student or
 * the student dislikes another student going to the host.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @param {Object} registeredStudents - The list of students registered for the week.
 * @returns {Array<String>} A list of warnings.
 */
export function GetHatedPeopleWarnings(host, regHost, student, regStudent, studentName, registeredStudents)
{
    let warnings = [];
    let studentEmail = student.email;

    //Check if the host hates this student
    if (host.hatedStudents.includes(studentEmail))
        warnings.push(`Host does not like ${studentName}`);

    //Check if the student hates anyone else going to the host
    for (let email of student.peopleHated)
    {
        if (regHost.hosting.includes(email))
        {
            //Get the hated person's name and add it to the warnings
            let hatedName = email;
            let hatedStudent = registeredStudents[email];
            if (hatedStudent)
                hatedName = hatedStudent.name;

            warnings.push(`${studentName} does not like ${hatedName}`);
        }
    }

    return warnings;
}


/**
 * Gets warnings when a couple is split up or the student isn't placed with any requested friends.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @param {Object} registeredStudents - The list of students registered for the week.
 * @returns {Array<String>} A list of warnings.
 */
export function GetRelationshipWarnings(host, regHost, student, regStudent, studentName, registeredStudents)
{
    let warnings = [];

    //Check if a couple has been split up
    if (student.dating)
    {
        let datingEmail = student.dating.email;
        let datingStud = registeredStudents[datingEmail];
        if (datingStud) //Dating someone coming this week
        {
            if (!regHost.hosting.includes(datingStud.email))
            {
                warnings.push(`${studentName} is separated from ${student.dating.name}`);
                return warnings; //Just warn for the dating pair, no friends
            }
            else
                return []; //Dating pair is together so we don't care if they got friends too
        }
    }

    //Check if a student didn't get any friends
    if (regStudent.friends.length > 0)
    {
        //Build friends list from students actually present this week
        let friends = regStudent.friends.filter((friend) => registeredStudents[friend]);
        if (friends.length === 0)
            return []; //Student has no friends present this week so don't warn

        for (let friend of friends)
        {
            if (regHost.hosting.includes(friend))
                return []; //Student has a friend so everything's fine
        }

        warnings.push(`${studentName} is separated from requested friends`);
    }

    return warnings;
}


/**
 * Gets warnings when the student has allergies or food preferences the host doesn't want.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetFoodRestrictionWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];

    //TODO: Make more robust like bot's code?

    for (let foodRestriction of regHost.badForAllergy)
    {
        foodRestriction = foodRestriction.toLowerCase();

        if (student.allergies.map((allergy) => allergy.toLowerCase()).includes(foodRestriction))
            warnings.push(`${studentName} has a ${foodRestriction.toLowerCase()}`);

        if (student.foodPreferences.map((pref) => pref.toLowerCase()).includes(foodRestriction))
            warnings.push(`${studentName} is ${foodRestriction.toLowerCase()}`);

        if (foodRestriction.endsWith(" allergy") //Guest allergies and food preferences are combined into one list
        && regStudent.guestAllergies.map((allergy) => allergy.toLowerCase()).includes(foodRestriction))
            warnings.push(`${studentName}'s guest has a ${foodRestriction.toLowerCase()}`);
    
        if (!foodRestriction.endsWith(" allergy") //Guest allergies and food preferences are combined into one list
        && regStudent.guestAllergies.map((pref) => pref.toLowerCase()).includes(foodRestriction))
            warnings.push(`${studentName}'s guest is ${foodRestriction.toLowerCase()}`);
    }

    return warnings;
}


/**
 * Gets warnings when the student and host have opposite political leanings.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} hostName - The name of the host.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetPoliticalLeaningWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];

    if (host.poliLeaning !== "" && host.poliLeaning !== "none"
    && student.poliLeaning !== "" && student.poliLeaning !== "none"
    && host.poliLeaning !== student.poliLeaning)
        warnings.push(`${studentName} has the opposite political view`);

    return warnings;
}


/**
 * Gets warnings when the host has a pet the student is afraid of.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetPetWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];

    if (student.animalFear.length > 0)
    {
        for (let pet of student.animalFear)
        {
            if (host.pets.includes(pet)) //Host's pet list contains a pet the student is afraid of
            {
                warnings.push(`${studentName} is afraid of ${pet.toLowerCase()}s`);
                break; //Only need one warning per student
            }
        }
    }

    return warnings;
}


/**
 * Gets warnings when the student's vaccination status doesn't match what the host wants
 * or the host's vaccination status doesn't match what the student wants.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetVaccinationWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];

    //Check the student's vaccination status
    if (!student.vaccinated && !host.unvaccinatedComfort)
        warnings.push(`Host is uncomfortable with ${studentName} unvaccinated`);

    //Check the host's vaccination status
    if (!host.vaccinated && !student.unvaccinatedComfort)
        warnings.push(`${studentName} is uncomfortable with host unvaccinated`);

    return warnings;
}


/**
 * Gets warnings when the student is out of the host's requested age range.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetAgeRangeWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];
    let studentAge = (student.birthday !== "") ? CalculateAge(student.birthday) : 0;
    let guestMinAge = regStudent.guestMinAge;
    let guestMaxAge = regStudent.guestMaxAge;
    let hostMinAge = regHost.minAge;
    let hostMaxAge = regHost.maxAge;

    //Check if the student is too young for the host
    if (hostMinAge !== 0)
    {
        if (studentAge !== 0 && studentAge < hostMinAge)
            warnings.push(`${studentName} is too young`);
        if (guestMinAge !== 0 && guestMinAge < hostMinAge)
            warnings.push(`${studentName}'s guest is too young`);
    }

    //Check if the student is too old for the host
    if (hostMaxAge !== 0)
    {
        if (studentAge !== 0 && studentAge > hostMaxAge)
            warnings.push(`${studentName} is too old`);
        if (guestMaxAge !== 0 && guestMaxAge > hostMaxAge)
            warnings.push(`${studentName}'s guest is too old`);
    }

    return warnings;
}


/**
 * Gets warnings when the student has to walk farther than they'd prefer to the host.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetDistanceWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];

    //Check if the necessary data is available
    if (!student.maxTravelTime
    || !student.distance
    || !(host.email in student.distance)) //Distance has been calculated and cached
        return warnings;

    //Check if the student is too far from the host
    let maxTravelDistance = student.maxTravelTime * WALKING_SPEED; //Convert time to distance
    let distanceTo = student.distance[host.email]; //Cached distance to host
    if (maxTravelDistance > 0 && maxTravelDistance < distanceTo) //0 travel time means no preference
    {
        //console.log(`Max travel distance: ${maxTravelDistance}`, `Distance to host: ${distanceTo}`);
        let minutesFarther = Math.round((distanceTo - maxTravelDistance) / WALKING_SPEED);
        warnings.push(`${studentName} is ${minutesFarther} minute${(minutesFarther !== 1) ? "s" : ""} too far`);
    }

    return warnings;
}

/**
 * Gets warnings when the student has been to the host in the past two times they were present.
 * @param {Object} host - The host with all of the host's details.
 * @param {Object} regHost - The host's weekly registration.
 * @param {Object} student - The student with all of the student details.
 * @param {Object} regStudent - The student's weekly registration.
 * @param {string} studentName - The name of the student.
 * @returns {Array<String>} A list of warnings.
 */
export function GetRecentVisitWarnings(host, regHost, student, regStudent, studentName)
{
    let warnings = [];

    //Check if the student has been to the host recently
    let weeksPresent = student.weeks.slice(-2); //Only check the last two weeks
    for (let week of weeksPresent)
    {
        if (week && isObject(week) && "host" in week && week["host"] === host.email) //Validate the data format because it's changed over time
        {
            warnings.push(`${studentName} has been to this host recently`);
            break; //Only need one warning per student
        }
    }

    return warnings;
}

export default AllocationWarnings;
