/**
 * This file defines the EmailField component.
 * It is used to input a user's email.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";

import {ProcessTextInput, RequiredTooltip} from '../Util';


export class EmailField extends Component
{
    /**
     * Represents the EmailField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.email - The default email input value.
     * @param {boolean} props.readOnly - Whether the email input is read-only.
     * @param {Function} props.setParentEmail - The function to set the parent's email input.
     * @param {Function} props.isInvalid - The function to check if the email is invalid.
     * @param {string} props.idMod - The string to modify the id of the email input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            emailInput: props.email,
            readOnly: props.readOnly || false,
            idMod: props.idMod || "",
        }

        this.setParentEmail = props.setParentEmail;
        this.isInvalid = props.isInvalid;

        //If the email field is read-only, disable the setParentEmail and isInvalid functions
        if (props.readOnly)
        {
            this.setParentEmail = () => {};
            this.isInvalid = () => {return false;};
        }
    }

    /**
     * Sets the email and also updates the parent component.
     * @param {Object} e - The event object to get the input from.
     */
    setEmail(e)
    {
        let email = ProcessTextInput(e, "EMAIL", true);
        this.setState({emailInput: email});
        this.setParentEmail(email);
    }

    /**
     * Renders the EmailField component.
     * @returns {JSX.Element} The rendered EmailField component.
     */
    render()
    {
        const required = RequiredTooltip();

        return (
            <Form.Group className="mb-3" controlId={"formBasicEmail" + this.state.idMod}>
                <Form.Label>Email Address{required}</Form.Label>
                <Form.Control
                    required
                    name="email"
                    autoComplete="email"
                    data-testid={"email-input" + this.state.idMod}
                    //placeholder="example@gmail.com"
                    value={this.state.emailInput}
                    className={`form-control ${this.state.emailInput !== "" && this.isInvalid() ? 'is-invalid' : ''}`}
                    onChange={(e) => this.setEmail(e)}
                    readOnly={this.state.readOnly}
                    disabled={this.state.readOnly}
                />
            </Form.Group>
        );
    }
}

export default EmailField;
