/**
 * This file defines the FoodDropdown component.
 * It is used to input a list of allergies or dietary restrictions.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {Dropdown} from 'semantic-ui-react';

import {NameListCase, SortNameListAlphabetically, ProcessTextInput} from '../Util';


export class FoodDropdown extends Component
{
    /**
     * Represents the FoodDropdown component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.foodSelection - The selected food input.
     * @param {Array} props.foodNameList - The list of food names.
     * @param {string} props.fieldLabel - The label for the field.
     * @param {string} props.fieldDesc - The description for the field.
     * @param {string} props.testId - The test ID for the field.
     * @param {boolean} props.allowAdditions - Indicates whether additions are allowed.
     * @param {boolean} props.isAllergyList - Indicates whether the list is for allergies.
     * @param {string} props.errorCode - The error code for the field.
     * @param {Function} props.setParentFoodSelection - The function to set the parent's food selection.
     * @param {Function} props.setParentFoodOptions - The function to set the parent's food options for the dropdown.
     * @param {boolean} props.isErrorMessage - Indicates whether an error message is displayed.
     * @param {string} props.idMod - The string to modify the id of the food input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            foodInput: props.foodSelection,
            originalFoodInput: props.foodSelection,
            foodNameList: props.foodNameList,
            fieldLabel: props.fieldLabel,
            fieldDesc: props.fieldDesc,
            testId: props.testId || "",
            allowAdditions: props.allowAdditions,
            isAllergyList: props.isAllergyList,
            errorCode: props.errorCode,
            idMod: props.idMod || "",
        }

        this.setParentFoodSelection = props.setParentFoodSelection;
        this.setParentFoodOptions = props.setParentFoodOptions;
        this.isErrorMessage = props.isErrorMessage;

        if (this.isErrorMessage == null)
            this.isErrorMessage = () => false;
    }

    /**
     * Generates the list of food options by combining the existing food names with any custom user inputs.
     * @returns {Array} The list of food names.
     */
    getFoodNameListIncludingCustomValues()
    {
        let foodNameList = [...this.state.foodNameList];
        for (let i = 0; i < this.state.originalFoodInput.length; i++)
        {
            //Add any custom values that aren't already in the list
            let food = this.state.originalFoodInput[i];
            if (!foodNameList.some((foodItem) => foodItem.value === food))
                foodNameList.push({key: foodNameList.length, text: food, value: food});
        }

        SortNameListAlphabetically(foodNameList);
        return foodNameList;
    }

    /**
     * Sets the food selection, optionally the food name list, and also updates the parent component.
     * @param {Array} foodSelection - The selected food input.
     * @param {Array} foodNameList - The list of food names.
     */
    setFoodSelection(foodSelection, foodNameList)
    {
        this.setState({foodInput: foodSelection});
        this.setParentFoodSelection(foodSelection);

        if (foodNameList != null)
        {
            this.setState({foodNameList: foodNameList});
            this.setParentFoodOptions(foodNameList);
        }
    }

    /**
     * Adds a new food to the options list.
     * @param {string} newFoodInputText - The input text for the new food.
     */
    addNewFoodToOptionsList(newFoodInputText)
    {
        let newFood = ProcessTextInput({target: {value: newFoodInputText}}, "ALLERGY", true).toLowerCase();
        if (this.state.isAllergyList && !newFood.endsWith(" allergy"))
            newFood += " allergy"; //Add "allergy" to the end if it's not there
        newFood = NameListCase(newFood); //Capitalize each word

        if (newFood.startsWith("No ") || newFood.startsWith("None "))
        {
            //Don't add "No" or "None" to the list and remove the custom input
            let newFoodInput = [...this.state.foodInput];
            newFoodInput = newFoodInput.filter((food) => food !== newFoodInputText);
            this.setFoodSelection(newFoodInput, null);
        }
        else if (!this.state.foodNameList.some((food) => food.value === newFood
        && this.getFoodNameListIncludingCustomValues().some((food) => food.value === newFood)))
        {
            //One of the user's custom values, so remove newFoodInputText from the list
            let newFoodInput = [...this.state.foodInput];
            newFoodInput = newFoodInput.filter((food) => food !== newFoodInputText);
            this.setFoodSelection(newFoodInput, null);
        }
        else if (!this.state.foodNameList.some((food) => food.value === newFood)) //If it's not already in the list
        {
            //Add to the list and remove the custom input
            let newFoodList = [...this.state.foodNameList,
                                  {key: this.state.foodNameList.length, text: newFood, value: newFood}];
            let newFoodInput = [...this.state.foodInput, newFood];
            newFoodInput = newFoodInput.filter((food) => food !== newFoodInputText || (newFoodInputText.toLowerCase() === newFood.toLowerCase()));

            SortNameListAlphabetically(newFoodList);
            this.setFoodSelection(newFoodInput, newFoodList);
        }
        else
        {
            //Count it as a selection of that value as long as it's not already selected
            if (!this.state.foodInput.includes(newFood))
                this.setFoodSelection([...this.state.foodInput, newFood], null);
        }
    }

    /**
     * Renders the FoodDropdown component.
     * @returns {JSX.Element} The rendered FoodDropdown component.
     */
    render()
    {
        return (
            <Form.Group className="mb-3">
                {/* Title */}
                <Form.Label className={this.state.fieldDesc ? "mb-1" : "mb-2"}>{this.state.fieldLabel}</Form.Label>

                {/* Note */}
                {
                    this.state.fieldDesc &&
                        <div className="mb-1">
                            <Form.Text className="text-muted">
                                {this.state.fieldDesc}
                            </Form.Text>
                        </div>
                }

                {/* Dropdown */}
                <Dropdown
                    //placeholder="Eg. Nuts"
                    fluid
                    multiple
                    search
                    selection
                    options={this.getFoodNameListIncludingCustomValues()}
                    value={this.state.foodInput}
                    className={`form-control ${this.state.foodInput.length > 0 && this.isErrorMessage(this.state.errorCode) ? 'is-invalid' : ''}`}
                    data-testid={this.state.testId + this.state.idMod}
                    onChange={(e, data) => this.setFoodSelection(data.value, null)}

                    //Custom input
                    allowAdditions={this.state.allowAdditions}
                    additionPosition="bottom"
                    onAddItem={(e, data) => this.addNewFoodToOptionsList(data.value)}
                />
            </Form.Group>
        );
    }
}

export default FoodDropdown;
