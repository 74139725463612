/**
 * This file defines the VaccinationFields component.
 * It is used to input a user's vaccination status and their comfort eating at/hosting unvaccinated people.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";

import {RequiredTooltip} from '../Util';

const COVID_EXISTS = process.env.COVID_EXISTS != null;


export class VaccinationFields extends Component
{
    /**
     * Represents the VaccinationFields component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.vaccinated - The default vaccination input value.
     * @param {string} props.vaccineComfort - The default vaccine comfort input value.
     * @param {string} props.vaccinatedLabel - The label for the vaccinated input.
     * @param {string} props.vaccineComfortLabel - The label for the vaccine comfort input.
     * @param {boolean} props.useDefaultValue - Whether to use the default values for the input fields.
     * @param {boolean} props.usePreferNotToSay - Whether to include the "Prefer not to say" option for the vaccinated input.
     * @param {Function} props.setParentVaccinated - The function to set the parent component's vaccination status.
     * @param {Function} props.setParentVaccineComfort - The function to set the parent component's vaccine comfort status.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            vaccinatedInput: props.vaccinated,
            vaccineComfortInput: props.vaccineComfort,
            vaccinatedLabel: props.vaccinatedLabel,
            vaccineComfortLabel: props.vaccineComfortLabel,
            useDefaultValue: props.useDefaultValue,
            usePreferNotToSay: props.usePreferNotToSay
        }

        this.setVaccinated = props.setParentVaccinated;
        this.setVaccineComfort = props.setParentVaccineComfort;
    }

    /**
     * Sets the vaccination status and also updates the parent component.
     * @param {boolean} isVaccinated - The vaccination status to set.
     */
    setVaccinated(isVaccinated)
    {
        this.setState({vaccinatedInput: isVaccinated});
        this.setParentVaccinated(isVaccinated);
    }

    /**
     * Sets the vaccine comfort status and also updates the parent component.
     * @param {boolean} comfortableWithUnvaccinatedPerson - The vaccine comfort status to set.
     */
    setVaccineComfort(comfortableWithUnvaccinatedPerson)
    {
        this.setState({vaccineComfortInput: comfortableWithUnvaccinatedPerson});
        this.setParentVaccineComfort(comfortableWithUnvaccinatedPerson);
    }

    /**
     * Renders the VaccinationFields component.
     * @returns {JSX.Element} The rendered VaccinationFields component.
     */
    render()
    {
        const required = RequiredTooltip();

        if (!COVID_EXISTS)
            return "";

        return (
        <>
            {/*Vaccinated Input*/}
            <Form.Group className="mb-3">
                <Form.Label>{this.state.vaccinatedLabel}{required}</Form.Label>
                <div key="vaccine-radio">
                    <Form.Check
                        required
                        inline
                        label="Yes"
                        name="vaccinationStatus"
                        data-testid={"radio-vaccinated" + this.state.idMod}
                        type="radio"
                        id="radio-vaccinated"
                        defaultChecked={this.state.useDefaultValue ? this.state.vaccinatedInput : false}
                        onChange={() => this.setVaccinated(true)}
                    />
                    <Form.Check
                        required
                        inline
                        label="No"
                        name="vaccinationStatus"
                        data-testid={"radio-unvaccinated" + this.state.idMod}
                        type="radio"
                        id="radio-unvaccinated"
                        defaultChecked={this.state.useDefaultValue ? !this.state.vaccinatedInput : false}
                        onChange={() => this.setVaccinated(false)}
                    />
                    {
                        this.state.usePreferNotToSay &&
                            <Form.Check
                                required
                                inline
                                label="Prefer not to say"
                                name="vaccinationStatus"
                                data-testid={"radio-vaccination-prefer-not-to-say" + this.state.idMod}
                                type="radio"
                                id="radio-stupid-person-who-obviously-isnt-vaccinated"
                                onChange={() => this.setVaccinated(false) /*Let's be real - they're not vaccinated lol*/}
                            />
                    }
                    
                </div>
            </Form.Group>

            {/*Non-Vaccinated Comfort Input*/}
            <Form.Group className="mb-3">
                <Form.Label>{this.state.vaccineComfortLabel}{required}</Form.Label>
                <div key="vaccine-comfort-radio">
                    <Form.Check
                        required
                        inline
                        label="Yes"
                        name="vaccineComfortStatus"
                        data-testid={"radio-comfortable-unvaccinated" + this.state.idMod}
                        type="radio"
                        id="radio-comfortable-unvaccinated"
                        defaultChecked={this.state.useDefaultValue ? this.state.vaccineComfortInput : false}
                        onChange={() => this.setVaccineComfort(true)}
                    />
                    <Form.Check
                        required
                        inline
                        label="No"
                        name="vaccineComfortStatus"
                        data-testid={"radio-uncomfortable-unvaccinated" + this.state.idMod}
                        type="radio"
                        id="radio-uncomfortable-unvaccinated"
                        defaultChecked={this.state.useDefaultValue ? !this.state.vaccineComfortInput : false}
                        onChange={() => this.setVaccineComfort(false)}
                    />
                </div>
            </Form.Group>
        </>
        );
    }
}

export default VaccinationFields;
