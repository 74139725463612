
/**
 * Represents the Welcome component.
 * This component displays a series of questions and buttons to guide the user through the site.
 * It allows the user to select their location and whether they are a student or a host.
 * Based on the user's selections, it provides routes to different forms.
 */
import React, {Component} from 'react';
import {useNavigate} from "react-router-dom";
import {Button} from 'react-bootstrap';

import {GetNewStudentPathByLocation, PATH_REGISTRATION_HOST,
        GetWeeklyStudentPathByLocation, PATH_WEEKLY_HOST,
        GetWeeklyStudentCancellationPathByLocation, PATH_CANCELLATION_HOST,
        PATH_EDIT_DETAILS} from "./App";
import {Capitalize} from "./Util";

import "./styles/Welcome.css";
import ShlabotLogo from "./images/shlabot.png";

const STATE_ASK_COUNTRY = 0;
const STATE_ASK_CITY = 1;
const STATE_ASK_COMMUNITY = 2;
const STATE_ASK_STUDENT_OR_HOST = 3;
const STATE_ASK_USED_BEFORE = 4;
//const STATE_ASK_FUNCTION = 5;


export class Welcome extends Component
{
    /**
     * Constructs a new instance of the Welcome component.
     * @param {Object} props - The props passed to the component.
     */
    constructor(props)
    {
        super(props);

        let initialState =
        {
            state: STATE_ASK_COUNTRY,
            country: "",
            city: "",
            community: "",
            isStudent: false,
            isHost: false,
            isNew: false,
            basePaths: props.basePaths,
            allLocationDetails: props.locationDetails,
        }

        //If the country, city, and community are already known, set the state accordingly
        if (props.country && props.city && props.community)
        {
            initialState.state = STATE_ASK_STUDENT_OR_HOST;
            initialState.country = props.country;
            initialState.city = props.city;
            initialState.community = props.community;
        }

        this.state = initialState;
    }

    /**
     * Called immediately after a component is mounted. 
     * It overrides the back button to implement internal navigation.
     */
    componentDidMount()
    {
        //Override back button
        window.history.pushState(null, document.title, window.location.href)
        window.addEventListener("popstate", this.reverseState.bind(this));
    }

    /**
     * Sets the state of the component and waits for the state update to complete.
     * @param {Object} newState - The new state object to set.
     * @returns {Promise} A promise that resolves when the state update is complete.
     */
    async setStateAndWait(newState)
    {
        return new Promise(resolve => this.setState(newState, resolve));
    }

    /**
     * Checks if the current state matches the given state.
     * @param {string} checkState - The state to check against.
     * @returns {boolean} - Returns true if the current state matches the given state, otherwise false.
     */
    isCurrentState(checkState)
    {
        return this.state.state === checkState;
    }

    /**
     * Retrieves the base URL path for the chosen location.
     * @returns {string} The base path.
     */
    getBasePathFromInput()
    {
        return this.state.basePaths[this.state.country][this.state.city][this.state.community];
    }

    /**
     * Retrieves the location details for the chosen location.
     * @returns {Object} The location details.
     */
    getLocationDetailsByInput()
    {
        return this.state.allLocationDetails[this.state.country][this.state.city][this.state.community];
    }

    /**
     * Updates the history to make the back button go back to the current page from the forms.
     * It pushes the current page's base path to the browser's history.
     */
    addBasePathToBackButton()
    {
        //TODO: Fix back button from welcome page then not going back to home
        window.history.replaceState(null, document.title, this.getBasePathFromInput());
    }

    /**
     * Advances the state by incrementing the current state value by 1.
     * @returns {Promise<void>} A promise that resolves when the state has been updated.
     */
    async advanceState()
    {
        await this.setStateAndWait({state: this.state.state + 1});
    }

    /**
     * Goes back to the previous state value by decrementing it by 1.
     */
    reverseState()
    {
        if (this.state.state > 0)
            this.setState({state: this.state.state - 1});
    }

    /**
     * Sets the country and advances the state.
     * If there is only one city in the country, it sets the city as well.
     * @param {string} country - The country to set.
     * @returns {Promise<void>} - A promise that resolves when the country and city are set.
     */
    async setCountry(country)
    {
        await this.setStateAndWait({country: country});
        await this.advanceState();

        //Try fast track the user if there is only one city in the country
        if (Object.keys(this.state.basePaths[this.state.country]).length === 1)
            await this.setCity(Object.keys(this.state.basePaths[this.state.country])[0]);
    }

    /**
     * Sets the city and advances the state.
     * If there is only one community in the city, it sets the community as well.
     * @param {string} city - The name of the city to set.
     * @returns {Promise<void>} A promise that resolves when the city is set and the state is advanced.
     */
    async setCity(city)
    {
        await this.setStateAndWait({city: city});
        await this.advanceState();
    
        //Try fast track the user if there is only one community in the city
        if (Object.keys(this.state.basePaths[this.state.country][this.state.city]).length === 1)
            await this.setCommunity(Object.keys(this.state.basePaths[this.state.country][this.state.city])[0]);
    }

    /**
     * Sets the community value and advances the state.
     * @param {string} community - The community value to set.
     * @returns {Promise<void>} A promise that resolves when the state is updated and advanced.
     */
    async setCommunity(community)
    {
        await this.setStateAndWait({community: community});
        await this.advanceState();
        this.addBasePathToBackButton();
    }

    /**
     * Sets the student flag to true and advances the state.
     * @returns {Promise<void>} A promise that resolves when the state is updated.
     */
    async setStudent()
    {
        await this.setStateAndWait({isStudent: true, isHost: false});
        await this.advanceState();
    }

    /**
     * Sets the host state and advances the state.
     * @returns {Promise<void>} A promise that resolves when the state is set and advanced.
     */
    async setHost()
    {
        await this.setStateAndWait({isHost: true, isStudent: false});
        await this.advanceState();
    }

    /**
     * Sets the new state and advances the state.
     * @param {boolean} val - The new state value to set.
     * @returns {Promise<void>} A promise that resolves when the state is set and advanced.
     */
    async setNew(val)
    {
        await this.setStateAndWait({isNew: val});
        await this.advanceState();
    }

    /**
     * Returns the registration form route based on the selected details.
     * @returns {string} The registration form route.
     */
    getRegistrationFormRoute()
    {
        let path = (this.state.isHost) ? PATH_REGISTRATION_HOST : GetNewStudentPathByLocation(this.getLocationDetailsByInput());
        return `${this.getBasePathFromInput()}${path}`;
    }

    /**
     * Returns the route for the couple registration form based on the selected details.
     * @returns {string} The route for the couple registration form.
     */
    getCoupleRegistrationFormRoute()
    {
        let path = (this.state.isHost) ? PATH_REGISTRATION_HOST : GetNewStudentPathByLocation(this.getLocationDetailsByInput()) + "-couple"; //Only students can sign up as a couple
        return `${this.getBasePathFromInput()}${path}`;
    }

    /**
     * Returns the route for the weekly form based on the selected details.
     * @returns {string} The route for the weekly form.
     */
    getWeeklyFormRoute()
    {
        let path = (this.state.isHost) ? PATH_WEEKLY_HOST : GetWeeklyStudentPathByLocation(this.getLocationDetailsByInput());
        return `${this.getBasePathFromInput()}${path}`;
    }

    /**
     * Returns the cancellation form route based on the selected details.
     * @returns {string} The cancellation form route.
     */
    getCancellationFormRoute()
    {
        let path = (this.state.isHost) ? PATH_CANCELLATION_HOST : GetWeeklyStudentCancellationPathByLocation(this.getLocationDetailsByInput());
        return `${this.getBasePathFromInput()}${path}`;
    }

    getEditDetailsRoute()
    {
        return `${this.getBasePathFromInput()}${PATH_EDIT_DETAILS}/${(this.state.isHost) ? "host" : this.getLocationDetailsByInput().guestType}`;
    }

    /**
     * Renders the welcome page with buttons and headers based on the current state.
     * @returns {JSX.Element} The rendered welcome page.
     */
    render()
    {
        let header, buttonText1, buttonText2, locationDetails;
        let key = 0;
        let buttonClass = "welcome-button";
        let backButtonClass = "welcome-back-button";
        let nextButtonVariant = "primary";
        let backButtonVariant = "danger";
        let buttons = [];
        let backButton;
        let locationHeader = `${this.state.community}, ${this.state.city}, ${this.state.country}`;
        let showLocation = false; //Hide the location before it is chosen

        //Set up the back button
        if (this.state.state > 0)
        {
            backButton =
                <Button size="lg" variant={backButtonVariant} className={backButtonClass}
                                 onClick={this.reverseState.bind(this)} >
                    Back
                </Button>;
        }
        else
        {
            //Invisible button to keep the layout consistent
            backButton =
                <Button size="lg" variant={backButtonVariant} className={backButtonClass + " invisible-button"} >
                    Back
                </Button>;
        }

        //Set up the buttons and header based on the current state
        if (this.isCurrentState(STATE_ASK_COUNTRY))
        {
            header = "Which country are you in?";
            for (let country of Object.keys(this.state.basePaths))
                buttons.push(
                    <Button key={key++} size="lg" variant={nextButtonVariant} onClick={this.setCountry.bind(this, country)} className={buttonClass}>
                        {country}
                    </Button>
                );
        }
        else if (this.isCurrentState(STATE_ASK_CITY))
        {
            header = "Which city are you in?";
            for (let city of Object.keys(this.state.basePaths[this.state.country]))
                buttons.push(
                    <Button key={key++} size="lg" variant={nextButtonVariant} onClick={this.setCity.bind(this, city)} className={buttonClass}>
                        {city}
                    </Button>
                );
        }
        else if (this.isCurrentState(STATE_ASK_COMMUNITY))
        {
            header = "Which community are you a part of?";
            for (let community of Object.keys(this.state.basePaths[this.state.country][this.state.city]))
                buttons.push(
                    <Button key={key++} size="lg" variant={nextButtonVariant} onClick={this.setCommunity.bind(this, community)} className={buttonClass}>
                        {community}
                    </Button>
                );
        }
        else if (this.isCurrentState(STATE_ASK_STUDENT_OR_HOST))
        {
            showLocation = true; //Location has been chosen at this point
            locationDetails = this.getLocationDetailsByInput();
            header = `Are you a ${locationDetails.guestType} or a host?`;
            buttons = [
                <Button key={key++} size="lg" variant={nextButtonVariant} onClick={this.setStudent.bind(this)} className={buttonClass}>
                    {Capitalize(locationDetails.guestType)}
                </Button>,
                <Button key={key++} size="lg" variant={nextButtonVariant} onClick={this.setHost.bind(this)} className={buttonClass}>
                    Host
                </Button>,
            ];
        }
        else if (this.isCurrentState(STATE_ASK_USED_BEFORE))
        {
            showLocation = true; //Location has been chosen at this point
            header = `Have you registered in ${this.state.city} as a ${this.state.isHost ? "host" : this.getLocationDetailsByInput().guestType} before?`;
            buttons = [
                <Button key={key++} size="lg" variant={nextButtonVariant} onClick={this.setNew.bind(this, false)} className={buttonClass}>
                    Yes
                </Button>,

                this.state.isStudent ?
                    <Button key={key++} size="lg" variant={nextButtonVariant} onClick={this.setNew.bind(this, true)} className={buttonClass}>
                        No
                    </Button>
                :
                    <NavButton key={key++} target={this.getRegistrationFormRoute()} variant={nextButtonVariant} className={buttonClass} text="No"/>,
            ];
        }
        else //if (this.isCurrentState(STATE_ASK_FUNCTION))
        {
            showLocation = true; //Location has been chosen at this point

            if (this.state.isNew)
            {
                header = "Are you signing up with a spouse or by yourself?";
                buttons = [
                    <NavButton key={key++} target={this.getCoupleRegistrationFormRoute()} variant={nextButtonVariant} className={buttonClass} text="Married"/>,
                    <NavButton key={key++} target={this.getRegistrationFormRoute()} variant={nextButtonVariant} className={buttonClass} text="Single"/>,
                ];
            }
            else
            {
                if (this.state.isStudent)
                {
                    buttonText1 = "Meal Sign-Up";
                    buttonText2 = "Cancel Meal";
                }
                else
                {
                    buttonText1 = `Host a Meal`;
                    buttonText2 = "Cancel Meal";
                }

                header = "What would you like to do?";
                buttons = [
                    <NavButton key={key++} target={this.getEditDetailsRoute()} variant={nextButtonVariant} className={buttonClass} text="Edit Details"/>,
                    <NavButton key={key++} target={this.getWeeklyFormRoute()} variant={nextButtonVariant} className={buttonClass} text={buttonText1}/>,
                    <NavButton key={key++} target={this.getCancellationFormRoute()} variant={nextButtonVariant} className={buttonClass} text={buttonText2}/>,
                ];
            }
        }

        return (
            <div className="welcome-page">
                <h1 className={(!showLocation) ? "invisible-button" : ""}>{locationHeader}</h1>
                <img src={ShlabotLogo} alt="" className="shlabot-logo"/>
                <h2>{header}</h2>
                <div>
                    <div className="welcome-buttons">
                        {buttons}
                    </div>
                    {backButton}
                </div>
            </div>
        )
    }
}

/**
 * Renders a navigation button component.
 * @param {Object} props - The props for the NavButton component.
 * @param {string} props.variant - The variant of the button.
 * @param {string} props.target - The target location to navigate to.
 * @param {string} props.className - The CSS class name for the button.
 * @param {string} props.text - The text to display on the button.
 * @returns {JSX.Element} The rendered NavButton component.
 */
export const NavButton = (props) =>
{
    const navigate = useNavigate();

    return (
        <Button size="lg" variant={props.variant} onClick={() => navigate(props.target)} className={props.className}>
            {props.text}
        </Button>
    );
};

export default Welcome;
