/**
 * This file defines the GenderedName component.
 * It is used to display a name with colour depending on the gender.
 */

import React, {Component} from 'react';


export class GenderedName extends Component
{
    /**
     * Represents the GenderedName component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {String} props.name - The name to print.
     * @param {String} props.gender - The gender of the name.
     * @param {Function} props.onClick - The function to call when the name is clicked.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            name: props.name,
            gender: (props.gender) ? props.gender.toLowerCase() : "",
            onClick: props.onClick || function() {},
            clickable: props.onClick ? true : false,
        };
    }

    /**
     * Checks if the text should be highlighted the male colour.
     * @returns {boolean} Whether the text should be highlighted the male colour.
     */
    isMale()
    {
        return this.state.gender === "male";
    }

    /**
     * Checks if the text should be highlighted the female colour.
     * @returns {boolean} Whether the text should be highlighted the female colour.
     */
    isFemale()
    {
        return this.state.gender === "female";
    }

    /**
     * Renders the GenderedName component.
     * @returns {JSX.Element} The rendered GenderedName component.
     */
    render()
    {
        const blue = "var(--bs-blue)";
        const pink = "var(--bs-pink)";
        const black = "var(--bs-black)";
        let nameColour = this.isMale() ? blue : this.isFemale() ? pink : black;

        return (
            <span key={this.state.name + nameColour} style={{color: nameColour}}
                  className={(this.state.clickable) ? "clickable" : ""}
                  onClick={this.state.onClick}>
                {this.state.name}
            </span>
        );
    }
}

export default GenderedName;
