/**
 * This file defines the PoliLeaningField component.
 * It is used to input a user's political leaning.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {Dropdown} from 'semantic-ui-react';


export class PoliLeaningField extends Component
{
    /**
     * Represents the PoliLeaningField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.poliLeaning - The default political leaning input value.
     * @param {string} props.poliLeaningList - The list of political leanings to display in the dropdown.
     * @param {string} props.fieldDesc - The description for the political leaning input.
     * @param {Function} props.setParentPoliLeaning - The function to set the parent component's political leaning.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {string} props.idMod - The string to modify the id of the political leaning input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            poliLeaningInput: props.poliLeaning,
            poliLeaningList: props.poliLeaningList,
            fieldDesc: props.fieldDesc,
            idMod: props.idMod || "",
        }

        this.setParentPoliLeaning = props.setParentPoliLeaning;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Sets the political leaning and also updates the parent component.
     * @param {string} poliLeaning - The political leaning to set.
     */
    setPoliLeaning(poliLeaning)
    {
        this.setState({poliLeaningInput: poliLeaning});
        this.setParentPoliLeaning(poliLeaning);
    }

    /**
     * Renders the PoliLeaningField component.
     * @returns {JSX.Element} The rendered PoliLeaningField component.
     */
    render()
    {
        return (
            <Form.Group className="mb-3">
                {/* Title */}
                <Form.Label className="mb-1">Political Leaning</Form.Label>

                {/* Description */}
                <div className="mb-1">
                    <Form.Text className="text-muted">
                        {this.state.fieldDesc}
                    </Form.Text>
                </div>

                {/* Input Field */}
                <Dropdown
                    fluid
                    selection
                    defaultValue={this.state.poliLeaningInput}
                    options={this.state.poliLeaningList}
                    className={`form-control ${this.state.poliLeaningInput !== "" && this.isErrorMessage("INVALID_POLILEANING") ? 'is-invalid' : ''}`}
                    data-testid={"poli-leaning-input" + this.state.idMod}
                    onChange={(e, data) => this.setPoliLeaning(data.value)}
                />
            </Form.Group>
        );
    }
}

export default PoliLeaningField;
