/**
 * This file defines the PetDropdown component.
 * It is used to input a list of pets.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {Dropdown} from 'semantic-ui-react';


export class PetDropdown extends Component
{
    /**
     * Represents the PetDropdown component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.pets - The default pets input value.
     * @param {string} props.petNameList - The list of pet names to display in the dropdown.
     * @param {string} props.fieldLabel - The label for the pets input.
     * @param {string} props.errorCode - The error code for the pets input.
     * @param {Function} props.setParentPets - The function to set the parent component's pet list.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {string} props.idMod - The string to modify the id of the pets input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            petInput: props.pets,
            petNameList: props.petNameList,
            fieldLabel: props.fieldLabel,
            errorCode: props.errorCode,
            idMod: props.idMod || "",
        }

        this.setParentPets = props.setParentPets;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Sets the pets and also updates the parent component.
     * @param {Array} pets - The array of pets to set.
     */
    setPets(pets)
    {
        this.setState({petInput: pets});
        this.setParentPets(pets);
    }

    /**
     * Renders the PetDropdown component.
     * @returns {JSX.Element} The rendered PetDropdown component.
     */
    render()
    {
        return (
            <Form.Group className="mb-3">
                <Form.Label>{this.state.fieldLabel}</Form.Label>
                <Dropdown
                    //placeholder="Eg. Cat"
                    fluid
                    multiple
                    search
                    selection
                    options={this.state.petNameList}
                    value={this.state.petInput}
                    className={`form-control ${this.state.petInput.length > 0 && this.isErrorMessage(this.state.errorCode) ? 'is-invalid' : ''}`}
                    data-testid={"pet-input" + this.state.idMod}
                    onChange={(e, data) => this.setPets(data.value)}
                />
            </Form.Group>
        );
    }
}

export default PetDropdown;
